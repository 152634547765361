<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet"
    integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">
<!-- Font Awesome CDN -->
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
    integrity="sha512-iBBXm8fW90+nuLcSKlbmrPcLa0OT92xO1BIsZ+ywDWZCvqsWgccV3gFoRBv0z+8dLJgyAHIhR35VZc2oM/gI1w=="
    crossorigin="anonymous" referrerpolicy="no-referrer" />


<!-- ===== Header ===== -->
<header>
    <div class="container-fluid py-md-4 home-header">
        <div class="content d-flex flex-wrap justify-content-between align-items-center">
            <div class="img">
                <img src="../../../assets/template/img/Peak-Focus-Logo.png" class="img-fluid logo" alt="">
            </div>
            <div class="button">
                <a routerLink="/login">Login</a>
                <button class="custom-button" (click)="SubmitEmail('blank')">TRY 2 MONTHS FREE</button>
                <span>Ends October 15th</span>
            </div>
        </div>
    </div>
</header>
<!-- x===== Header =====x -->

<div class="home-wrapper-container ">


    <!-- ===== Hero ===== -->
    <div class="hero-wrapper py-md-4 ">
        <div class="container-fluid">
            <div class="content text-center pb-15 focus-custom">
                <h1>The Better Way To Focus
                    <!-- <img src="../../../assets/template/img/home/Vector-mind.png" alt=""> -->
                </h1>
                <h4>Virtual co-working eliminates procrastination & helps you <b>get more done.</b>
                </h4>
                <!-- Mobile screen show -->
                <div class="mobile-screen1">
                    <button class="custom-button">TRY 2 MONTHS FREE</button>
                </div>
                <!-- Mobile screen show end -->
                <a href="#" data-target="#exampleModalCenter" data-toggle="modal" class="custome-btn">Watch a 45 second
                    video</a>
                <!-- Button trigger modal -->
                <!-- Video Modal -->
                <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-lg-custom modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <video width="100%" height="auto" controls>
                                    <source src="../../../assets/template/img/video/45secondVideo-2.mp4"
                                        type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row wrote-book">
                <!-- Mobile screen start -->
                <div class="mobile-screen2">
                    <!-- <img src="../../../assets/template/img/home/hero-imgv2.png" class="img-fluid custom-img" alt=""> -->
                    <ng-lottie class="custom-img" [options]="options" (animationCreated)="animationCreated($event)">
                    </ng-lottie>
                </div>
                <!-- Mobile screen End -->
                <div class="col-md-3">
                    <ul class="stars">
                        <li><img src="../../../assets/template/img/home/Vector-star.png" alt="star"></li>
                        <li><img src="../../../assets/template/img/home/Vector-star.png" alt="star"></li>
                        <li><img src="../../../assets/template/img/home/Vector-star.png" alt="star"></li>
                        <li><img src="../../../assets/template/img/home/Vector-star.png" alt="star"></li>
                        <li><img src="../../../assets/template/img/home/Vector-star.png" alt="star"></li>
                    </ul>
                    <h3>I Wrote A Book</h3>
                    <span>+ co-founded a tech company</span>
                    <span>+ Launched an online course.</span>
                    <strong>I now make 5x more money. </strong>

                    <span>I couldn't have done it without my 4 focus sessions a day.</span>
                    <div class="author-details">
                        <div class="left">
                            <img src="../../../assets/template/img/home/Moe-Ali.png" alt="">
                        </div>
                        <div class="right">
                            <strong>Moe Ali</strong>
                            <span>ProductFaculty.com</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="desktop-screen2">
                        <ng-lottie class="custom-img" [options]="options" (animationCreated)="animationCreated($event)">
                        </ng-lottie>
                        <!-- <img src="../../../assets/template/img/home/hero-imgv2.png" class="img-fluid custom-img" alt=""> -->
                    </div>
                </div>
                <div class="col-md-3">

                    <div class="for-responsive position-relative h-100">
                        <div class="email-section">
                            <div class="add-txt">
                                <div class="txt-line">
                                    <h3>Enter Email Here For:</h3>
                                </div>
                            </div>


                            <div class="email-input">
                                <input type="email" placeholder="2 MONTHS FREE!" id="useremail" name="useremail"
                                    [(ngModel)]="useremail">
                            </div>


                            <div class="free-btn">
                                <strong>+ FREE Member Gifts </strong>
                                <span>Ends Oct 15th</span>
                            </div>


                            <div class="add-txt">
                                <span>* No Credit Card Required</span>
                            </div>
                        </div>
                    </div>

                </div> <!-- col-md-2 end -->
            </div>

        </div>
    </div>
    <!-- x===== Hero =====x -->

    <!-- Marquee section    -->
    <Marquee class="marquee-custom">
        <h2>>>> <span>FREE GIFTS</span> when you complete sessions in your first 2 months >>> <img
                src="../../../assets/template/img/home/image 3.png" alt="star"> <span>FREE Focus Sand Timers</span>
            after 20 sessions
            <span>>>> <img src="../../../assets/template/img/home/image_1-removebg-preview 2.png" alt="star"> FREE Blue
                light blocking glasses</span> after 50 sessions <span>>></span>
        </h2>
    </Marquee>

    <!-- ===== shocking section ===== -->
    <div class="shocking">
        <div class="left-side">
            <img src="../../../assets/template/img/home/EcFjwskXYAMVd8v-1-1.jpg" alt="star">
        </div>
        <div class="right-side">
            <h2>SHOCKINGLY EFFECTIVE...</h2>
            <span>+ the people are awesome!</span>
            <span>I get 40% more done </span>
            <span>with focus sessions”</span>
            <p>Rachel Connors | Founder, Yellow Leaf Hammocks</p>
            <strong>$1 Million Dollor </strong>
            <div class="invest">
                <p>Investment on <span>SharkTank</span></p>
                <strong>Yeah... That T.V. Show :)</strong>
            </div>
        </div>
    </div>

    <!-- ===== Imagine section ===== -->
    <section class="imagine">
        <div class="container">
            <div class="title">
                <span><strong>Imagine</strong> what will happen if you keep getting distracted </span>
            </div>

            <div class="row with-without">
                <div class="col-md-6">
                    <h4>Without PeakFocus</h4>
                    <ul>
                        <li>
                            <img src="../../../assets/template/img/home/Vector-cross.png" alt="cross">
                            <span>
                                Blown opportunities to make more money.
                            </span>
                        </li>
                        <li>
                            <img src="../../../assets/template/img/home/Vector-cross.png" alt="cross">
                            <span>
                                Missed deadlines & letting people down.
                            </span>
                        </li>
                        <li>
                            <img src="../../../assets/template/img/home/Vector-cross.png" alt="cross">
                            <span>
                                Constantly distracted by technology.
                            </span>
                        </li>
                        <li>
                            <img src="../../../assets/template/img/home/Vector-cross.png" alt="cross">
                            <span>
                                Low capacity for self control.
                            </span>
                        </li>
                        <li>
                            <img src="../../../assets/template/img/home/Vector-cross.png" alt="cross">
                            <span>
                                High stress brought on by procrastination
                            </span>
                        </li>
                        <li>
                            <img src="../../../assets/template/img/home/Vector-cross.png" alt="cross">
                            <span>
                                Unclear goals & unprioritized tasks.
                            </span>
                        </li>
                        <li>
                            <img src="../../../assets/template/img/home/Vector-cross.png" alt="cross">
                            <span>
                                Low self esteem & constant anxiety.
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <div class="animated-img">
                        <img src="../../../assets/template/img/home/Lottie__1 (1).gif" alt="gif">
                    </div>
                </div>
            </div>

            <div class="title">
                <span><strong>Imagine</strong> what your life could look like
                    if you could just stop procrastinating. </span>
            </div>

            <div class="row with-without">
                <div class="col-md-6">
                    <h4>With PeakFocus</h4>
                    <ul>
                        <li>
                            <img src="../../../assets/template/img/home/Vector-right.png" alt="cross">
                            <span>
                                Eliminate procrastination once and for all.
                            </span>
                        </li>
                        <li>
                            <img src="../../../assets/template/img/home/Vector-right.png" alt="cross">
                            <span>
                                Access to a live accounability partner 24/7.
                            </span>
                        </li>
                        <li>
                            <img src="../../../assets/template/img/home/Vector-right.png" alt="cross">
                            <span>
                                Outsmart your undisciplined default habits.
                            </span>
                        </li>
                        <li>
                            <img src="../../../assets/template/img/home/Vector-right.png" alt="cross">
                            <span>
                                Accountability whenever you need it.
                            </span>
                        </li>
                        <li>
                            <img src="../../../assets/template/img/home/Vector-right.png" alt="cross">
                            <span>
                                Instant focused flow with brain music.
                            </span>
                        </li>
                        <li>
                            <img src="../../../assets/template/img/home/Vector-right.png" alt="cross">
                            <span>
                                Better organize your time.
                            </span>
                        </li>
                        <li>
                            <img src="../../../assets/template/img/home/Vector-right.png" alt="cross">
                            <span>
                                Significantly improve productivity, attention and focus.
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <div class="animated-img">
                        <img src="../../../assets/template/img/home/Lottie_02 (1).gif" alt="gif">
                    </div>
                </div>
            </div>

        </div>
    </section>

    <!-- ===== ClubDone section ===== -->
    <section class="clubDone">
        <div class="container">
            <h2>PeakFocus Eliminates Procrastination
                <span>& Increases Your Focus</span>
            </h2>
            <div class="row">
                <div class="col-md-6 box">
                    <div class="box-icon">
                        <img src="../../../assets/template/img/home/Vector-mind.png" alt="mind">
                    </div>
                    <h2>
                        Backed by Science
                    </h2>
                    <p>
                        PeakFocus uses many powerful principles in psychology to boost productivity 300%.
                    </p>
                    <a href="#">LEARN MORE</a>
                </div>

                <div class="col-md-6 box">
                    <div class="box-icon">
                        <img src="../../../assets/template/img/home/Vector-wiseup.png" alt="mind">
                    </div>
                    <h2>
                        A Club For Doers
                    </h2>
                    <p>
                        We all have big goals &
                        we’re going places.
                        Come focus with us!
                    </p>
                    <a href="#">CLUB CULTURE RULES</a>
                </div>
            </div>
            <!-- Free-gift-input -->
            <div class="free-gift">
                <h3>Nothing to Loose!</h3>
                <h4>JOIN THE CLUB TODAY!</h4>
                <div class="contain">
                    <div class="email-input">
                        <input type="email" placeholder="Email" id="useremail" name="useremail" [(ngModel)]="useremail">
                    </div>
                    <div class="start-save">
                        <button type="button" (click)="SubmitEmail('first')">Start Today & Save</button>
                    </div>
                </div>
            </div>
            <!-- Free-gift-input End-->
        </div>
    </section>

    <!-- ===== Section 04 (Win some goods) ===== -->
    <div class="section-04-wrapper py-5">
        <div class="container">
            <div class="header d-flex flex-column align-items-center">
                <h4>Hey, Early Bird!</h4>
                <h1>WIN SOME GOODS!</h1>
                <p>
                    Complete sessions in your first 2 months, and we’ll<br>
                    mail you these goods <a href="#">FOR FREE :)</a>
                </p>
            </div>
            <div class="content pt-5">
                <div class="row d-flex justify-content-between align-items-top ">
                    <div class="col-md-6 mb-5 px-5 early-ring">
                        <img src="../../../assets/template/img/home/section-4-img-1.png" alt="" class="img-fluid">
                    </div>
                    <div class="col-md-6 px-5">
                        <img src="../../../assets/template/img/home/section-4-img-2.png" alt="" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- x===== Section 04 End=====x -->

    <!-- ===== Testimonial ===== -->
    <div class="testimonial-wrapper">
        <div class="container">
            <h1>Hear What Members are saying...</h1>
            <div id="demo" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="carousel-caption">
                            <p><span>I Wrote a Book... </span>+ co-founded a high revenue tech company, and
                                launched a super profitable online course. <a href="#">I make 5x more
                                    money.</a><br><br> I couldn't have done any of those things without 3-4
                                focus sessions a day.</p>
                            <div class="info-box">
                                <div class="img">
                                    <img src="../../../assets/template/img/home/Moe-Ali.png" alt="">
                                </div>
                                <div class="text">
                                    <h6>Moe Ali</h6>
                                    <span>ProductFaculty.com</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="carousel-caption">
                            <p><span>I love PeakFocus! </span>I like using it when I do my strategic planning. No
                                distractions. It’s instant.
                                <br><br>I highly recommend everyone try PeakFocus. You’ll be surprised how much
                                you like it.
                            </p>
                            <div class="info-box">
                                <div class="img">
                                    <img src="../../../assets/template/img/home/Sheiva.png" alt="">
                                </div>
                                <div class="text">
                                    <h6>Sheiva Sajadpour</h6>
                                    <span>7 figure ecommerce entrepreneur</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="carousel-caption">
                            <p><span>I’m always working... </span>but I didn’t know how focus sessions could
                                help me with things I don’t always make time for. I am reading more, writting
                                more, and I’m keeping in touch with friends. <br><br> I was skeptical at first,
                                but now I’m hooked. </p>
                            <div class="info-box">
                                <div class="img">
                                    <img src="../../../assets/template/img/home/Ryan.png" alt="">
                                </div>
                                <div class="text">
                                    <h6>Ryan S</h6>
                                    <span>Machine Learning Engineer</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="carousel-caption">
                            <p><span>In a Post Doc, I have a lot of writing...</span>being only accountable to
                                myself, it is all too easy to fall behind. Now that I set up consistent focus
                                sessions, I am consistently making my writing goals on time.<br><br> I am so
                                happy I found PeakFocus!</p>
                            <div class="info-box">
                                <div class="img">
                                    <img src="../../../assets/template/img/home/tina.png" alt="">
                                </div>
                                <div class="text">
                                    <h6>Tina</h6>
                                    <span>Machine Learning Post Doc</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> <a class="carousel-control-prev" href="#demo" data-slide="prev"> <i
                        class='fas fa-chevron-left'></i> </a> <a class="carousel-control-next" href="#demo"
                    data-slide="next"> <i class='fas fa-chevron-right'></i> </a>
            </div>
        </div>
    </div>
    <!-- x===== Testimonial End =====x -->

    <!-- x===== Better Focus Section =====x -->
    <section class="better-focus">
        <div class="box-contain">
            <h1>Better Focus 100% Guaranteed</h1>
            <a href="#">TRY TODAY</a>
        </div>
    </section>
    <!-- x===== Better Focus Section End =====x -->

    <!-- ===== secret Weapon section ===== -->
    <section class="secret-weapon">
        <div class="container">
            <h2>
                PeakFocus Member Benefits
            </h2>
            <!-- Mobile screen div -->
            <div class="video-custom mobile-screen3">
                <img _ngcontent-ccl-c59="" id="video-gif" src="../../../assets/template/img/home/GIF-for-pc.gif">
                <img src="../../../assets/template/img/home/secret-desktop.png" class="img-fluid" alt="secret">
            </div>
            <!-- Mobile screen div End -->
            <div class="row icons-contain">
                <div class="col-md-3">
                    <div class="box">
                        <img src="../../../assets/template/img/home/doers-icon.png" class="img-fluid" alt="icon">
                        <h4>
                            24/7 Doers Co-Working Club
                        </h4>
                    </div>
                    <div class="box brain">
                        <img src="../../../assets/template/img/home/brain-icon.png" class="img-fluid" alt="icon">
                        <h4>
                            Brain Music designed for 4 different brain states
                        </h4>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="video-custom desktop-screen3">
                        <img _ngcontent-ccl-c59="" id="video-gif"
                            src="../../../assets/template/img/home/GIF-for-pc.gif">
                        <img src="../../../assets/template/img/home/secret-desktop.png" class="img-fluid" alt="secret">
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="box peak">
                        <img src="../../../assets/template/img/home/peak-icon.png" class="img-fluid" alt="icon">
                        <h4>
                            Peak Productivity Progress Groups
                        </h4>
                    </div>
                    <div class="box custom-space">
                        <img src="../../../assets/template/img/home/VIP1.png" class="img-fluid" alt="icon">
                        <h4>
                            Daily Planning Focus Assistants
                        </h4>
                    </div>
                </div>
            </div>
            <!-- Free-gift-input -->
            <div class="free-gift">
                <h3>2 FREE Months & Free Gifts untill Oct 1</h3>
                <div class="contain">
                    <div class="email-input">
                        <input type="email" placeholder="Email" id="seconduseremail" name="seconduseremail"
                            [(ngModel)]="seconduseremail">
                    </div>
                    <div class="start-save">
                        <button type="button" (click)="SubmitEmail('second')">Start Today & Save</button>
                    </div>
                </div>
            </div>
            <!-- Free-gift-input End-->
        </div>
    </section>

    <!-- ===== Section 06 ===== -->
    <div class="section-06-wrapper py-5">
        <div class="container">
            <h2>Doers<span>Coworking Club</span></h2>
            <div class="content">
                <div class="row d-flex justify-content-between">
                    <div class="col-md-6">
                        <p>
                            PeakFocus virtual coworking club pairs you
                            with friendly accountability buds for
                            50 min focus sessions at anytime.
                        </p>
                        <p>
                            <span>Instant laser focus -</span> Get more done in a few days than most people do in a
                            month.
                        </p>
                        <p>
                            <span>Match groups -</span>Get more done in a few days than most people do in a month.
                        </p>
                    </div>
                    <div class="col-md-6">
                        <img src="../../../assets/template/img/home/ClubDone-Landing-Page.png" alt="" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- x===== Section 06 =====x -->

    <!-- ===== Section 08 ===== -->
    <div class="section-08-wrapper">
        <section class="custom-bg">
            <div class="container">
                <h3 class="text-center mb-5">How it works</h3>

                <div class="content row pb-5">
                    <div class="col-md-12 col-sm-12 ">
                        <div class="skew-btn-cover ">
                            <a class="parallelogram btn">
                                <span class="skew-fix">Co-Working Club</span>
                            </a>
                        </div>
                        <div class="d-flex align-items-center wrappers">
                            <div class="video-clip">
                                <img id="pick-time" src="../../../assets/template/img/home/pick-a-time.gif" />
                                <img src="../../../assets/template/img/home/Ellipse-11.png"
                                    class="img-fluid img-resp desktop-img">
                                <img src="../../../assets/template/img/home/mob-img-6.png"
                                    class="img-fluid img-resp mob-img">
                            </div>
                            <span><a>1. Pick a time</a><br>Book a time on the calendar for whenever you need to
                                focus.</span>
                        </div>
                        <div class="d-flex align-items-center wrappers">
                            <div class="video-clip">
                                <img id="video-session" src="../../../assets/template/img/home/video-session.gif" />
                                <img src="../../../assets/template/img/home/Ellipse-12.png"
                                    class="img-fluid img-resp desktop-img">
                                <img src="../../../assets/template/img/home/mob-img-5.png"
                                    class="img-fluid img-resp mob-img">
                            </div>
                            <span><a>2. Join Video Session</a><br>Start your video session at the set time.Take the 1st
                                state what<br>you will be working on. </span>
                        </div>
                        <div class="d-flex align-items-center wrappers">
                            <img src="../../../assets/template/img/home/Ellipse-8.png"
                                class="img-fluid img-resp desktop-img">
                            <img src="../../../assets/template/img/home/mob-img-1.png"
                                class="img-fluid img-resp mob-img">
                            <span><a>3. Do your work</a><br>Take the 1st min to tell your partner what you will
                                be<br>working on. Go on mute, and get it done!</span>
                        </div>
                    </div>
                </div>
                <!-- Free-gift-input -->
                <div class="free-gift">
                    <h3>Nothing to Loose!</h3>
                    <h4>TRY IT OUT TODAY!</h4>
                    <div class="contain">
                        <div class="email-input">
                            <input type="email" placeholder="Email" id="useremailthird" name="useremailthird"
                                [(ngModel)]="useremailthird">
                        </div>
                        <div class="start-save">
                            <button type="button" (click)="SubmitEmail('third')">2 Months Free!</button>
                        </div>
                    </div>
                </div>
                <!-- Free-gift-input End-->

            </div>
        </section>
        <!-- x===== Section 08 =====x -->

        <!-- ===== Section 07 ===== -->
        <div class="section-07-wrapper py-5">
            <div class="container">
                <div class="content">
                    <h1>Peak Productivity
                        <br>Progress Parlors
                    </h1>
                    <div class="row d-flex justify-content-between align-items-center">
                        <div class="col-md-6">
                            <img src="../../../assets/template/img/home/ClubDone-section-7.png" alt=""
                                class="img-fluid">
                        </div>
                        <div class="col-md-6">
                            <p>
                                For the extra motivated! Weekly
                                For the extra motivated! Weekly
                                accountability groups. Get connected with
                                3 doers similar to you. Check in and cheer
                                eachother on, as you watch each other schieve their goals!
                            </p>
                            <p>
                                <span>Get clear on your goals -</span> Use out tools to help
                                you set better goals.
                            </p>
                            <p>
                                <span>Peak productivity - </span>
                                2 min videos to
                                watch every week.
                            </p>
                            <p>
                                <span>Meet others like you -</span>
                                Being ambitious
                                can be lonely. Meet others on a similar
                                journey as you!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <!-- x===== Section 07 =====x -->


        <!-- ===== Section 08 ===== -->
        <div class="section-08-wrapper">
            <div class="container">
                <h3 class="text-center mb-5">How it works</h3>

                <div class="content content-2 row justify-content-lg-end justify-content-md-center">
                    <div class="col-md-9 col-sm-12 mt-md-5 pt-md-5">
                        <div class="skew-btn-cover">
                            <a class="parallelogram parallelogram-2 ">
                                <span class="skew-fix">Progress Parties</span>
                            </a>
                        </div>
                        <div class="d-flex align-items-center wrappers">
                            <span><a>1. Answer a few questions</a>
                                <br>Just a few short questions to helps us get to
                                know your goals and find a good time to meet.</span>
                            <img src="../../../assets/template/img/home/Ellipse-13.png"
                                class="img-fluiid img-resp desktop-img">
                            <img src="../../../assets/template/img/home/mob-img-3.png"
                                class="img-fluiid img-resp mob-img">
                        </div>
                        <div class="d-flex align-items-center wrappers">
                            <span><a>2. Zoom 1x per week</a><br>
                                Get connected with 3-4 other doers like you! Meet at the same time every week. Make new
                                friends!</span>
                            <img src="../.../../../assets/template/img/home/Ellipse-14.png"
                                class="img-fluiid img-resp desktop-img">
                            <img src="../.../../../assets/template/img/home/mob-img-4.png"
                                class="img-fluiid img-resp  mob-img">
                        </div>
                        <div class="d-flex align-items-center wrappers">
                            <span><a>3. Learn & Make progress </a><br>
                                Just a few short questions to helps us get to
                                know your goals and find a good time to meet.</span>
                            <img src="../../../assets/template/img/home/Ellipse-15.png"
                                class="img-fluiid img-resp desktop-img">
                            <img src="../../../assets/template/img/home/mob-img-2.png"
                                class="img-fluiid img-resp  mob-img">
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- x===== Section 08 =====x -->

        <div class="footer-section py-5 mt-5">
            <div class="container">
                <h1 class="text-center main-heading ">Don’t Miss Out!</h1>
                <div class="row txt-price">
                    <div class="col-md-8">
                        <h3>
                            Prices will increase!
                        </h3>
                        <h4>+ you won’t get FREE trial with prizes!</h4>
                        <p>
                            Don’t let another week go by<br>
                            without getting closer to<br>
                            achieving your dreams!<br>
                        </p>
                    </div>
                    <div class="col-md-4">
                        <div class="gif-contain">
                            <img _ngcontent-dty-c59="" _ngcontent-ccl-c59="" id="video-gif"
                                src="../../../assets/template/img/home/GIF-for-pc.gif">
                            <img src="../../../assets/template/img/home/secret-desktop.png" class="img-fluiid"
                                alt="desk">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ========== Better Focus section ========== -->
    <section class="better-focus-v2">
        <h1>Better Focus 100% Guaranteed</h1>
        <a href="#">TRY TODAY</a>
    </section>
</div>
<!-- Copy right section -->
<div class="copyright-section">
    <div class="container">
        <div class=" row ">
            <div class="col-md-4">
                <img src="../../../assets/template/img/Peak-Focus-Logo.png" class="img-fluiid img-logo-f" alt="F-logo">
            </div>
            <div class="col-md-4">
                <a href="#">Info</a>
            </div>
            <div class="col-md-4 contact-info">
                <a href="#">Contact Us</a><br>
                <a href="#">Hello@ClubDone.com</a>
                <button class="btn btn-google btn-user btn-block" (click)="authService.GoogleAuth('login')">
                    <img src="../../../assets/btn_google_signin_dark_normal_web@2x.png" />
                </button>
            </div>
        </div>
    </div>
    <!-- All right section -->
    <div class="all-rights">
        <span> Copyright © PeakFocus 2021. All Rights Reserved.</span>
    </div>
</div>
<!-- Copy right section -->

<!-- Video pop up -->
<script>
    $('#myModal').on('shown.bs.modal', function () {
        $('#myInput').trigger('focus')
    })
</script>
<!-- Video pop up End -->

<!-- jQuery CDN -->
<script>
    document.getElementById('pick-time').play();
    document.getElementById('ideo-session').play();
</script>


<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<!-- Bootstrap 5 CDN -->
<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"
    integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p"
    crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js"
    integrity="sha384-cVKIPhGWiC2Al4u+LWgxfKTRIcfu0JTxR+EQDz/bgldoEyl4H0zUF0QKbrJ0EcQF"
    crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.bundle.min.js"></script>