import { Injectable, NgZone } from '@angular/core';
import { Task } from "../../services/user";
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';

import { Router } from "@angular/router";
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TasksService {

  tasksRef: AngularFirestoreCollection<Task>;
  product: Observable<Task[]>;
  taskList:any=[];
  currentUser:any;

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private db: AngularFirestore,

  ) {
    this.tasksRef = this.afs.collection<Task>('tasks');
    this.currentUser = JSON.parse(localStorage.getItem('user'));
  }

 async GetTaskByUserId() {
    var object = this;
     await this.afs.collection('tasks', ref => ref.where('userId', '==', this.currentUser.uid)).get().subscribe((querySnapshot) => {
      querySnapshot.forEach((doc:any)=> {
       object.taskList.push(
          {
            id: doc.data().taskId,
            title:doc.data().taskName,
            start: doc.data().dateTime
        });
      });
      return object.taskList;
    });
  }

  AddTaskData(task) {
    const taskData: Task = {
      taskId: this.afs.createId(),
      userId: task.userId,
      taskName: task.taskName,
      taskTime: task.time,
      taskDuration: task.duration,
      taskDate: task.date,
      days: task.days,
      dateTime:task.dateTime,
      joinuserflag:task.joinuserflag,
       joinuserflagtaskid:task.joinuserflagtaskid,
       joinuserflaguserid:task.joinuserflaguserid
    }
    this.afs.collection('tasks').doc(taskData.taskId).set(taskData);
  }






}
