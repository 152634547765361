<div class="wrapper" id="sectionsecond">
  <div class="middle-panel ">
    <div class="loading" *ngIf="loader">
      <div class="loading-message">
        Mindful moment: <br />
        Take a deep breath… <br />
        🧘  <br />
        Long exhale… <br /> <br />

        Your Focus Session Calendar is loading up. <br /><br /><br />

        🧑‍💻 <br />
        May focus be with you <br /><br /><br /><br /><br />
      </div>
      Loading&#8230;
    </div>
    <!-- <button (click)="clicktest()">Test</button> -->
    <div class="row mb-5">
      <div class="col-md-4">
        <div class="event" *ngFor="let item of upCommingEvents | slice:0:1;let i=index">
          <span class="beta-color text-center">Hey {{currentUser.firstname}}! <img height="20" src="../../../assets/template/img/smile.png" /></span>
          <button type="button" class="btn btn-primary view-all" (click)="upcommingpage()">View All</button>
          <button type="button" class="btn btn-primary join" *ngIf="upcommingmeetingschedule === 'true'"
            (click)="JoinMeeting(item)">Join</button>
          <button type="button" class="btn btn-primary join disabled"
            *ngIf="upcommingmeetingschedule !== 'true'">Join<span>10 min before</span></button>
          <h5>Next Focused Session</h5>
          <div class="title">
            <h6>
              <i class="far fa-clock"></i> <span class="organiser text-lowercase"
                [innerHTML]="DateForUI(item.taskStartTime,item.taskEndTime)"></span>
            </h6>
            <span class="name"><i class="fa fa-users"></i>{{item.taskName}}</span>
          </div>
          <div class="actions">
            <div class="btn-group">
              <button class="btn btn-flat" (click)="taskDetails('Upcoming',item.taskId)"><i class="fa fa-edit"></i></button>
              <button class="btn btn-flat" (click)="deleteTaskClick(item.taskId)"><i class="fas fa-times"></i></button>
            </div>
          </div>
        </div>
        <div class="event no-task" *ngIf="!upCommingEvents || upCommingEvents.length === 0">
          <span class="beta-color text-center">Hey {{currentUser.firstname}}! <img height="20" src="../../../assets/template/img/smile.png" /></span>
          <h5 class="text-center">No Focus Sessions Booked Yet</h5>
          <button type="button" class="btn btn-primary view-all shadow" (click)="upcommingpage()">View All</button>
        </div>
      </div>
      <div class="col-md-8">        
        <div class="circle-container">
          <div class="circle">
            <span class="value">{{completedsessioncount}}</span>
            <label>Complete Sessions</label>
          </div>
          <div class="circle timeliness">
            <div class="value">
              <div><i>Score</i><b>{{timelinessScore}}%</b></div>
            </div>
            <label>Timeliness </label>
          </div>
          <div class="circle">
            <span class="value"><img src="../../assets/groups.png"></span>
            <label>Groups</label>
            <!-- <p>FREE Planner click here</p> -->
            <div class="group-list">
              <div class="grplist">
                <div class="item" *ngFor="let item of allGroupList">
                  <label for="test1" (click)="openNewTab(item.slug)">{{item.name}}</label>
                </div>
              </div>
              <div class="grpbtn">
                <button class="btn btn-primary btn-sm mb-1" (click)="openNewTab('list-grp-all')">See all group</button>
                <button class="btn btn-primary btn-sm" (click)="openNewTab('list-grp-all')">Create group</button>
              </div>
            </div>
          </div>
           <div class="circle">
            <span class="value"><img src="../../assets/match-group.png"></span>
            <label>Current Match Group</label>
            <p>Set Preference</p>
            <div class="group-list">
              <h6>Check the group you want to be matched with</h6>
              <div class="item" *ngFor="let item of groupList;let i=index">
                <input *ngIf="i==0" type="radio" id="{{ 'wave2' + i }}" name="radio-group" (change)="SelectedGroup(0,null)"
                  [checked]="preferedGroup==null">
                <label *ngIf="i==0" for="{{ 'wave2' + i }}">None</label>
              </div>
              <div class="item" *ngFor="let item of groupList;let i=index">
                <input type="radio" id="{{ 'wave' + i }}" name="radio-group" (change)="SelectedGroup(item.id,item.name)"
                  [checked]="item.id===preferedGroup">
                <label for="{{ 'wave' + i }}">{{item.name}}</label>
              </div>
              <div class="item" *ngIf="!groupList.length">
                <label>You are not a member of any group!</label>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <label class="mr-3 calendarhead">Gmail Calendar</label>
    <label class="switch">
      <input type="checkbox" id="ToggleswitchId" (change)="getPreferredCalendar($event)" />
      <span class="slider round"></span>
    </label> <span class="ml-2 calendarstatus"> {{gmailcalendertext}}</span>

    <full-calendar class="mt-4" *ngIf="initialized" id="calendar" #calendar [options]="calendarOptions"></full-calendar>

    <div class="remove-continue" *ngIf="bookingselected">
      <button class="btn btn-remove" (click)="removeSelectedBooking()">Remove {{bookingselctioncount}} Booking</button>
      <button class="btn btn-continue" [disabled]="clicked" (click)="continueBooking(); clicked;">Continue to book
        {{bookingselctioncount}}
        Session</button>
    </div>
  </div>
  <div class="right-panel">

    <div class="goal-div shadow mt-3">
      <h4 class="alert-heading">Your Goal:</h4>
      <p>{{goal_ans}}.</p>
    </div>

    
    <div cdkDropList class="task-list shadow" (cdkDropListDropped)="drop($event)">

      <h2 class="title">
        <button type="button" class="btn btn-flat add-task" data-toggle="modal" data-target="#createTask" #openModal
          (click)="CreateTask()"><i class="fa fa-plus"></i> Add Task</button>
      </h2>

      <div class="task-row head">
        <div class="task-column asign">
          <h5>
            <span>Top 3 Tasks</span>
            <button class="open" [ngClass]="{'open': toggle, '': !toggle}" (click)="btn_tgl_dwn()">
              <i class="fa fa-angle-down" id="toggledown"></i>
              <i class="fa fa-angle-up"></i>
            </button>
          </h5>
        </div>
        <div class="task-column time">Est Time</div>
        <div class="task-column date">Due Date</div>
      </div>
      
      <div class="task-container open" [ngClass]="{'open': toggle, '': !toggle}">
        <div class="task-row" *ngFor="let item of totalremainderList | slice:0:3;let i=index" cdkDrag>
          <div class="task-custom-placeholder" *cdkDragPlaceholder></div>
          <div class="task-column asign">{{i+1}} <i class="fa fa-check"
              (click)="UpdateDoneTask(item.id)"></i><span>{{item.title}}</span></div>
          <div class="task-column time">{{item.taskDuration}}:{{item.taskDurationmin}}</div>
          <div class="task-column date">{{ (item.start!==null)? (item.start | date:'dd-MM-yy'):""}}<button
              (click)="remainderDetails('remainderTasks',item.id)" class="delete"><i class="fa fa-edit"></i></button>
            <button (click)="deletedetails('remaindertasks', item.id)" class="delete"><i
                class="fas fa-times"></i></button>
          </div>
        </div>
      </div>
      <h5 class="mt-4">
        <span>Tasks</span>
        <button class="open" [ngClass]="{'open': togglemaster, '': !togglemaster}" (click)="btn_tgl_dwn_master()">
          <i class="fa fa-angle-down"></i>
          <i class="fa fa-angle-up"></i>
        </button>

      </h5>
      <div class="task-container open" [ngClass]="{'open': togglemaster, '': !togglemaster}">
        <div class="task-row dragdrop" *ngFor="let item of totalremainderList | slice:3;let i=index" cdkDrag>
          <div class="task-custom-placeholder" *cdkDragPlaceholder></div>
          <div class="task-column asign">{{i+1}} <i class="fa fa-check"
              (click)="UpdateDoneTask(item.id)"></i>{{item.title}}</div>
          <div class="task-column time">{{item.taskDuration}}:{{item.taskDurationmin}}</div>
          <div class="task-column date">{{ (item.start!==null)? (item.start | date:'dd-MM-yy'):""}} <button
              (click)="remainderDetails('remainderTasks',item.id)" class="delete"><i
                class="fa fa-edit"></i></button><button (click)="deletedetails('remaindertasks', item.id)"
              class="delete"><i class="fas fa-times"></i></button></div>
        </div>
      </div>
      <h5 class="mt-4">
        <span>Done</span>
        <button class="open" [ngClass]="{'open': toggledone, '': !toggledone}" (click)="btn_tgl_dwn_done()">
          <i class="fa fa-angle-down"></i>
          <i class="fa fa-angle-up"></i>
        </button>
      </h5>
      <div class="task-container open done-task" [ngClass]="{'open': toggledone, '': !toggledone}">
        <div class="task-row dragdrop" *ngFor="let item of doneTaskList | slice:0;let i=index">
          <div class="task-column asign">{{i+1}} <i class="fa fa-check active"></i>{{item.title}}</div>
          <div class="task-column time"> {{item.taskDuration}}:{{item.taskDurationmin}}</div>
          <div class="task-column date">{{ (item.start!==null)? (item.start | date:'dd-MM-yy'):""}} <button
              (click)="deletedetails('taskDoneList', item.id)" class="delete"><i class="fas fa-times"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button type="button" class="btn btn-primary" style="display: none;" data-toggle="modal"
    data-target="#detailTaskModel" #detailTaskModel></button>

  <div class="modal fade" id="detailTaskModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered" role="document">
      <div class="modal-content">
        <form class="login100-form validate-form" [formGroup]="taskForm" novalidate="">
          <div class="modal-header">
            <div class="form-group">
              <input type="text" name="" value="{{taskForDetails[0].tasktittle}}"
                (focusout)="updateeventname($event, taskForDetails[0].taskId)" />
              <button></button>
            </div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closedetailsModal>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Date</label> <br />
                  <span class="value">{{taskDatedetails | date:'dd-MM-yy'}}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Time</label> <br />
                  <span class="value">{{tasktimedetails}}</span>
                </div>
              </div>
            </div>
            <hr />
            <div class="repeat-container">
              <label>Frequency</label>
              <div class="repeat" [ngClass]="togglerepeatdaily ||  togglerepeatweek ? 'd-none': ''">
                <button class="btn btn-flat-theme" (click)="togglerepeatdaily = !togglerepeatdaily">Repeat
                  Daily</button>
                <button class="btn btn-flat-theme" (click)="togglerepeatweek = !togglerepeatweek">Repeat Weekly</button>
              </div>
              <div class="form-group d-flex" [ngClass]="togglerepeatdaily ? 'active': ''">
                <label class="mr-1">Repeat Daily</label>
                <select class="form-control mr-1" (change)="countselect($event)">
                  <option *ngFor="let itemd of itemsarr;" value="{{itemd}}">{{itemd}}</option>
                </select>
                <button class="btn btn-flat-theme save"
                  (click)="repeat_event('daily', taskForDetails[0].taskId)">Save</button>
                <button class="btn btn-primary close" (click)="togglerepeatdaily = !togglerepeatdaily"><i
                    class="fas fa-times"></i></button>
              </div>
              <div class="form-group d-flex" [ngClass]="togglerepeatweek ? 'active': ''">
                <label class="mr-1">Repeat Weekly</label>
                <select class="form-control mr-1" (change)="countselect($event)">
                  <option *ngFor="let itemd of itemsarr;" value="{{itemd}}">{{itemd}}</option>
                </select>
                <button class="btn btn-flat-theme save"
                  (click)="repeat_event('weekly', taskForDetails[0].taskId)">Save</button>
                <button class="btn btn-primary close" (click)="togglerepeatweek = !togglerepeatweek"><i
                    class="fas fa-times"></i></button>
              </div>
            </div>
            <hr />
            <div class="partner-detail">
              <div class="pp">
                <img src="https://cdn.pixabay.com/photo/2017/06/13/12/53/profile-2398782_1280.png">
              </div>
              <div class="detail">
                <label>PARTNER</label>
                <span class="name">{{JoinUser ? JoinUser : 'No partner Matched'}}</span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary save" (click)="selfBooked('delete')" *ngIf="joinflag == ''">Delete</button>
            <button type="submit" class="btn btn-danger" (click)=" deleteTaskClick(taskForDetails[0].taskId)"
              *ngIf="joinflag">Cancel
              Session</button>
            <button type="button" class="btn btn-light" (click)="close('detailsModal')">Close</button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="modal fade" id="createTask" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered" role="document">
      <div class="modal-content">
        <form class="login100-form validate-form" [formGroup]="taskForm" (ngSubmit)="remainderSubmit()">
          <div class="modal-header justify-content-center">
            <h5 class="modal-title" id="exampleModalLongTitle">{{TaskModalTitle}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeModal>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-3 mt-3 justify-content-center">
              <div class="col-10">
                <div class="form-group">
                  <label>Task Description</label>
                  <input type="text" class="form-control" formControlName="taskName" />
                </div>
                <div *ngIf="submitted && f.taskName.errors" class="text-danger text-center validate-error">
                  <div *ngIf="f.taskName.errors.required">Task name is required !</div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center mb-3">
              <div class="col-md-7">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="mr-4">Estimated Time</label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group d-flex">
                      <select class="mr-1" class="form-control" formControlName="duration"
                        [ngClass]="{ 'is-invalid': submitted && f.duration.errors }">
                        <option selected>0</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                      </select>
                      <span class="mr-2 ml-2">Hrs</span>
                    </div>
                    
                  </div>
                  <div class="col-md-6">
                    <div class="form-group d-flex">
                      <select class="form-control" formControlName="durationmin"
                        [ngClass]="{ 'is-invalid': submitted && f.durationmin.errors }">
                        <option *ngFor="let item of items; let i = index">
                          {{item}}
                        </option>
                      </select>
                      <span class="ml-2">Mins</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div *ngIf="submitted && f.duration.errors" class="text-danger text-center validate-error select-error">
                      <div *ngIf="f.duration.errors.required">Estimated Time is required !</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-center mb-3">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Due Date</label>
                  <input type='text' class="form-control" id='datetimepicker1' autocomplete="off" />
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-md-12 text-center">
              <button type="submit" class="btn px-5 btn-primary">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <button type="button" class="btn btn-primary" style="display: none;" data-toggle="modal" data-target="#createTask"
    #taskeventdetailopenModal></button>

  <div class="modal fade" id="taskeventdetail" tabindex="-1" role="dialog" aria-labelledby="taskeventdetailTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">{{taskForDetails[0].taskName}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" #taskeventcloseModal>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="text-center row">
            <div class="col-md-4">
              <label>Date</label>
              <p>{{taskForDetails[0].taskDate | date:'dd-MM-yy'}}</p>
            </div>
            <div class="col-md-4">
              <label>Time</label>
              <p>{{taskForDetails[0].taskTime}}</p>
            </div>
            <div class="col-md-4">
              <label>Duration</label>
              <p>{{taskForDetails[0].taskDuration}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group d-flex">
                <label class="mr-5">What Days?</label>
                <div class="d-flex w-100">
                  <div class="form-group check">
                    <label>Su</label>
                    <input type="checkbox" class="echeck Sun" name="" (change)="dayslection($event,'Sun')">
                  </div>
                  <div class="form-group check">
                    <label>Mo</label>
                    <input type="checkbox" class="echeck Mo" name="" (change)="dayslection($event,'Mo')">
                  </div>
                  <div class="form-group check">
                    <label>Tu</label>
                    <input type="checkbox" class="echeck Tu" name="" (change)="dayslection($event,'Tu')">
                  </div>
                  <div class="form-group check">
                    <label>We</label>
                    <input type="checkbox" class="echeck We" name="" (change)="dayslection($event,'We')">
                  </div>
                  <div class="form-group check">
                    <label>Th</label>
                    <input type="checkbox" class="echeck Th" name="" (change)="dayslection($event,'Th')">
                  </div>
                  <div class="form-group check">
                    <label>Fr</label>
                    <input type="checkbox" class="echeck Fr" name="" (change)="dayslection($event,'Fr')">
                  </div>
                  <div class="form-group check">
                    <label>Sa</label>
                    <input type="checkbox" class="echeck Sa" name="" (change)="dayslection($event,'Sa')">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button type="button" class="btn btn-primary" style="display: none;" data-toggle="modal" data-target="#deleteTask"
  #deleteopenModal></button>

<div class="modal fade" id="deleteTask" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered custom-alert" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Delete Task</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #deleteCloseModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h5 class="text-center">You sure you about this?! ☹️</h5>
        <p class="text-center">Deleting task {{taskForDetails[0].taskName}}. This action is irreversible.</p>

        <div class="row text-center">
          <div class="col-md-12">
            <p class="confirm-button">
              <a class="mr-2"><button class="btn btn-primary" data-dismiss="modal" aria-label="Close"
                  #deleteCloseModal>Cancel</button>
              </a>
              <a>
                <button class="btn btn-danger" (click)="sure_Delete_Task(deleteTask)">delete it</button>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button type="button" class="btn btn-primary" style="display: none;" data-toggle="modal" data-target="#Booktask"
  #taskbooktaskopenModal></button>

<div class="modal fade" id="Booktask" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered custom-alert" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{meetingbutton}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #deleteBookCloseModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row text-center">
          <div class="col-md-12">
            <p class="confirm-button">
              <a>
                <button class="btn btn-primary" (click)="sure_book_it()">Book ?</button>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="userProfileDetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered custom-alert" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">User Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closePopUpModal()"
          #CloseuserProfileDetailsModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row align-items-center">
          <div class="col-md-4">
            <div class="profile">
              <div class="avatar">
                <img src="{{userimageSrc}}" alt="Circle Image" class="img-raised rounded-circle img-fluid">
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="name">
              <h3 class="title mb-0 text-capitalize">{{userDisplayName}}</h3>
              <h6 class="mb-0">{{userEmail}}</h6>
              <p class="mb-1 mt-1">Member since : {{userCreationDate}} </p>
              <p><i class="fa fa-map-marker" aria-hidden="true"></i>{{userRegionWithTimeZone}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<button type="button" class="btn btn-primary" style="display: none;" data-toggle="modal" data-target="#confirmBooking"
  #confirmBookingModal></button>

<div class="modal fade" id="confirmBooking" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true" #confirmBookingCloseModal>
  <div class="modal-dialog modal-dialog-centered custom-alert" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Meeting Booking</h5>
      </div>
      <div class="modal-body">
        <h5 class="text-center">Remove Marked Event</h5>
        <p class="text-center">Are you sure you dont want to book selected session!</p>
        <div class="row text-center">
          <div class="col-md-12">
            <p class="confirm-button">
              <a class="mr-2"><button class="btn btn-danger" data-dismiss="modal" aria-label="Close"
                  (click)="cancelevent()">No</button></a>
              <a><button class="btn btn-success" (click)="confirmremoveevent()">Yes</button></a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Meeting Booked Popup -->
<div class="meeting-booked-popup" id="book-meeting-notification">
	<div class="popup-body">
		<div class="d-flex justify-content-center mb-2">
			<img src="../../../assets/template/img/thumbs-up.png" style="height: 25px;">
			<h3 class="m-0 pl-2">Focus Sessions</h3>
		</div>
		<div class="d-flex justify-content-center">
			<img src="../../../assets/template/img/square-tick.png" style="height: 25px;">
			<h3 class="m-0 pl-2">Booked!</h3>
		</div>
	</div>
</div>