<div class="container">
  <div class="limiter">
    <div class="container-login">
      <div class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-50">
        <form class="login100-form validate-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <span class="login100-form-title p-b-33">Let's sign you in.</span>
          <div class="row">
            <div class="col-md-12 d-md-flex">
              <a href="javascript:void(0)" class="btn btn-google btn-user btn-block"
                (click)="authService.GoogleAuth('login')">
                <img src="../../../assets/btn_google_signin_dark_normal_web@2x.png" />
              </a>
              <a href="javascript:void(0)" class="btn btn-facebook btn-user btn-block"
                (click)="authService.FacebookAuth('login')">
                <img src="../../../assets/facebook-icon.png" />
              </a>
            </div>
          </div>

          <hr />
          <div class="alert alert-danger alert-dismissible fade show scstcss" role="alert" *ngIf="errorEmail">
            <span [innerHTML]="errorEmail"></span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close"
              (click)="closeForgotModel('UI')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="alert alert-success alert-dismissible fade show scstcss" role="alert" *ngIf="successEmail">
            <span [innerHTML]="successEmail"></span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close"
              (click)="closeForgotModel('UI')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <label>Email</label>
          <div class="wrap-input100 validate-input mb-2">
            <input class="input100" type="text" name="email" formControlName="email" placeholder="Email"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            <span class="focus-input100-1"></span>
            <span class="focus-input100-2"></span>
          </div>
          <div *ngIf="submitted && f.email.errors" class="text-danger validate-error">
            <div *ngIf="f.email.errors.required">Email is required !</div>
            <div *ngIf="f.email.errors.email">Email must be a valid email address !</div>
          </div>
          <label>Password</label>
          <div class="wrap-input100 validate-input">
            <input class="input100" type="password" formControlName="password" name="pass" placeholder="Password"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
            <span class="focus-input100-1"></span>
            <span class="focus-input100-2"></span>
          </div>
          <div *ngIf="submitted && f.password.errors" class="text-danger validate-error">
            <div *ngIf="f.password.errors.required">Password is required !</div>
            <div *ngIf="f.password.errors.minlength">Password must be at least 8 characters !</div>
          </div>
          <div class="container-login100-form-btn m-t-20">
            <button type="submit" class="btn theme-gradient-reverse btn-user btn-block">
              Login
            </button>
          </div>
          <div class="divforgotpassword mt-4 text-center">
            <a href="javascript:void(0)" (click)="forgotpassword()">Forgot Password?</a>
          </div>
          <div class="divforgotpassword text-center">New user? <a href="javascript:void(0)"
              routerLink="/sign-up">Sign up here</a></div>
        </form>
      </div>
    </div>
  </div>

  <button type="button" class="btn btn-primary" style="display: none;" data-toggle="modal"
    data-target="#exampleModalCenter" #openModal>
    Launch demo modal
  </button>

  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <form class="login100-form validate-form" [formGroup]="forgotForm" (ngSubmit)="forgotFormSubmit()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Reset Password</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeModal>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-center">Please enter your email address to request a password reset.</p>
            <div class="wrap-input100 validate-input">
              <input class="input100" type="text" name="email" formControlName="Email" placeholder="Email Id"
                [ngClass]="{ 'is-invalid': submittedForgot && ff.Email.errors }">
              <span class="focus-input100-1"></span>
              <span class="focus-input100-2"></span>
            </div>
            <div *ngIf="submittedForgot && ff.Email.errors" class="text-danger validate-error">
              <div *ngIf="ff.Email.errors.required">Email is required !</div>
              <div *ngIf="ff.Email.errors.email">Email must be a valid email address !</div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>