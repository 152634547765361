import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../../services/auth.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/services/user';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { TribeService } from "src/app/services/tribe/tribe.service";
import { ActivatedRoute, Router } from '@angular/router';

declare var moment: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  signupForm: FormGroup;
  submitted = false;
  errorEmail: string;
  region_with_timezone: any;
  current_Date: any;
  adminToken: any;
  usersList = [];
  passwordIsValid = false;
  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    private tribe: TribeService,
    public router: Router,
    private route: ActivatedRoute
  ) {
    if (this.authService.currentUserValue) {
      this.router.navigate(['/calendar-dashboard']);
    }
    this.tribe.GenerateAdminToken().subscribe((response: any) => {
      if (response.status_code == 200) {
        this.adminToken = 'Bearer ' + response.data.access_token;
        localStorage.setItem("adminToken", 'Bearer ' + response.data.access_token);
        this.TribeUser();
      }
    }, error => {
      console.log("Error:", error);
    })
  }

  ngOnInit(): void {
    this.signupForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: this.MustMatch('password', 'confirmPassword')
    });

    this.region_with_timezone = moment.tz.guess();
    this.current_Date = moment().format("MMM Do YYYY");
    let email = this.route.snapshot.paramMap.get('email')
    this.signupForm.patchValue({ email: email })
  }

  get f() { return this.signupForm.controls; }

  onSubmit() { this.submitted = true; if (this.signupForm.invalid) { return; } this.SignUp(this.signupForm); }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  async SignUp(signupData) {
    let exist = await this.Checkemailexists(signupData.value.email);
    if (exist) {
      this.errorEmail = "The email address is already in use by another account.";
      return false;
    }
    let tribeexists = this.usersList.filter(x => x.email === signupData.value.email);
    if (tribeexists.length > 0) {
      this.errorEmail = "The email address is already in use by another account.";
      return false;
    }
    this.afAuth.createUserWithEmailAndPassword(signupData.value.email, signupData.value.password)
      .then((result) => {
        result.user['firstname'] = signupData.value.firstname;
        result.user['lastname'] = signupData.value.lastname;
        // this.authService.SendVerificationMail(result.user.email);
        this.authService.SendCustomVerificationMail(result.user.email,signupData.value.firstname + ' ' + signupData.value.lastname)
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${result.user.uid}`);
        const userData: User = {
          uid: result.user.uid,
          firstname: signupData.value.firstname,
          lastname: signupData.value.lastname,
          email: result.user.email,
          displayName: signupData.value.firstname + ' ' + signupData.value.lastname,
          photoURL: "../../../assets/avtar.png",
          emailVerified: result.user.emailVerified,
          creationDate: this.current_Date,
          regionwithtimezone: this.region_with_timezone,
          region: '',
          timezone: ''
        }
        userRef.set(userData, {
          merge: true
        })
        let tribeuserdata = {
          username: signupData.value.firstname + "_" + Math.floor(100000000 + Math.random() * 900000000),
          name: signupData.value.firstname + '' + signupData.value.lastname,
          email: signupData.value.email,
          password: signupData.value.password,
          externalId: result.user.uid,
          authorization: this.adminToken,
          role: "member"
        }
        this.createTribeUser(tribeuserdata);
      }).catch((error) => {
        this.errorEmail = error.message;
      })
    this.closeForgotModel(null);
  }

  closeForgotModel(param) { if (param == null) { let obj = this; setTimeout(() => { obj.errorEmail = ''; }, 5000); } else { this.errorEmail = ''; } }

  passwordValid(event) { this.passwordIsValid = event; }

  createTribeUser(userdata) { this.tribe.CreateUser(userdata).subscribe(() => { }, error => { console.error("Error:", error) }) }

  Checkemailexists(email) { return new Promise((resolve, reject) => { let exist = false; this.afs.collection('users', ref => ref.where('email', '==', email)).get().subscribe((querySnapshot) => { if (querySnapshot.size > 0) { exist = true; } else { exist = false; } resolve(exist); }) }); }

  TribeUser() { this.tribe.GetTribeUsersList().subscribe((response: any) => { if (response != undefined && response.successData.length > 0) { this.usersList = response.successData; } }); }

}
