<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet"
    integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">
<!-- Font Awesome CDN -->
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
    integrity="sha512-iBBXm8fW90+nuLcSKlbmrPcLa0OT92xO1BIsZ+ywDWZCvqsWgccV3gFoRBv0z+8dLJgyAHIhR35VZc2oM/gI1w=="
    crossorigin="anonymous" referrerpolicy="no-referrer" />

<!-- ===== Header ===== -->
<header>
    <div class="container-fluid py-4 home-header">
        <div class="content d-flex flex-wrap justify-content-between align-items-center">
            <div class="img">
                <img src="../../../assets/template/img/Peak-Focus-Logo.png" class="img-fluid logo" alt="">
            </div>
            <div class="button">
                <a routerLink="/login" style="color:#ffffff">Login</a>
                <!-- <button class="custom-button">TRY 2 MONTHS FREE</button> -->
            </div>
        </div>
    </div>
</header>
<!-- x===== Header =====x -->

<div class="home-wrapper-container ">


    <!-- ===== Hero ===== -->
    <div class="hero-wrapper py-4 ">
        <!-- <div class="container">
            <h4> PRIVACY POLICY</h4>

            <P>
                This online privacy policy has been put together to better those who find themselves worried about how
                their Personally Identifiable Information (PII) has been used online. As defined in US privacy
                regulation and information security, PII is information that can be used alone or with other information
                to recognize, contact, or find a single person, or even to identify a person in the framework. Please
                read our online privacy policy carefully to obtain an explicit knowledge of how we accumulate, use,
                protect or elsewhere take care of your Personally Identifiable Information relative to our website.
            </P>
            <ul>
                <li>
                    <p>
                        Your privacy is essential to us, and we are firmly dedicated to doing our works concerning your
                        information increasingly straightforward and more attractive. It would be ideal if you read this
                        Privacy Policy cautiously and make sure that you completely comprehend and consent to it.
                    </p>
                </li>
                <li>
                    <p>
                        You are not legally obligated to provide us with any Personal Data (as described below), and you
                        can
                        do so (or refrain from doing so) at your discretion.
                    </p>
                </li>
                <li>
                    <p>
                        If you do not want to assist us with such Personal Data or have it compiled by us or any of our
                        Service Providers (described below), simply do not access our Sites or use our Services.
                    </p>
                </li>
                <li>
                    <p>
                        You may likewise decide not to give us "discretionary" Personal Data; however please remember
                        that
                        without it, we will most likely be unable to provide you with the full scope of our
                        administrations
                        or with the best client experience when utilizing our Services.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Activated Self LLC</b> provides an online platform <b>Peak Focus</b> to virtually join
                        coworker’s
                        club to improve productivity and progress.
                    </p>
                </li>
            </ul>
            <h4>What private data do we collect from the people who visit our website?</h4>
            <p>When enlisting on our site or buying membership, as suitable, you could be approached to type in your
                name, email, postage information, payment information, or different subtleties to assist you with your
                experience.</p>
            <p>
                When you create an account and use the Services, including through a third-party platform, we collect
                any data you provide directly, including:
            </p>
            <ul>
                <li>
                    <p>
                        <b>Account Data:</b> To use certain features (like Paid or unpaid Services), you need to create
                        a
                        user account. When you create or update your account, we collect and store the data you provide,
                        like your email address, password, gender, and date of birth, and assign you a unique
                        identifying
                        number ("Account Data").
                    </p>
                </li>
                <li>
                    <p>
                        <b>Personal Data: </b> Personal Data is information that can be used to identify you
                        specifically, including your name, zip code, time zone, email address, telephone number, or
                        demographic information like your age, gender, or hometown. You consent to give us this
                        information by providing it to us voluntarily on our website or any mobile application. You
                        provide some of this information when you register with or make purchases from our website. You
                        may also provide this information by participating in various activities associated with our
                        site, including responding to blogs, contacting us with questions, or participating in group
                        training. Your decision to disclose this data is entirely voluntary. You are under no obligation
                        to provide this information, but your refusal may prevent you from accessing certain benefits
                        from our website or from making purchases.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Profile Data: </b>You can also choose to provide profile information like a photo, headline,
                        website link, social media profiles, or other data. Your Profile Data will be publicly viewable
                        by others.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Financial Data: </b> Financial data is related to your payment methods, such as credit card
                        or bank transfer details. We collect financial data to allow you to purchase, order, return or
                        exchange services from our website and any related mobile apps. We store limited financial data.
                        Most financial data is transferred to our payment processor, Stripe, and you should review these
                        processors' Privacy Policy to determine how they use, disclose and protect your financial data.
                    </p>
                </li>
                <li>
                    <p> Stripe Privacy Policy can be found here https://stripe.com/gb/privacy</p>
                </li>
            </ul>
            <h4>Data About Your Accounts on Other Services: </h4>
            <ul>
                <li>
                    <p>
                        We may obtain certain information through your social media or other online accounts if they are
                        connected to your <b>Peak Focus</b> account. If you login to via Facebook or another third-party
                        platform or
                        service, we ask for your permission to access certain information about that other account. For
                        example, depending on the platform or service, we may collect your name, profile picture,
                        account ID
                        number, login email address, location, the physical location of your access devices, gender,
                        birthday, and list of friends or contacts.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Social Networking Data:</b> We may access personal information from social networking sites,
                        and apps, including Facebook, GitHub, LinkedIn, or other social networking sites or apps not
                        explicitly named here, which may include your name, your social network username, location,
                        email address, age, gender, profile picture, and any other public information. If you do not
                        want us to access this information, please go to the specific social networking site and change
                        your privacy settings.
                    </p>
                </li>
                <li>
                    <p>
                        <b> Mobile Device Data:</b> If you use our website via a mobile device or app, we may collect
                        information about your mobile device, including device ID, model and manufacturer, and location
                        information.
                    </p>
                </li>
                <li>
                    <p>
                        Those platforms and services make information available to us through their APIs. The
                        information we receive depends on what information you (via your privacy settings) or the
                        platform or service decide to give us.
                    </p>
                </li>
                <li>
                    <p>
                        If you access or use our Services or to buy membership through a third-party platform or
                        service, or click on any third-party links, the collection, use, and sharing of your data will
                        also be subject to the privacy policies and other agreements of that third party.
                    </p>
                </li>
            </ul>



            <P>
                How we collect and store information depends on the page you are visiting, the activities in which you
                elect to participate and the services provided. For example, you may be asked to provide information
                when you register for access to certain portions of our site or request certain features, such as
                newsletters or when you make a purchase. You may provide information when you participate in surveys,
                sweepstakes and contests, message boards and chat rooms, and other interactive areas of our site. When
                you provide us with your email address, we will send you periodic emails or advertising. We may add your
                email address to other electronic platforms we use to target our audience and enhance your user
                experience with our website, products and services. Generally, We will ask you to indicate whether or
                not you want to receive certain types of communication from us, and we will adhere to your preferences
                unless we consider a particular communication to be absolutely necessary. Emails and advertising will
                contain a link at the bottom to be removed from our list. Like most Web sites, ClubDone also collects
                information automatically and through the use of electronic tools that may be transparent to our
                visitors. For example, we may log your Internet Protocol address (“IP address”), the name of your
                Internet Service Provider or use cookie technology, such as conversion and tracking pixels, to recognize
                you and hold information from your visit or from your visit to a third party site. Among other things,
                the cookie may store your user name and password, sparing you from having to re-enter that information
                each time you visit, or may control the number of times you encounter a particular advertisement while
                visiting our site. As we adopt additional technology, we may also gather information through other
                means. In certain cases, you can choose not to provide us with information, for example by setting your
                browser to refuse to accept cookies, but if you do you may be unable to access certain portions of the
                site or may be asked to re-enter your user name and password, and we may not be able to customize the
                site’s features according to your preferences.
            </P>
            <h4>How We Protect Your Information</h4>
            <P>
                We implement a variety of security measures to maintain the safety of your personal information when you
                place an order or enter, submit, or access your personal information. We offer the use of a secure
                server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL)
                technology and then encrypted into our Payment gateway providers database only to be accessible by those
                authorized with special access rights to such systems, and are required to keep the information
                confidential.
            </P>
            <h4> How To Delete Your Account and Data</h4>
            <P>
                In compliance with GDPR regulations, you can permanently delete your account and have your data erased.
                To do so, log in to your ClubDOne account, visit your Profile page and select “Delete account”.
            </P>
            <h4>Do We Use Cookies?</h4>
            <P>
                Yes. (Cookies are small files that a site or its service provider transfers to your computers hard drive
                through your Web browser (if you allow) that enables the sites or service providers systems to recognize
                your browser and capture and remember certain information.) We use cookies to help us remember and
                process the items in your shopping cart, understand and save your preferences for future visits and
                compile aggregate data about site traffic and site interaction so that we can offer better site
                experiences and tools in the future. We may contract with third-party service providers to assist us in
                better understanding our site visitors. These service providers are not permitted to use the information
                collected on our behalf except to help us conduct and improve our business.
            </P>
            <h4>What We Do With The Information We Collect?</h4>
            <P>
                Like other Web publishers, we collect information to enhance your visit and deliver more individualized
                content and advertising. We do not sell, trade, or otherwise transfer to outside parties your personally
                identifiable information. This does not include trusted third parties who assist us in operating our
                website, conducting our business, or servicing you, so long as those parties agree to keep this
                information confidential. We may also release your information when we required comply by law, enforce
                our site policies, or protect ours or others rights, property, or safety. However, non-personally
                identifiable visitor information may be provided to other parties for marketing, advertising, or other
                uses. Aggregated Information (information that does not personally identify you) may be used in many
                ways. For example, we may combine information about your usage patterns with similar information
                obtained from other users to help enhance our site and services (e.g., to learn which pages are visited
                most or what features are most attractive). Aggregated Information may occasionally be shared with our
                advertisers and business partners. Again, this information does not include any Personally Identifiable
                Information about you or allow anyone to identify you individually. We may use Personally Identifiable
                Information collected on ClubDOne to communicate with you about your registration and customization
                preferences; our Terms of Service and privacy policy; services and products offered by ClubDone and
                other topics we think you might find of interest. Personally Identifiable Information collected by
                ClubDone may also be used for other purposes, including but not limited to site administration,
                troubleshooting, processing of e-commerce transactions, administration of sweepstakes and contests, and
                other communications with you. Certain third parties who provide technical support for the operation of
                our site (our Web hosting service for example) may access such information. We will use your information
                only as permitted by law. In addition, from time to time as we continue to develop our business, we may
                sell, buy, merge or partner with other companies or businesses. In such transactions, user information
                may be among the transferred assets. We may also disclose your information in response to a court order,
                at other times when we believe we are reasonably required to do so by law, in connection with the
                collection of amounts you may owe to us, and/or to law enforcement authorities whenever we deem it
                appropriate or necessary. Please note we may not provide you with notice prior to disclosure in such
                cases.
            </P>
            <h4> How We Use Google Analytics</h4>
            <P>
                We use Google Analytics to identify you as the same user when logging in through different devices. This
                allows us to analyze and understand user behavior, in aggregate, without transmitting or revealing and
                personally identifying information.

                We may also use Google Analytics to connect your individual usage behavior data to your account
                information, in order to provide more relevant support and communications to you, and enhance your
                experience.

                We do not upload any data that allows Google to personally identify an individual (such as certain
                names, social security numbers, email addresses, or any similar data), or data that permanently
                identifies a particular device (such as a mobile phone’s unique device identifier if such an identifier
                cannot be reset).
            </P>
            <h4> Affiliated Sites, Linked Sites and Advertisements</h4>
            <P>
                ClubDone expects its partners, advertisers and affiliates to respect the privacy of our users. Be aware,
                however, that third parties, including our partners, advertisers, affiliates and other content providers
                accessible through our site, may have their own privacy and data collection policies and practices. For
                example, during your visit to our site you may link to, or view as part of a frame on ClubDone pages,
                certain content that is actually created or hosted by a third party. Also, through ClubDone you may be
                introduced to, or be able to access, information, surveys, Web sites, features, contests or sweepstakes
                offered by other parties. ClubDone is not responsible for the actions or policies of such third parties.
                You should check the applicable privacy policies of those third parties when providing information on a
                feature or page operated by a third party. While on our site, our advertisers, promotional partners or
                other third parties may use cookies or other technology to attempt to identify some of your preferences
                or retrieve information about you. For example, some of our advertising is served by third parties and
                may include cookies that enable the advertiser to determine whether you have seen a particular
                advertisement before. Other features available on our site may offer services operated by third parties
                and may use cookies or other technology to gather information. ClubDone does not control the use of this
                technology by third parties or the resulting information, and are not responsible for any actions or
                policies of such third parties. You should also be aware that if you voluntarily disclose Personally
                Identifiable Information on message boards or in chat areas, that information can be viewed publicly and
                can be collected and used by third parties without our knowledge and may result in unsolicited messages
                from other individuals or third parties. Such activities are beyond the control of ClubDone and this
                policy.
            </P>
            <h4> Children’s Privacy Statement</h4>
            <P>
                This children’s privacy statement explains our practices with respect to the online collection and use
                of personal information from children under the age of thirteen, and provides important information
                regarding their rights under federal law with respect to such information. This Site is not directed to
                children under the age of thirteen and we do NOT knowingly collect personally identifiable information
                from children under the age of thirteen as part of the Site. We screen users who wish to provide
                personal information in order to prevent users under the age of thirteen from providing such
                information. If we become aware that we have inadvertently received personally identifiable information
                from a user under the age of thirteen as part of the Site, we will delete such information from our
                records. If we change our practices in the future, we will obtain prior, verifiable parental consent
                before collecting any personally identifiable information from children under the age of thirteen as
                part of the Site. Because we do not collect any personally identifiable information from children under
                the age of thirteen as part of the Site, we also do NOT knowingly distribute such information to third
                parties. We do NOT knowingly allow children under the age of thirteen to publicly post or otherwise
                distribute personally identifiable contact information through the Site. Because we do not collect any
                personally identifiable information from children under the age of thirteen as part of the Site, we do
                NOT condition the participation of a child under thirteen in the Site’s online activities on providing
                personally identifiable information. We can be reached by contacting: Hello (at) Clubdone (Dot) com
            </P>
            <h4> Changes to this Policy</h4>
            <P>
                ClubDone reserves the right to change this policy at any time. Please check this page periodically for
                changes. Your continued use of our site following the posting of changes to these terms will mean you
                accept those changes. Information collected prior to the time any change is posted will be used
                according to the rules and laws that applied at the time the information was collected.
            </P>
            <h4> Governing Law</h4>
            <P>
                This policy and the use of these Sites are governed by Delaware law. If a dispute arises under this
                Policy we agree to first try to resolve it with the help of a mutually agreed-upon mediator in the
                following location: Delaware. Any costs and fees other than attorney fees associated with the mediation
                will be shared equally by each of us. If it proves impossible to arrive at a mutually satisfactory
                solution through mediation, we agree to submit the dispute to binding arbitration at the following
                location: Delaware, under the rules of the American Arbitration Association. Judgment upon the award
                rendered by the arbitration may be entered in any court with jurisdiction to do so. This statement and
                the policies outlined herein are not intended to and do not create any contractual or other legal rights
                in or on behalf of any party.


            </P>
        </div> -->
        <div class="container">
            <div class=WordSection1>

                <h4>Privacy Policy </h4>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>This online privacy policy has been put together to better those
                        who find themselves worried about how their Personally Identifiable Information
                        (PII) has been used online. As defined in US privacy regulation and information
                        security, PII is information that can be used alone or with other information
                        to recognize, contact, or find a single person, or even to identify a person in
                        the framework. Please read our online privacy policy carefully to obtain an
                        explicit knowledge of how we accumulate, use, protect or elsewhere take care of
                        your Personally Identifiable Information relative to our website.</span></p>

                <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Your
                        privacy is essential to us, and we are firmly dedicated to doing our works
                        concerning your information increasingly straightforward and more attractive.
                        It would be ideal if you read this Privacy Policy cautiously and make sure that
                        you completely comprehend and consent to it. </span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>You
                        are not legally obligated to provide us with any Personal Data (as described
                        below), and you can do so (or refrain from doing so) at your discretion.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>If
                        you do not want to assist us with such Personal Data or have it compiled by us
                        or any of our Service Providers (described below), simply do not access our
                        Sites or use our Services.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>You
                        may likewise decide not to give us &quot;discretionary&quot; Personal Data;
                        however please remember that without it, we will most likely be unable to
                        provide you with the full scope of our administrations or with the best client
                        experience when utilizing our Services.</span></p>

                <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>Activated Self LLC</span></b><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'> provides an online platform “</span><a href="https://peakfocus.co/home"><b><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>Peak Focus</span></b></a><b><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>”</span></b><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'> to virtually join coworker’s club to improve productivity and
                        progress.</span></p>

                <h2 style='text-align:justify'><a name="_What_private_data"></a><b><i><span
                                style='font-size:11.0pt;line-height:107%;color:#667783'>What private data do we
                                collect from the people who visit our website?</span></i></b></h2>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>When enlisting on our site or buying membership, as suitable, you
                        could be approached to type in your name, email, postage information, payment
                        information, or different subtleties to assist you with your experience.</span></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>When you create an account and use the Services, including through
                        a third-party platform, we collect any data you provide directly, including:</span></p>

                <ul style='margin-top:0in' type=disc>
                    <li class=MsoNormal style='color:#667783;text-align:justify'><span class=Heading3Char><b><span
                                    style='line-height:107%;color:#667783'>Account
                                    Data</span></b></span><span class=Heading3Char><span style='line-height:
                     107%;color:#667783'>:</span></span><span style='font-family:"Calibri Light",sans-serif'>
                            To use certain features (like Paid or unpaid Services), you need to create
                            a user account. When you create or update your account, we collect and
                            store the data you provide, like your email address, password, gender, and
                            date of birth, and assign you a unique identifying number (&quot;Account
                            Data&quot;).</span></li>
                    <li class=MsoNormal style='color:#667783;text-align:justify'><span class=Heading3Char><b><span
                                    style='line-height:107%;color:#667783'>Personal
                                    Data</span></b></span><span style='font-family:"Calibri Light",sans-serif'>:
                            Personal Data is information that can be used to identify you
                            specifically, including your name, zip code, time zone, email address,
                            telephone number, or demographic information like your age, gender, or
                            hometown. You consent to give us this information by providing it to us
                            voluntarily on our website or any mobile application. You provide some of
                            this information when you register with or make purchases from our
                            website. You may also provide this information by participating in various
                            activities associated with our site, including responding to blogs,
                            contacting us with questions, or participating in group training. Your
                            decision to disclose this data is entirely voluntary. You are under no
                            obligation to provide this information, but your refusal may prevent you
                            from accessing certain benefits from our website or from making purchases.</span></li>
                    <li class=MsoNormal style='color:#667783;text-align:justify'><span class=Heading3Char><b><span
                                    style='line-height:107%;color:#667783'>Profile
                                    Data:</span></b></span><span style='font-family:"Calibri Light",sans-serif'>
                            You can also choose to provide profile information like a photo, headline,
                            website link, social media profiles, or other data. Your Profile Data will
                            be publicly viewable by others.</span></li>
                </ul>

                <p class=MsoListParagraph style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span class=Heading3Char><b><span style='line-height:107%;
                color:#667783'>Financial Data:</span></b></span><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'> Financial data is related to your payment methods, such as credit
                        card or bank transfer details. We collect financial data to allow you to
                        purchase, order, return or exchange  services from our website and any related
                        mobile apps. We store limited financial data. Most financial data is
                        transferred to our payment processor, <b>Stripe</b>, and you should review
                        these processors' Privacy Policy to determine how they use, disclose and
                        protect your financial data. </span></p>

                <p class=MsoNoSpacing style='margin-left:1.0in;text-indent:-.25in'><span
                        style='font-family:"Courier New";color:#667783'>o<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Stripe
                        Privacy Policy can be </span><a href="https://stripe.com/gb/privacy"><span
                            style='font-family:"Calibri Light",sans-serif;color:#667783'>found here</span></a><span
                        style='font-family:"Calibri Light",sans-serif;color:#667783'>
                        https://stripe.com/gb/privacy</span></p>

                <h3 style='margin-left:.25in;text-align:justify'><b><span style='font-size:
                11.0pt;line-height:107%;color:#667783'>Data About Your Accounts on Other
                            Services: </span></b></h3>

                <p class=MsoNoSpacing style='margin-left:.5in;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>We
                        may obtain certain information through your social media or other online
                        accounts if they are connected to your </span><a href="https://peakfocus.co/home"><b><span
                                style='font-family:"Calibri Light",sans-serif;
                color:#667783'>Peak Focus</span></b></a><b><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'> </span></b><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'> account. If you login to via Facebook or another third-party
                        platform or service, we ask for your permission to access certain information
                        about that other account. For example, depending on the platform or service, we
                        may collect your name, profile picture, account ID number, login email address,
                        location, the physical location of your access devices, gender, birthday, and
                        list of friends or contacts.</span></p>

                <ul style='margin-top:0in' type=disc>
                    <li class=MsoNormal style='color:#667783;text-align:justify'><span class=Heading3Char><b><span
                                    style='line-height:107%;color:#667783'>Social
                                    Networking Data:</span></b></span><span
                            style='font-family:"Calibri Light",sans-serif'>
                            We may access personal information from social networking sites, and apps,
                            including <b>Facebook</b>, GitHub, LinkedIn,  or other social networking
                            sites or apps not explicitly named here, which may include your name, your
                            social network username, location, email address, age, gender, profile
                            picture, and any other public information. If you do not want us to access
                            this information, please go to the specific social networking site and
                            change your privacy settings.</span></li>
                    <li class=MsoNormal style='color:#667783;text-align:justify'><span class=Heading3Char><b><span
                                    style='line-height:107%;color:#667783'>Mobile
                                    Device Data:</span></b></span><span style='font-family:"Calibri Light",sans-serif'>
                            If you use our website via a mobile device or app, we may collect
                            information about your mobile device, including device ID, model and
                            manufacturer, and location information.</span></li>
                    <li class=MsoNormal style='color:#667783;text-align:justify'><span
                            style='font-family:"Calibri Light",sans-serif'>Those platforms and
                            services make information available to us through their APIs. The
                            information we receive depends on what information you (via your privacy
                            settings) or the platform or service decide to give us.</span></li>
                    <li class=MsoNormal style='color:#667783;text-align:justify'><span
                            style='font-family:"Calibri Light",sans-serif'>If you access or use our
                            Services or to buy membership through a third-party platform or service,
                            or click on any third-party links, the collection, use, and sharing of
                            your data will also be subject to the privacy policies and other
                            agreements of that third party.</span></li>
                </ul>

                <h2 style='text-align:justify'><b><i><span style='font-size:11.0pt;line-height:
                107%;color:#667783'>When do we acquire information?</span></i></b></h2>

                <p class=MsoBodyText2>We get data from you when you get enlisted on our site,
                    respond to an audit, give us reactions on our items or enter information on our
                    site. Below are the examples:</p>

                <p class=MsoListParagraphCxSpFirst style='margin-left:1.0in;text-align:justify;
                text-indent:-.5in'><span style='color:#667783'>•<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        register on our website to get updated about services or to buy memebrship.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-.5in'><span
                        style='color:#667783'>•<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        create your account on the website (e.g., your name and email address)</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-.5in'><span
                        style='color:#667783'>•<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        process your orders via our online services (e.g., your name, address, date of
                        birth, and payment details) </span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-align:justify;
                text-indent:-.5in'><span style='color:#667783'>•<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        deal with our relationship with you, including notifying you about changes to
                        our terms or security arrangement. Requesting that you leave an auditor to take
                        an overview. </span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-align:justify;
                text-indent:-.5in'><span style='color:#667783'>•<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        manage and ensure our business and this site (counting investigating,
                        information examination, testing, framework upkeep, backing, announcing, and
                        facilitating information).</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-align:justify;
                text-indent:-.5in'><span style='color:#667783'>•<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        send you our email pamphlet and other robotized email correspondences. </span></p>

                <p class=MsoListParagraphCxSpLast style='margin-left:1.0in;text-align:justify;
                text-indent:-.5in'><span style='color:#667783'>•<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        make proposals and suggestions to you about merchandise or administrations that
                        might hold any importance with you.</span></p>

                <h3><b><span style='font-size:11.0pt;line-height:107%;color:#667783'>Automatically
                            collected information about your use of our Services or tools,</span></b></h3>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>This information is registered automatically with the visit by own
                        configuration or manual of each tool on the website</span></p>

                <p class=MsoNoSpacing style='margin-left:.5in;text-align:justify;text-indent:
                -.25in'><span style='color:#667783'>•<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>When
                        you visit, connect with, or utilize our service, we may gather, record or
                        create specific specialized data about you. We do so either autonomously or
                        with the assistance of third gathering Service Providers, including using
                        &quot;<b>cookies</b>&quot; and other following innovations. </span></p>

                <p class=MsoNoSpacing style='margin-left:.5in;text-align:justify'><span
                        style='font-family:"Calibri Light",sans-serif;color:#667783'>&nbsp;</span></p>

                <p class=MsoNoSpacing style='margin-left:.5in;text-align:justify;text-indent:
                -.25in'><span style='color:#667783'>•<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Such
                        data comprises of availability, specialized and collected utilization data, for
                        example, <b>IP locations</b> and <b>general areas</b>, <b>gadget data</b> (like
                        sort, working framework, cell phone id, program form, region and language
                        settings, Browser Information, Geographic location, Time of Visit, Referring
                        site, applications or service, search engine utilized), date and time stamps of
                        use, the and pixels introduced or used on such gadget and the recorded movement
                        (meetings, clicks and further cooperation's) of Visitors and Users regarding
                        our Service. for purposes including examination, service-, tasks, and business
                        quality control and enhancements, and record-keeping purposes.</span></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>This is to improve the website, services, and security, among
                        which we include security inspection by the administration of the website and
                        third parties.</span></p>

                <h2 style='text-align:justify'><a name="_How_do_we"></a><b><i><span
                                style='font-size:11.0pt;line-height:107%;color:#667783'>How do we handle social
                                signals? </span></i></b></h2>

                <p class=MsoNormal style='text-align:justify'><b><i><span style='font-family:
                "Calibri Light",sans-serif;color:#667783'>In Short</span></i></b><span
                        style='font-family:"Calibri Light",sans-serif;color:#667783'>: Yes, if you choose
                        to register or log in to our website using a social media account, we may
                        access certain information about you. </span></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>Our Sites offers you the ability to register and log in using your
                        third-party social media account details (like your <b>Github</b> or <b>LinkedIn</b>
                        logins). Where you choose to do this, we will receive certain profile
                        information about you from your social media provider. The profile Information
                        we receive may vary depending on the social media provider concerned, but will
                        often include your name, email address, friends list, profile picture, as well
                        as other information you choose to make public. </span></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>We will use the information we receive only for the purposes
                        described in this privacy policy or that are otherwise made clear to you on the
                        Sites. Please note that we do not control, and are not responsible for, other
                        uses of your personal information by your third-party social media provider. We
                        recommend that you review their privacy policy to understand how they collect,
                        use and share your personal information and how you can set your privacy
                        preferences on their sites and apps.</span></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>The APIs that we use to store and access cookies and other
                        information on your devices. If you are a user currently in the European Union,
                        please look at our </span><a
                        href="https://www.google.com/about/company/user-consent-policy/"><b><span
                                style='font-family:"Calibri Light",sans-serif;color:#667783'>EU User Consent
                                Policy.</span></b></a></p>

                <h2 style='text-align:justify'><a name="_What’s_and_Affiliate"></a><a
                        name="_How_do_we_2"></a><b><i><span style='font-size:11.0pt;line-height:107%;
                color:#667783'>How do we use your details?</span></i></b></h2>

                <p class=MsoBodyText2>We may utilize the data we procure from you when you
                    enlist, make a buy, join our bulletin, respond to an examination or promoting
                    correspondence, peruse the site, or utilize specific other site includes in the
                    following ways:</p>

                <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Create
                        your account; and</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Deliver
                        any services purchased by you to you; and</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Correspond
                        with you; and</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Compile
                        anonymous statistical data for our own use or for a third party's use; and</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Assist
                        law enforcement as necessary; and</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Prevent
                        fraudulent activity on our website or mobile app; and</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Analyze
                        trends to improve our website and offerings.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        fulfill or meet the reason you provided the information (e.g., to help provide
                        our Site services to you).</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        personalize and develop our site and the services we provide you and improve
                        our offerings.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        provide certain features or functionality of the services on the site.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>For
                        marketing and promotions.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        create, maintain, customize, and secure your account with us.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        provide you with support, to communicate with you and respond to your
                        inquiries, including to investigate and address your concerns and monitor and
                        improve our responses.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        personalize your experience and to deliver content and services relevant to
                        your interests.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        help maintain the safety, security, and integrity of our site, services,
                        databases, and other technology assets and business.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>For
                        testing, research, analysis, and App development, including to develop and
                        improve our site and services.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        respond to law enforcement requests and as required by applicable law, court
                        order, or governmental regulations.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        prevent illegal activity, fraud, and abuse.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>As
                        described to you when collecting your personal information or as otherwise set
                        forth in </span><a href="#_California_online_privacy"><span style='font-family:
                "Calibri Light",sans-serif;color:#667783'>the CCPA</span></a><span
                        style='font-family:"Calibri Light",sans-serif;color:#667783'>.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Where
                        we have to play out the agreement we are going to go into or have gone into
                        with you</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        help our site that will ready to serve you better.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        allow us to brought administration you up in furnishing a response to your
                        client assistance demands.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        procure rankings and audits of items</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        send messages after a certain time routinely, with respect to your
                        administrations or items and different items.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        catch up after correspondence with (live talk, email, or telephone requests)</span></p>

                <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>To
                        furnish you with the data, items, and administrations that you demand from the
                        site.</span></p>

                <h2 style='text-align:justify'><a name="_Enquiries,_issues_and"></a><a
                        name="_Customer_data_processing"></a><a name="_GDPR-EU_data_protection"></a><b><i><span
                                style='font-size:11.0pt;line-height:107%;color:#667783'>How do we protect your
                                details?</span></i></b></h2>

                <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>We
                        do not use vulnerability scanning and/or scanning to PCI specifications.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>We
                        only provide articles and information. We never require credit card volumes.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>We
                        use regular Malware Scanning.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Your
                        individual information is comprised behind secured systems and is merely
                        accessible by a restricted number of folks who've special access privileges to
                        such systems and must keep the information confidential carefully. Furthermore,
                        all very sensitive/credit information you resource is encrypted via Secure
                        Socket Layer (SSL) technology.</span></p>

                <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>We
                        implement a number of security measures whenever a user gets into, submits, or
                        accesses their information to keep up the protection of your individual
                        information.</span></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>All deals are processed through the gateway service provider and
                        aren't stored or refined on our machines.</span></p>

                <h2 style='text-align:justify'><b><i><span style='font-size:11.0pt;line-height:
                107%;color:#667783'>Do we use 'cookies?</span></i></b></h2>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>Yes. Cookies are small documents a site or its provider exchanges
                        to your computer's hard drive through your browser (if you allow) that permit
                        the site's or service provider's systems to identify your internet browser and
                        capture please remember certain information. For example, we use cookies to
                        help us keep in mind and process the things in your shopping cart software.
                        Also, they are used to help us understand your requirements based on prior or
                        current site activity, which permits us to offer you improved upon services. We
                        also use cookies to help us put together aggregate data about site traffic and
                        site conversation so that people may offer better site experience and tools in
                        the foreseeable future.</span></p>

                <h3 style='text-align:justify'><b><span style='font-size:11.0pt;line-height:
                107%;color:#667783'>We use cookies to:</span></b></h3>

                <p class=MsoNoSpacing style='margin-left:.5in;text-align:justify;text-indent:
                -.25in'><span style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Understand
                        and save user's tastes for future views or visits of our site.</span></p>

                <p class=MsoNoSpacing style='margin-left:.5in;text-align:justify;text-indent:
                -.25in'><span style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Keep
                        an eye on advertisements.</span></p>

                <p class=MsoNoSpacing style='margin-left:.5in;text-align:justify;text-indent:
                -.25in'><span style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Compile
                        aggregate data about site traffic and site connections in order to provide
                        better site activities and tools in the foreseeable future.</span></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>You are able to choose that your personal computer warns you
                        whenever a cookie has been directed, or you can select to turn off all cookies
                        carefully. You can perform that through your web browser settings. Since the
                        internet browser is just a little different, check out your browser's Help Menu
                        to learn the way in which to change your cookies.</span></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>If you change cookies off, many of the features that produce your
                        site experience better might not exactly function properly. It will not impact
                        the user's experience, which builds your site experience better and might not
                        function properly.</span></p>

                <h4><a name="_Toc72349973"></a><a name="_Toc72350076"></a><a name="_Toc72632436"><b><span
                                style='color:#667783'>General Use</span></b></a><b><span style='color:#667783'>
                        </span></b>
                </h4>

                <p class=MsoNormal style='margin-left:1.0in;text-align:justify;text-indent:
                -.5in'><span style='color:#667783'>•<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Understand
                        client's desires for future perspectives or visits of our site. </span></p>

                <p class=MsoNormal style='margin-left:1.0in;text-align:justify;text-indent:
                -.5in'><span style='color:#667783'>•<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Collect
                        and Compile total data about site traffic their sources e.g. (Gadgets, Country,
                        page sessions) and site associations so as to give better site exercises and
                        apparatuses within a reasonable time-frame. </span></p>

                <p class=MsoNormal style='margin-left:1.0in;text-align:justify;text-indent:
                -.5in'><span style='color:#667783'>•<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>You
                        have the choice to accept or reject cookies for the website and at whatever
                        point a cookie has been coordinated, or you can choose to deliberately kill all
                        cookies. You can play out that through your internet browser settings if you
                        using chrome, you can go the setting of the chrome and select the domain and
                        change the setting of the cookies to your desire. </span></p>

                <p class=MsoNormal style='margin-left:1.0in;text-align:justify;text-indent:
                -.5in'><span style='color:#667783'>•<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>On
                        the off chance that you change cookies off, some of the features that produce
                        your site experience better may not actually work appropriately. It won't
                        affect the client's experience that delivers your site experience better and
                        may not actually work appropriately.</span></p>

                <h2><b><i><span style='font-size:11.0pt;line-height:107%;color:#667783'>Firebase
                                by Google</span></i></b></h2>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>We use the Firebase service from Google LLC. (1600 Amphitheatre
                        Parkway, Mountain View, CA 94043, USA) in order to derive application
                        behavioral analytics. We use that information to see how users interact with
                        our website and app.</span></p>

                <p class=MsoBodyText><span style='color:#667783'>Firebase is part of the Google
                        Cloud Platform and offers numerous services for developers. A list can be found
                        here: </span><a href="https://firebase.google.com/terms/"><b><span
                                style='color:#667783;text-decoration:none'>https://firebase.google.com/terms/</span></b></a><b><span
                            style='color:#667783'>.</span></b><span style='color:#667783'> Some Firebase
                        services process personal data. In most cases, the personal data is limited to
                        so-called “instance IDs”, which are provided with a time stamp. These “Instance
                        IDs” assigned by Firebase are unique and thus allow the linking of different
                        events or processes. This data does not represent personally identifiable
                        information for us, nor do we make any efforts to personalize it subsequently.
                        We process these aggregated data to analyze and optimize usage behavior, for
                        example by evaluating crash reports.</span></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>&nbsp;</span></p>

                <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 style='border-collapse:collapse'>
                    <tr style='height:205.35pt'>
                        <td valign=top style='border:solid #667783 1.0pt;background:white;padding:2.0pt 7.0pt 2.0pt 5.0pt;
                  height:205.35pt'>
                            <h6><span style='color:#667783'>Facebook Pixel</span></h6>
                            <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
                  0in;margin-left:0in;text-align:justify;line-height:normal'><span
                                    style='font-family:"Calibri Light",sans-serif;color:#667783'>_fpb,datr,dpr,fr,wd</span>
                            </p>
                        </td>
                        <td valign=top style='border:solid #667783 1.0pt;border-left:none;background:
                  white;padding:2.0pt 7.0pt 2.0pt 5.0pt;height:205.35pt'>
                            <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
                  12.0pt;margin-left:0in;text-align:justify;line-height:normal'><span
                                    style='font-family:"Calibri Light",sans-serif;color:#667783'>Collects anonymous
                                    statistics regarding usage of the </span><span style='color:#667783'><a
                                        href="https://peakfocus.co/home"><span style='font-family:"Calibri Light",sans-serif;
                  color:#667783'>Peak Focus</span></a></span><span style='font-family:"Calibri Light",sans-serif;
                  color:#667783'> website. These are third-party cookies. While </span><span style='color:#667783'><a
                                        href="https://peakfocus.co/home"><span
                                            style='font-family:"Calibri Light",sans-serif;color:#667783'>Peak
                                            Focus</span></a></span><span
                                    style='font-family:"Calibri Light",sans-serif;color:#667783'>'s use of Facebook
                                    causes these cookies to be used, </span><span style='color:#667783'><a
                                        href="https://peakfocus.co/home"><span style='font-family:"Calibri Light",sans-serif;
                  color:#667783'>Peak Focus</span></a></span><span style='font-family:"Calibri Light",sans-serif;
                  color:#667783'> itself does not control the data within the cookies themselves.
                                    The names of the cookies listed are provided as examples. </span><span
                                    style='color:#667783'><a href="https://peakfocus.co/home"><span
                                            style='font-family:"Calibri Light",sans-serif;color:#667783'>Peak
                                            Focus </span></a></span><span
                                    style='font-family:"Calibri Light",sans-serif;color:#667783'>does not directly
                                    control the names of the cookies involved, and the actual names may differ.</span>
                            </p>
                            <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
                  12.0pt;margin-left:0in;text-align:justify;line-height:normal'><span
                                    style='font-family:"Calibri Light",sans-serif;color:#667783'>These cookies
                                    enable us to:</span></p>
                            <ol style='margin-top:0in' start=1 type=1>
                                <li class=MsoNormal style='color:#667783;margin-top:12.0pt;margin-bottom:0in;
                       text-align:justify;line-height:normal;vertical-align:baseline'><span
                                        style='font-family:"Calibri Light",sans-serif'>Determine the
                                        effectiveness of certain marketing campaigns</span></li>
                                <li class=MsoNormal style='color:#667783;margin-bottom:12.0pt;text-align:justify;
                       line-height:normal;vertical-align:baseline'><span style='font-family:
                       "Calibri Light",sans-serif'>Collect additional anonymous statistics (see
                                        below)</span></li>
                            </ol>
                            <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
                  12.0pt;margin-left:0in;text-align:justify;line-height:normal'><span
                                    style='font-family:"Calibri Light",sans-serif;color:#667783'>You can learn more
                                    about Facebook's Tracking Pixel </span><span style='color:#667783'><a
                                        href="https://developers.facebook.com/docs/facebook-pixel"><b><span style='font-family:"Calibri Light",sans-serif;color:#667783;text-decoration:
                  none'>here</span></b></a></span></p>
                        </td>
                    </tr>
                </table>

                <h2 style='text-align:justify'><b><i><span style='font-size:11.0pt;line-height:
                107%;color:#667783'>GDPR-Customer data processing appendix:</span></i></b></h2>

                <p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>Customer Data</span></b><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>&quot; means any personal data that processes on behalf of
                        Customer via the service, as more particularly described in this DPA.</span></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>&quot;<b>Data Protection Laws</b>&quot; signifies all information
                        protection laws and guidelines appropriate to a gathering's handling of
                        Customer Data under the agreement, including, where pertinent, EU Data
                        Protection Law and Non-EU Data Protection Laws.</span></p>

                <h2 style='text-align:justify'><a name="_California_online_privacy"></a><b><i><span
                                style='font-size:11.0pt;line-height:107%;color:#667783'>GDPR-EU data protection
                                law  </span></i></b></h2>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>&quot;<b>EU Data Protection Law</b>&quot; signifies all data
                        protection laws and guidelines appropriate to Europe, including (I) Regulation
                        2016/679 of the European Parliament and of the Council on the insurance of
                        ordinary people concerning the preparing of individual information and on the
                        free development of such information <b>(General Data Protection Regulation)</b>
                        <b>(&quot;GDPR&quot;);</b> (ii) Directive 2002/58/EC concerning the processing
                        of personal data and the protection of privacy in the electronic communications
                        sector; (iii) applicable national implementations of (i) and (ii); and (iii) in
                        respect of the United Kingdom (&quot;UK&quot;) any applicable national
                        legislation that replaces or converts in domestic law the GDPR or any other law
                        relating to data and privacy as a consequence of the UK leaving the European
                        Union).</span></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>&quot;Europe&quot; signifies, for the motivations behind this DPA,
                        the European Union, the European Economic Area as well as their part states,
                        Switzerland and the United Kingdom. </span></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>&quot;Non-EU Data Protection Laws&quot; means the California
                        Consumer Privacy Act (&quot;CCPA&quot;); the Canadian Personal Information
                        Protection and Electronic Documents Act (&quot;PIPEDA&quot;); and the Brazilian
                        General Data Protection Law (&quot;LGPD&quot;), Federal Law no. 13,709/2018.</span></p>

                <ul style='margin-top:0in' type=disc>
                    <li class=MsoNormal style='color:#667783;text-align:justify'><span class=Heading4Char><b><span
                                    style='color:#667783'>Parties' roles:</span></b></span><b><span
                                style='font-family:"Calibri Light",sans-serif'> </span></b><span
                            style='font-family:"Calibri Light",sans-serif'>If EU Data Protection Law
                            or the LGPD applies to either party's processing of Customer Data, the
                            parties acknowledge and agree that concerning the processing of Customer
                            Data, Customer is the controller and is a processor acting on behalf of
                            Customer, as further described in Annex A (Details of Data Processing) of
                            this DPA.</span></li>
                    <li class=MsoNormal style='color:#667783;text-align:justify'><span class=Heading4Char><b><span
                                    style='color:#667783'>Purpose limitation: </span></b></span><span
                            style='font-family:"Calibri Light",sans-serif'> </span><span style='color:windowtext'><a
                                href="https://peakfocus.co/home"><span
                                    style='font-family:"Calibri Light",sans-serif;color:#667783'>Peak
                                    Focus </span></a></span><span style='font-family:"Calibri Light",sans-serif'>
                        </span><span style='font-family:"Calibri Light",sans-serif'>shall process Customer Data
                            only following Customer's documented lawful instructions as outlined in
                            this DPA, as necessary to comply with applicable law, or as otherwise
                            agreed in writing (&quot;Permitted Purposes&quot;). The parties agree that
                            the agreement sets out the Customer's complete and final instructions to </span><span
                            style='color:windowtext'><a href="https://peakfocus.co/home"><span
                                    style='font-family:"Calibri Light",sans-serif;color:#667783'>Peak
                                    Focus </span></a></span><span style='font-family:"Calibri Light",sans-serif'>
                        </span><span style='font-family:"Calibri Light",sans-serif'>concerning the processing
                            of Customer Data, and processing outside the scope of these instructions
                            (if any) shall require a prior written agreement between the parties.</span></li>
                    <li class=MsoNormal style='color:#667783;text-align:justify'><span class=Heading4Char><b><span
                                    style='color:#667783'>Customer compliance:</span></b></span><span
                            style='font-family:"Calibri Light",sans-serif'> Customer represents and
                            warrants that (i) it has complied, and will continue to comply, with all
                            applicable laws, including Data Protection Laws, in respect of its
                            processing of Customer Data and any processing instructions it issues to </span><span
                            style='color:windowtext'><a href="https://peakfocus.co/home"><span
                                    style='font-family:"Calibri Light",sans-serif;color:#667783'>Peak
                                    Focus </span></a></span><span style='font-family:"Calibri Light",sans-serif'>; and
                            (ii) it has provided,
                            and will continue to provide, all notice and has obtained, and will
                            continue to obtain, all consents and rights necessary under Data
                            Protection Laws for Peak Focus to process Customer Data for the purposes
                            described in the agreement. Customer shall have sole responsibility for
                            the accuracy, quality, and legality of Customer Data and the means by
                            which Customer acquired Customer data. Without prejudice to the generality
                            of the foregoing, Customer agrees that it shall be responsible for
                            complying with all laws (including Data Protection Laws) applicable to any
                            emails or other content created, sent, or managed through the service,
                            including those relating to obtaining consents (where required) to send
                            emails, the content of the emails and its email deployment practices.</span></li>
                    <li class=MsoNormal style='color:#667783;text-align:justify'><span class=Heading4Char><b><span
                                    style='color:#667783'>The lawfulness of
                                    Customer's instructions:</span></b></span><b><span style='font-family:
                     "Calibri Light",sans-serif'> </span></b><span
                            style='font-family:"Calibri Light",sans-serif'>Customer
                            will ensure that United Kingdom processing of the Customer Data by
                            Customer's instructions will not cause </span><span style='color:windowtext'><a
                                href="https://peakfocus.co/home"><span style='font-family:"Calibri Light",sans-serif;
                     color:#667783'>Peak Focus</span></a></span><span style='font-family:"Calibri Light",sans-serif'>
                        </span><span style='font-family:"Calibri Light",sans-serif'> to violate any
                            applicable law, regulation, or rule, including, without limitation, Data
                            Protection Laws. </span><span style='color:windowtext'><a
                                href="https://peakfocus.co/home"><span style='font-family:"Calibri Light",sans-serif;
                     color:#667783'>Peak Focus</span></a></span><span style='font-family:"Calibri Light",sans-serif'>
                        </span><span style='font-family:"Calibri Light",sans-serif'> shall promptly
                            notify Customer in writing unless prohibited from doing so under EU Data
                            Protection Laws if it becomes aware or believes that any data processing
                            instruction from Customer violates the GDPR or any UK implementation of
                            the GDPR.</span></li>
                </ul>

                <h2 style='text-align:justify'><b><i><span style='font-size:11.0pt;line-height:
                107%;color:#667783'>California online privacy protection act</span></i></b></h2>

                <p class=MsoNoSpacing style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>CalOPPA is the first state law in the country to require
                        commercial websites and online services to create an online privacy policy. </span></p>

                <p class=MsoNoSpacing style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>The law's reach extends well beyond California to require anybody
                        or company in America (and possibly the entire world) that functions websites
                        collecting (PII) Personally Identifiable Information from California consumers
                        to create a visible online privacy policy on its website declaring the
                        information being accumulated and the individuals or companies with whom it has
                        been distributed. –See more at </span><a
                        href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/"><span
                            style='font-family:"Calibri Light",sans-serif;color:#667783'>https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/</span></a>
                </p>

                <h2 style='text-align:justify'><b><i><span style='font-size:11.0pt;line-height:
                107%;color:#667783'>According to caloppa, we agree to the following:</span></i></b></h2>

                <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Users
                        can visit our site anonymously.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Once
                        this online privacy policy is established, we will put a link to it on our
                        website, on the first significant web page after getting into our website.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Our
                        ONLINE PRIVACY POLICY link includes the term 'Privacy' and can certainly be on
                        the page given above.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>You'll
                        be notified of any ONLINE PRIVACY POLICY changes:</span></p>

                <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Via
                        Email</span></p>

                <h2 style='text-align:justify'><a name="_Coppa_(children_online"></a><b><i><span
                                style='font-size:11.0pt;line-height:107%;color:#667783'>Coppa (children online
                                privacy protection action)</span></i></b></h2>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>With regards to the assortment of private information from
                        children under age 13 years, the Children's Online Privacy Protection Act
                        (COPPA) puts parents in charge. The Federal Trade Commission, United States'
                        consumer safety firm, enforces the COPPA Guideline, which spells out what
                        providers of websites and online services should do to safeguard children
                        privatizes and security online. Fore more Details </span><a
                        href="https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/childrens-online-privacy-protection-rule"><span
                            style='font-family:"Calibri Light",sans-serif;color:#667783'>Click Here</span></a><span
                        style='font-family:"Calibri Light",sans-serif;color:#667783'> or below link</span></p>

                <p class=MsoNormal style='text-align:justify'><span
                        style='font-family:"Calibri Light",sans-serif;
                color:#667783'>https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/childrens-online-privacy-protection-rule
                    </span></p>

                <h2 style='text-align:justify'><b><i><span style='font-size:11.0pt;line-height:
                107%;color:#667783'>Fair information practices</span></i></b></h2>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>The fair Information Practices Rules form the backbone of level of
                        privacy law in America and the ideas they include have played an important role
                        in the introduction of data protection laws and regulations around the world.
                        Understanding the Good Information Practice Guidelines and how they must be
                        implemented is fundamental to adhere to the various privation laws and
                        regulations that protect private information.</span></p>

                <p class=MsoNormal style='text-align:justify'><b><i><span style='font-family:
                "Calibri Light",sans-serif;color:#667783'>To become consistent with Fair
                                Information Methods we will need the following responsive action, should a data
                                breach happen:</span></i></b></p>

                <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>We
                        will inform you via email</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>within
                        7 business days</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>We
                        will inform the users via in-site notification</span></p>

                <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Within
                        1 working day</span></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>We also agree to the average person Redress Rule which requires
                        that peoples have the right to legally go after enforceable privileges against
                        data collectors and processors who neglect to adhere to the law. This theory
                        requires not just that people have enforceable protection under the law against
                        data users, but also that person have recourse to courts or federal government
                        agencies to research and/or prosecute non-compliance by data processors.</span></p>

                <h2 style='text-align:justify'><a name="_Can-spam_act"></a><b><i><span
                                style='font-size:11.0pt;line-height:107%;color:#667783'>Can-spam act</span></i></b></h2>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>The CAN-SPAM Take action is a regulation that sets the guidelines
                        for commercial email, establishes requirements for commercial announcements,
                        offers recipients to have emails ceased from being delivered to them, and
                        spells out hard fines for violations.</span></p>

                <h3 style='text-align:justify'><span style='font-size:11.0pt;line-height:107%;
                color:#667783'>We accumulate your email to be able to:</span></h3>

                <p class=MsoListParagraph style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Send
                        information, react to questions, and/or other demands or questions</span></p>

                <ul style='margin-top:0in' type=disc>
                    <li class=MsoNormal style='color:#667783;text-align:justify'><span
                            style='font-family:"Calibri Light",sans-serif'>To maintain compliance with
                            CANSPAM, we consent to the next:</span></li>
                    <li class=MsoNormal style='color:#667783;text-align:justify'><span
                            style='font-family:"Calibri Light",sans-serif'>Not use untrue or
                            misleading subject matter or email addresses.</span></li>
                </ul>

                <p class=MsoNormal style='margin-left:.5in;text-align:justify'><span
                        style='font-family:"Calibri Light",sans-serif;color:#667783'>• Identify the
                        concept as an advertisement in some realistic way.</span></p>

                <p class=MsoNormal style='margin-left:.5in;text-align:justify'><span
                        style='font-family:"Calibri Light",sans-serif;color:#667783'>• Include the
                        physical address of our site headquarters or business</span></p>

                <p class=MsoNormal style='margin-left:.5in;text-align:justify'><span
                        style='font-family:"Calibri Light",sans-serif;color:#667783'>• Screen third-party
                        email marketing services for conformity, if one can be used.</span></p>

                <p class=MsoNormal style='margin-left:.5in;text-align:justify'><span
                        style='font-family:"Calibri Light",sans-serif;color:#667783'>• Honor
                        opt-out/unsubscribe demands quickly.</span></p>

                <p class=MsoNormal style='margin-left:.5in;text-align:justify'><span
                        style='font-family:"Calibri Light",sans-serif;color:#667783'>• Allow users to
                        unsubscribe utilizing the link at the bottom of every email.</span></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>If anytime you want to unsubscribe from receiving future email,
                        you can email us at by using contact form at our website Peak Focus and we'll
                        immediately remove you from ALL communication.</span></p>

                <h2><b><span style='font-size:11.0pt;line-height:107%;color:#667783'>Your Legal
                            Rights</span></b></h2>

                <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
                12.0pt;margin-left:0in;text-align:justify;line-height:normal'><span
                        style='font-family:"Calibri Light",sans-serif;color:#667783'>Under certain
                        circumstances, you have rights under data protection laws in relation to your
                        personal data.</span></p>

                <h3><b><i><span style='font-size:11.0pt;line-height:107%;color:#667783'>You may
                                have the following rights: -</span></i></b></h3>

                <p class=MsoListParagraphCxSpFirst style='margin-top:12.0pt;margin-right:0in;
                margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;
                line-height:normal'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>A.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span class=Heading3Char><b><span style='color:#667783'>Request
                                access</span></b></span><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'> to your personal data (commonly known as a &quot;data subject
                        access request&quot;). This enables you to receive a copy of the personal data
                        we hold about you and to check that we are lawfully processing it.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-top:12.0pt;margin-right:0in;
                margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;
                line-height:normal'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>B.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span class=Heading3Char><b><span style='color:#667783'>Request
                                correction</span></b></span><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'> of the personal data that we hold about you. This enables you to
                        have any incomplete or inaccurate data we hold about you corrected, though we
                        may need to verify the accuracy of the new data you provide to us.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-top:12.0pt;margin-right:0in;
                margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;
                line-height:normal'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>C.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span class=Heading3Char><b><span style='color:#667783'>Request
                                erasure</span></b></span><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'> of your personal data. This enables you to ask us to delete or
                        remove personal data where there is no good reason for us continuing to process
                        it. You also have the right to ask us to delete or remove your personal data
                        where you have successfully exercised your right to object to processing (see
                        below), where we may have processed your information unlawfully or where we are
                        required to erase your personal data to comply with local law. Note, however,
                        that we may not always be able to comply with your request of erasure for
                        specific legal reasons which will be notified to you, if applicable, at the
                        time of your request.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-top:12.0pt;margin-right:0in;
                margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;
                line-height:normal'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>D.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span class=Heading3Char><b><span style='color:#667783'>Object to
                                processing</span></b></span><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'> of your personal data where we are relying on a legitimate
                        interest (or those of a third party) and there is something about your
                        particular situation which makes you want to object to processing on this
                        ground as you feel it impacts on your fundamental rights and freedoms. You also
                        have the right to object where we are processing your personal data for direct
                        marketing purposes. In some cases, we may demonstrate that we have compelling
                        legitimate grounds to process your information which override your rights and
                        freedoms.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-top:12.0pt;margin-right:0in;
                margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;
                line-height:normal'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>E.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span class=Heading3Char><b><span style='color:#667783'>Request
                                restriction</span></b></span><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'> of processing of your personal data. This enables you to ask us
                        to suspend the processing of your personal data in the following scenarios:</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-top:12.0pt;margin-right:0in;
                margin-bottom:12.0pt;margin-left:1.0in;text-align:justify;text-indent:-.25in;
                line-height:normal'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>If
                        you want us to establish the data's accuracy.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-top:12.0pt;margin-right:0in;
                margin-bottom:12.0pt;margin-left:1.0in;text-align:justify;text-indent:-.25in;
                line-height:normal'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Where
                        our use of the data is unlawful, but you do not want us to erase it.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-top:12.0pt;margin-right:0in;
                margin-bottom:12.0pt;margin-left:1.0in;text-align:justify;text-indent:-.25in;
                line-height:normal'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Where
                        you need us to hold the data even if we no longer require it as you need it to
                        establish, exercise or defend legal claims.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-top:12.0pt;margin-right:0in;
                margin-bottom:12.0pt;margin-left:1.0in;text-align:justify;text-indent:-.25in;
                line-height:normal'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>d.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>You
                        have objected to our use of your data but we need to verify whether we have
                        overriding legitimate grounds to use it.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-top:12.0pt;margin-right:0in;
                margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;
                line-height:normal'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>F.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span class=Heading3Char><b><span style='color:#667783'>Request the
                                transfer</span></b></span><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'> of your personal data to you or to a third party. We will provide
                        to you, or a third party you have chosen, your personal data in a structured,
                        commonly used, machine-readable format. Note that this right only applies to
                        automated information which you initially provided consent for us to use or
                        where we used the information to perform a contract with you.</span></p>

                <p class=MsoListParagraphCxSpLast style='margin-top:12.0pt;margin-right:0in;
                margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;
                line-height:normal'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>G.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span class=Heading3Char><b><span style='color:#667783'>Withdraw
                                consent</span></b></span><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'> at any time where we are relying on consent to process your
                        personal data. However, this will not affect the lawfulness of any processing
                        carried out before you withdraw your consent. If you withdraw your consent, we
                        may not be able to provide certain services to you.</span></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>&nbsp;</span></p>

                <h2 style='text-align:justify'><a name="_Limitation_of_liability"></a><b><i><span
                                style='font-size:11.0pt;line-height:107%;color:#667783'>Limitation of
                                liability</span></i></b></h2>

                <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Some
                        jurisdictions do not allow the limitation or exclusion of liability for
                        incidental or consequential damages so some of the above limitations may not apply
                        to you.</span></p>

                <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-.25in'><span
                        style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>We
                        make no legal representation that the website or services are appropriate or
                        available for use in locations outside United States. You may access the
                        website from outside United States.at your own risk and initiative and must
                        bear all responsibility for compliance with any applicable foreign laws.</span></p>

                <h3 style='text-align:justify'><b><i><span style='font-size:11.0pt;line-height:
                107%;color:#667783'>Indemnification</span></i></b></h3>

                <p class=MsoNoSpacing style='margin-left:.5in;text-align:justify;text-indent:
                -.25in'><span style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>Upon
                        visiting this website you agree release, indemnify, defend and hold harmless </span><span
                        style='font-family:"Calibri Light",sans-serif;color:#667783'>Peak Focus </span><span
                        style='font-family:"Calibri Light",sans-serif;color:#667783'>and any of its
                        contractors, agents, employees, officers, directors, shareholders, affiliates
                        and assigns from all liabilities, claims, damages, costs and expenses,
                        including reasonable attorneys' fees and expenses, of third parties relating to
                        or arising out of your use of the website content; your use of the app; your
                        violation of any provision of these terms; any unauthorized information or data
                        you supplied to us. You will have sole responsibility to defend us against any
                        claim, but you must receive our prior written consent regarding any related
                        settlement.</span></p>

                <h3 style='text-align:justify'><b><i><span style='font-size:11.0pt;line-height:
                107%;color:#667783'>Governing Law and Jurisdiction</span></i></b></h3>

                <p class=MsoNoSpacing style='margin-left:.5in;text-align:justify;text-indent:
                -.25in'><span style='font-family:Symbol;color:#667783'>·<span
                            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style='font-family:"Calibri Light",sans-serif;color:#667783'>This
                        website originates from United States. The laws of United States. without
                        regard to its conflict of law principles will govern these terms to the
                        contrary. You, hereby agree that, all disputes arising out of or in connection
                        with these terms shall be submitted to the exclusive jurisdiction of the United
                        States. By using this website, you consent to the jurisdiction and venue of
                        such courts in connection with any action, suit, proceeding or claim arising
                        under or by reason of these terms. You hereby waive any right to trial by jury
                        arising out of these terms.</span></p>

                <h2 style='text-align:justify'><b><i><span style='font-size:11.0pt;line-height:
                107%;color:#667783'>Changes to this privacy notice</span></i></b></h2>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>We reserve the right to alter this privacy notice at any time.
                        Such alterations will be posted on our website. You can also obtain an
                        up-to-date copy of our privacy notice by contacting us.</span></p>

                <h2 style='text-align:justify'><a name="_Contacting_us"></a><b><i><span
                                style='font-size:11.0pt;line-height:107%;color:#667783'>Contacting us</span></i></b>
                </h2>

                <p class=MsoNormal><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>If you would like to contact us to understand more about this
                        Policy or wish to contact us concerning any matter relating to individual
                        rights and your Personal Information, you may do so via the contact us or email
                        us at<b> </b></span><a href="mailto:hello@activatedself.co"><b><span
                                style='font-family:"Calibri Light",sans-serif;color:#667783'>hello@activatedself.co</span></b></a>
                </p>

                <p class=MsoNoSpacing><b><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>Activated Self LLC </span></b></p>

                <p class=MsoNoSpacing><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>United States </span></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>&nbsp;</span></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>This document was last updated on August 19, 2021</span></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>&nbsp;</span></p>

                <p class=MsoNormal><span style='font-family:"Calibri Light",sans-serif;
                color:#667783'>&nbsp;</span></p>

                <p class=MsoNormal>&nbsp;</p>

            </div>
        </div>
    </div>

</div>
<div class="copyright-section">
    <div class="container">
        <div class=" row ">
            <div class="col-md-12 col-sm-12">
                <p>Copyright © PeakFocus 2021. All Rights Reserved.</p>
            </div>
        </div>
    </div>
</div>
<!-- jQuery CDN -->

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<!-- Bootstrap 5 CDN -->
<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"
    integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p"
    crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js"
    integrity="sha384-cVKIPhGWiC2Al4u+LWgxfKTRIcfu0JTxR+EQDz/bgldoEyl4H0zUF0QKbrJ0EcQF"
    crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.bundle.min.js"></script>