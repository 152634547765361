<audio controls #audioPlayer [src]="selectedAudio?.url"> </audio>

<div class="nwrapper" [ngStyle]="{ 'background-color': backgroundColor , 'width': width, 'height': height }">

    <!-- loader and error -->
    <div class="loader" *ngIf="!isAudioLoaded && !isError"> Loading....</div>
    <div class="error" *ngIf="isError">No audio Found !</div>
    <div class="container" *ngIf=islist>
        <div *ngIf="isAudioLoaded">
            <div class="details">
                <div class="slidecontainer">
                    <input type="range" max="{{totalAudioLength}}" value="{{currentAudioTime}}"
                        [ngStyle]="{ 'background': timeSliderColor }" (input)="seekAudio($event)" class="slider"
                        id="myRange">
                    <div class="time-line" [ngStyle]="{ 'color' : audioTimeColor }">
                        <div>{{ currentAudioTime | timeConversion}}</div>
                        <div>{{ totalAudioLength | timeConversion}}</div>
                    </div>
                </div>
                <marquee *ngIf="scrollTitle" [ngStyle]="{ 'color' : audioTitleColor }">
                    <p class="title"> {{selectedAudio?.title}}</p>
                </marquee>
                <p class="title" [ngStyle]="{ 'color' : audioTitleColor }" *ngIf="!scrollTitle">
                    {{selectedAudio?.title}}
                </p>
            </div>
            <div class="controls">
                <!-- previous button starts -->
                <svg id="Layer_1" *ngIf="previous" (click)="previousAudio()" data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 65.7"
                    [ngStyle]="{ 'fill' : previousButtonColor }">
                    <title>previous</title>
                    <polygon class="cls-1" points="64.5 0.85 7.05 32.85 64.5 64.85 64.5 0.85" />
                    <rect class="cls-1" x="0.5" y="0.85" width="13.52" height="64" />
                </svg>
                <!-- previous button ends -->

                <!-- play button starts -->
                <svg id="Layer_1" (click)="play()" *ngIf="!isAudioPlaying" data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" [ngStyle]="{ 'fill' : playButtonColor }">
                    <title>play</title>
                    <path class="cls-1"
                        d="M21.65,79.51A3.48,3.48,0,0,1,18,76.22V18.79a3.47,3.47,0,0,1,3.64-3.28L80.8,45s2.73,2.47,0,4.93S21.65,79.51,21.65,79.51Z"
                        transform="translate(-18.01 -15.51)" />
                </svg>
                <!-- play button ends -->

                <!-- pause button starts -->
                <svg id="Layer_1" (click)="pause()" *ngIf="isAudioPlaying" data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" [ngStyle]="{ 'fill' : pauseButtonColor }">
                    <title>pause</title>
                    <g id="pause">
                        <path class="cls-1" d="M16,82H37.33V18H16ZM58.67,18V82H80V18Z" transform="translate(-16 -18)" />
                    </g>
                </svg>
                <!-- pause button ends -->

                <!-- next button starts -->
                <svg id="Layer_1" *ngIf="next" (click)="nextAudio()" data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" [ngStyle]="{ 'fill' : nextButtonColor }">
                    <title>next</title>
                    <polygon class="cls-1" points="0 64 57.45 32 0 0 0 64" />
                    <rect class="cls-1" x="50.48" width="13.52" height="64" />
                </svg>
                <!-- next button ends -->

                <!-- volume button starts -->
                <div class="volume-container">
                    <!-- with volume -->
                    <svg id="volume" *ngIf="!isMute" (click)="muteAudio()" data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"
                        [ngStyle]="{ 'fill' : pauseButtonColor }">
                        <defs>
                            <style>
                                .cls-2 {
                                    fill: none;
                                }
                            </style>
                        </defs>
                        <title>volume-high</title>
                        <path class="cls-1"
                            d="M2.67,22.39V44.28H16.89L34.67,62.52V4.14L16.89,22.39Zm48,10.94a16.46,16.46,0,0,0-8.89-14.7V48A16.37,16.37,0,0,0,50.67,33.33Zm-8.89-32V8.85A25.49,25.49,0,0,1,59.56,33.33,25.5,25.5,0,0,1,41.78,57.82v7.51a32.65,32.65,0,0,0,24.89-32A32.66,32.66,0,0,0,41.78,1.33Z"
                            transform="translate(-2.67 -1.33)" />
                        <path class="cls-2" d="M22.67,21.33h24v24h-24Z" transform="translate(-2.67 -1.33)" />
                    </svg>

                    <!-- mute -->
                    <svg id="Capa_1" *ngIf="isMute" (click)="muteAudio()" data-name="Capa 1"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" [ngStyle]="{ 'fill' : muteButtonColor }">
                        <title>mute</title>
                        <g id="volume-off">
                            <path class="cls-1"
                                d="M59,39.83a15.35,15.35,0,0,0-8.89-14.14v7.78L59,42.31Zm8.89,0A26.37,26.37,0,0,1,66.12,49l5.34,5.3A29.71,29.71,0,0,0,75,39.48,31.94,31.94,0,0,0,50.12,8.36v7.43C60.43,19.33,67.9,28.52,67.9,39.83ZM15.63,8,11,12.61,27.72,29.23H11V50.44H25.23L43,68.12V44.43L58.3,59.64a30,30,0,0,1-8.18,4.24V71.3a30.44,30.44,0,0,0,13.16-6.36L70.39,72,75,67.41,43,35.59ZM43,11.55,35.54,19,43,26.4Z"
                                transform="translate(-11.01 -8.01)" />
                        </g>
                    </svg>

                    <!-- volume button ends -->
                    <div class="volume-control">
                        <input type="range" max="100" value="{{audioVolume}}" (click)="$event.stopPropagation();"
                            (input)="volumeChange($event)" [ngStyle]="{ 'background': volumeSliderColor }"
                            class="slider" id="volumeRange">
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>