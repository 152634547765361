import { Component, OnInit, NgZone, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from "../../../services/auth.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  forgotForm: FormGroup;
  submittedForgot: any = false;
  errorEmail: string;
  // Inject Firebase auth service
  successEmail: string;

  @ViewChild('openModal') openModal: ElementRef;
  @ViewChild('closeModal') closeModal: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    public router: Router,
    public afAuth: AngularFireAuth,
    public ngZone: NgZone,
    public route: ActivatedRoute,
    private common: CommonService
  ) {
    if (this.authService.currentUserValue) {
      this.router.navigate(['/calendar-dashboard']);
    }
    if (localStorage.getItem("user_delete") != '') {
      localStorage.getItem("user_delete") != '' ? this.successEmail = localStorage.getItem("user_delete") : '';
      localStorage.setItem("user_delete", '');
    }
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
    this.forgotForm = this.formBuilder.group({
      Email: ['', [Validators.required, Validators.email]]
    });

    this.authService.getCustomersList();
  }

  get f() { return this.loginForm.controls; }
  get ff() { return this.forgotForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.afAuth.signInWithEmailAndPassword(this.loginForm.value.email, this.loginForm.value.password)
      .then((result) => {
        if (result.user.emailVerified) {
          this.authService.gerfirebaseUserbyUID(result.user.uid);
        } else {
          this.authService.SendVerificationMail(this.loginForm.value.email);
        }
      }).catch((error) => {
        this.ToasterAlert("error", error.message);
      })
    this.closeForgotModel(null);
  }

  forgotpassword() {
    this.openModal.nativeElement.click();
  }

  forgotFormSubmit() {
    this.submittedForgot = true;
    if (this.forgotForm.invalid) {
      return;
    }
    this.afAuth.sendPasswordResetEmail(this.forgotForm.value.Email)
      .then(() => {
        this.ToasterAlert("success", 'Password reset email sent, check your inbox.');

      }).catch((error) => {
        this.ToasterAlert("error", error);
      })
    this.submittedForgot = false;
    this.forgotForm.patchValue({
      Email: ''
    });
    this.closeModal.nativeElement.click();
    this.closeForgotModel(null);
  }

  closeForgotModel(param) {
    if (param == null) {
      let obj = this;
      setTimeout(() => {
        obj.errorEmail = '';
        obj.successEmail = '';
        obj.closeModal.nativeElement.click();
      }, 5000);
    } else {
      this.closeModal.nativeElement.click();
    }
  }
  ToasterAlert(type, message) {
    this.common.Toaster(type, message);

  }
}
