import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
declare var moment: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
 
  options: AnimationOptions = {
    path: '/assets/animation.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  useremail: string = '';
  seconduseremail: string = '';
  useremailthird: string = '';
 
  
  constructor(public router: Router, private common: CommonService, public authService: AuthService) { }

  ngOnInit(): void { }

  SubmitEmail(obj) {
    let email: string = '';
    if (obj == 'first') { email = this.useremail; }
    else if (obj == 'second') { email = this.seconduseremail; }
    else if (obj == 'third') { email = this.useremailthird }
    if (email) {
      if (this.validateEmail(email)) {
        let url = "/sign-up;email=" + email
        window.open(url, '_blank');

      }
      else {
        this.common.Toaster("Error", "Please enter a valid email-id");
        return;
      }
    }
    else {
      window.open("/sign-up", '_blank');
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}
