<nav class="navbar navbar-expand-lg navbar-dark top-nav" *ngIf="currentUser&&currentUrl"
  [class.tribe]="addDynamicClass">
  <a class="navbar-brand" href="javascript:void(0)" routerLink="/calendar-dashboard">
    <img class="brand-img" src="../assets/template/img/Peak-Focus-Logo.png" />
    <span class="font-weight-bold">
      BETA
      <!--How Doers, Get More Done.-->
    </span>
  </a>
  <div class="dropdown ml-md-auto">
    <!--<a routerLink="/test-session" class="mr-3">Launch Test Session</a>
    <a routerLink="/privacypolicy" class="mr-3">Privacy Policy </a>-->
    <button class="btn btn-theme nav-bar task-btn mr-2" id="bodyclasstoggle">Tasks</button>
    <div class="left-head" (click)="toggleShow()">
      <div class="user-name">
        <div class="img"><img src="{{currentUser.photoURL}}"></div>
        <span>{{currentUser.displayName}}</span>
      </div>
      <div class="dropdown">

        <button class="btn dropdown-toggle" type="button"></button>
        <div *ngIf="isShown" id="dropdownshowhide" class="dropdown-menu">
          <span>{{currentUser.displayName}}</span>
          <a class="dropdown-item" (click)="profile()">Profile</a>
          <a class="dropdown-item" routerLink="/test-session">Launch Test Session</a>
          <a class="dropdown-item" routerLink="/privacypolicy">Privacy Policy </a>
          <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Logout</a>
        </div>
      </div>
    </div>


  </div>
</nav>
<div class="player" *ngIf="currentUser&&music">
  <button class="btn btn-theme" id="music-player">
    <span style="font-size:11px">FOCUS</span><span style="font-size:11px">MUSIC</span>
    <i class="fas fa-headphones"></i></button>
  <div class="item-list" id="music-player-list">
    <h6>Waiting for your session? Focus Music will keep you focused before it starts.</h6>
    <div class="item active-track" id="first" (click)="ChangeTrck('first',$event)">
      <img src="../assets/template/img/deep-work.png">
      <span>Deep Work</span>
    </div>
    <div class="item" id="second" (click)="ChangeTrck('second',$event)">
      <img src="../assets/template/img/creative.png">
      <span>Creative</span>
    </div>
    <div class="item" id="third" (click)="ChangeTrck('third',$event)">
      <img src="../assets/template/img/study-read.png">
      <span>Study & Read</span>
    </div>
    <div class="item" id="fourth" (click)="ChangeTrck('fourth',$event)" style="margin-bottom: 40px;">
      <img src="../assets/template/img/light-work.png">
      <span>Light Work</span>
    </div>
    <div class="player-card">
      <ang-music-player [audioList]="audioList" timeSliderColor="{{color}}"></ang-music-player>
    </div>
  </div>
</div>
<router-outlet></router-outlet>