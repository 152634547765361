export class Post {
    public postType: string;
    public postValue: string;
    
    public likeCount: number; // to be fetched from API
    public unlikeCount: number; // to be fetched from API
    public status: string;
    public commentList: Comment[];
    public draftComment: string;

    public constructor() {
        this.postType = 'Text';
        this.postValue = '';

        this.likeCount = 0;
        //this.commentList = [];
        this.unlikeCount = 0; //initialize as 0
        this.status = "none";
        // status can be like or unlike or none
        this.commentList = [];
        this.draftComment = "";

    }
}