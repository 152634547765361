import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalendarDashboardComponent } from './component/calendar-dashboard/calendar-dashboard.component';
import { AwsChimeComponent } from './component/awsChime/awsChime.component';
import { UpcommingComponent } from './component/upcomming/upcomming.component';
import { AuthGuard } from "../app/helper/auth.guard";
import { LoginComponent } from './component/account/login/login.component';
import { RegisterComponent } from './component/account/register/register.component';
import { VerifyEmailComponent } from './component/account/verify-email/verify-email.component';
import { ProfileComponent } from './component/profile/profile.component';
import { PostComponent } from './component/post/post.component';
import { TestSessionComponent } from './component/test-session/test-session.component';
import { GroupsComponent } from './component/groups/groups.component';
import { FeedsComponent } from './component/feeds/feeds.component';
import { QuestionsComponent } from './component/questions/questions.component';
import { PrivacyPolicyComponent } from './component/privacy-policy/privacy-policy.component';
import { NewHomeTemplateComponent } from './component/new-home-template/new-home-template.component';
import { ConfirmVerificationComponent } from './component/account/confirm-verification/confirm-verification.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'calendar-dashboard', component: CalendarDashboardComponent, canActivate: [AuthGuard] },
  { path: 'session', component: AwsChimeComponent, canActivate: [AuthGuard] },
  { path: 'test-session', component: TestSessionComponent, canActivate: [AuthGuard] },
  { path: 'verify-email/:id', component: VerifyEmailComponent },
  { path: 'confirm-email', component: ConfirmVerificationComponent },
  { path: 'profile/:uid', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'sign-up', component: RegisterComponent },
  { path: 'home', component: NewHomeTemplateComponent },
  { path: 'privacypolicy', component: PrivacyPolicyComponent },
  { path: 'post', component: PostComponent, canActivate: [AuthGuard] },
  { path: 'upcoming', component: UpcommingComponent, canActivate: [AuthGuard] },
  { path: 'groups', component: GroupsComponent, canActivate: [AuthGuard] },
  { path: 'feeds', component: FeedsComponent, canActivate: [AuthGuard] },
  { path: 'questions', component: QuestionsComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'calendar-dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
