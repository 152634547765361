import { VideoTileState } from "amazon-chime-sdk-js";

export class VideoFeed {
    speaker: boolean;
    // tileState: VideoTileState;
    index: number;
    attendeeId: any;
    externalId: string;
    content: boolean
    local: boolean;
    tileId: number;
    isPin: boolean;
    activeTile: boolean = false;

    constructor(speaker: boolean, index: number, state: VideoTileState) {
        this.speaker = speaker;
        // this.tileState = state;
        this.attendeeId = state.boundAttendeeId
        this.externalId = state.boundExternalUserId;
        this.content = state.isContent;
        this.local = state.localTile;
        this.tileId = state.tileId;
        this.activeTile = false;
        this.index = index;
        this.isPin = false;
    }


    isSpeaker() {
        return this.speaker === true;
    }

    isContent() {
        return this.content;
    }

    /**
     * Is attendee ID
     * @param id
     */
    is(id) {
        return id === this.attendeeId
    }

    name() {
        return (this.externalId.split('#').slice(-1)[0]);
    }

    isRemote() {
        return !this.local;
    }

    pin() {
        this.isPin = true;
    }

    unpin() {
        this.isPin = false;
    }
}

export class Admission {
    public attendees: {} = {};

    constructor(attendees: []) {
        if (attendees) {
            for (let i = 0; i < attendees.length; i++) {
                this.attendees[attendees[i]["attendee_id"]] = attendees[i];
            }
            console.log("attendees: ", attendees);
        }
    }
}

export class DataEvent {
    public action: string;
    public data: string;
    public topic: string;
    constructor(topic: string, action: string, data = null) {
        this.action = action;
        this.topic = topic;
        this.data = data;
    }
}