export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCHUljgDkoJMBegCFA8dSXsDfs9r8LBMLw",
    authDomain: "superbrain-c2070.firebaseapp.com",
    databaseURL: "https://superbrain-c2070.firebaseio.com",
    projectId: "superbrain-c2070",
    storageBucket: "superbrain-c2070.appspot.com",
    messagingSenderId: "2785391677",
    appId: "1:2785391677:web:bc3b01bfc03e4a3ebd2654",
    measurementId: "G-G1C615CQWZ"
  },
  tribe_base_url: "https://asas.tribe.so/api/v1/",
  awsConfig: {
    accessKeyId: "AKIARYFKISGWMPP2FV4O",
    secretAccessKey: "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",
    region: "us-east-1"
  }
};