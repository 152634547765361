<div class="questions-head" *ngIf="first_question||second_question||third_question">
	<h2 class="text-center mt-5 pt-5">
		Welcome to Peak Focus!
		<img src="../../../assets/template/img/smile.png" style="height: 25px;margin-left: 10px;">
		<p>
			Before you go in, we'd like to personalize the club for you.<br>
			Just answer these 3 Questions.
		</p>
	</h2>
</div>

<div class="quest-wrapper" style="max-width: 1200px !important" *ngIf="third_question">
	<div class="wrapper-info">
		<div class="current-one">Question {{current_question}} of 3</div>
		<div>
			<button type="button" class="btn theme-gradient float-right" [disabled]="disabled"
				(click)="Next('fourth')">Next</button>

			<h5>When will you need to focus? </h5>
			<p>Choose 1 or more 50min session</p>
			<full-calendar class="mt-4" id="calendar" #calendar [options]="calendarOptions"></full-calendar>
			<div class="remove-continue" *ngIf="bookingselected">
				<button class="btn btn-remove" (click)="removeSelectedBooking()">Remove {{bookingselctioncount}}
					Booking</button>
				<button class="btn btn-continue" [disabled]="clicked" (click)="continueBooking(); clicked;">Continue to
					book
					{{bookingselctioncount}}
					Session</button>
			</div>
			<div class="btn-cover">
				<button type="button" class="btn" [disabled]="disabled" (click)="Next('fourth')">Next</button>
			</div>
		</div>
	</div>
</div>



<div class="quest-outer-area">
	<!-- <div class="outer-btn-cover" *ngIf="first_question">
		<button type="button" class="theme-gradient btn" (click)="submitQuiz(first_one)">Next</button>
	</div> -->
	<div class="quest-wrapper" *ngIf="!third_question">
		<div class="wrapper-info">
			<!-- <div class="current-one" *ngIf="first_question">Question 1,2 of 5</div> -->
			<div class="current-one" *ngIf="first_question||second_question">Question
				{{current_question}} of 3</div>

			<!-- <div *ngIf="second_question"> -->
			<div *ngIf="first_question">
				<h5>What is one goal that you are trying to reach in the next 12 weeks?</h5>
				<input type="text" class="form-control" id="secondAns" (keyup)="validate()">
				<div class="btn-cover">
					<button type="button" class="btn" [disabled]="disabled" (click)="Next('second')">Next</button>
				</div>
			</div>

			<div *ngIf="second_question">
				<h5>What are 3 tasks you need to do in the next week?</h5>
				<form class="login100-form validate-form" *ngIf="second_question" [formGroup]="taskForm"
					(ngSubmit)="remainderSubmit()">
					<table class="table table-borderless">
						<tbody>
							<tr>
								<th scope="row" style="line-height: 2;">1</th>
								<th scope="row">
									<input type="text" class="form-control mb-15 height-30" formControlName="taskName" />
									<div *ngIf="submitted && f.taskName.errors" class="text-danger validate-error">
										<div *ngIf="f.taskName.errors.required">Task name is required !</div>
									</div>
								</th>
							</tr>
							<tr>
								<th scope="row" style="line-height: 2;">2</th>
								<th scope="row">
									<input type="text" class="form-control mb-15 height-30" formControlName="taskName2" />
									<div *ngIf="submitted && f.taskName2.errors" class="text-danger validate-error">
										<div *ngIf="f.taskName2.errors.required">Task name is required !</div>
									</div>
								</th>
							</tr>
							<tr>
								<th scope="row" style="line-height: 2;">3</th>
								<th scope="row">
									<input type="text" class="form-control mb-15 height-30" formControlName="taskName3" />
									<div *ngIf="submitted && f.taskName3.errors" class="text-danger validate-error">
										<div *ngIf="f.taskName3.errors.required">Task name is required !</div>
									</div>
								</th>
							</tr>
						</tbody>
					</table>
					<div class="btn-cover">
						<button type="submit" class="btn">Submit</button>
					</div>
				</form>
			</div>

			<!-- <div *ngIf="first_question text-center">
				<div class="quest-wrapper2">
					<div class="wrapper-info">
						<div *ngIf="first_one==1">
							<h2 class="beta-color text-center">We're Turning Productivity</h2>
							<h2 class="beta-color text-center mb-5">Into A PARTY! <img height="30"
									src="../../../assets/template/img/party-icon.png" />
							</h2>
							<h3 class="text-center">"Ok Babe...</h3>
							<h3 class="text-center">Let's Rock and Roll"</h3>
							<div class="text-center"><img height="30"
									src="../../../assets/template/img/guitar-icon.png" />
							</div>
						</div>

						<div *ngIf="first_one==2">
							<div class="text-center"><img height="30"
									src="../../../assets/template/img/waving-hand-icon.png" />
							</div>
							<h2 class="beta-color text-center text-uppercase">Hey</h2>
							<h2 class="beta-color text-center mb-5"><span class="text-danger">!!</span> Full Disclosure
								<span class="text-danger">!!</span>
							</h2>
							<p class="text-center mb-2">I'm still in BETA...</p>
							<p class="text-center mb-0">So things might be a little</p>
							<p class="text-center">"Rough around the edges"</p>
							<p class="text-center beta-color">I might be wee slow <img height="20"
									src="../../../assets/template/img/man-shrugging-icon.png" /></p>
							<p class="text-center mb-0"><i>Just know you'll be part of the IN CROWD</i></p>
							<p class="text-center mb-0"><i>That remembers when</i></p>
							<p class="text-center mb-0"><i>"It used to take me a whole 5 seconds to<br>__________."</i>
							</p>
						</div>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</div>

<button type="button" class="btn btn-primary" style="display: none;" data-toggle="modal" data-target="#confirmBooking"
	#confirmBookingModal></button>

<div class="modal fade" id="confirmBooking" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
	aria-hidden="true" #confirmBookingCloseModal>
	<div class="modal-dialog modal-dialog-centered custom-alert" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLongTitle">Meeting Booking</h5>
			</div>
			<div class="modal-body">
				<h5 class="text-center">Remove Marked Event</h5>
				<p class="text-center">Are you sure you dont want to book selected session!</p>
				<div class="row text-center">
					<div class="col-md-12">
						<p class="confirm-button">
							<a class="mr-2"><button class="btn btn-success" data-dismiss="modal" aria-label="Close"
									(click)="cancelevent()">No</button></a>
							<a><button class="btn btn-success" (click)="confirmremoveevent()">Yes</button></a>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<button type="button" class="btn btn-primary" style="display: none;" data-toggle="modal" data-target="#Booktask"
	#taskbooktaskopenModal></button>

<div class="modal fade" id="Booktask" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered custom-alert" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLongTitle">{{meetingbutton}}</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close" #deleteBookCloseModal>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row text-center">
					<div class="col-md-12">
						<p class="confirm-button">
							<a>
								<button class="btn btn-primary" (click)="sure_book_it()">Book ?</button>
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<!-- Meeting Booked Popup -->
<div class="meeting-booked-popup" id="book-meeting-notification" style="display: none;">
	<div class="popup-body">
		<div class="d-flex justify-content-center mb-2">
			<img src="../../../assets/template/img/thumbs-up.png" style="height: 25px;">
			<h3 class="m-0 pl-2">Focus Sessions</h3>
		</div>
		<div class="d-flex justify-content-center">
			<img src="../../../assets/template/img/square-tick.png" style="height: 25px;">
			<h3 class="m-0 pl-2">Booked!</h3>
		</div>
	</div>
</div>