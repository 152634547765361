import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../services/auth.service'
import { AwsChimeService } from '../../services/aws-chime/aws-chime.service'
import { AsyncScheduler, AudioVideoFacade, ConsoleLogger, DefaultModality, DefaultDeviceController, DefaultMeetingSession, LogLevel, MeetingSessionConfiguration, MeetingSessionStatusCode } from 'amazon-chime-sdk-js';
import { Router } from '@angular/router';
import { CommonService } from '../../services/common.service'

declare var $: any;

@Component({
  selector: 'app-test-session',
  templateUrl: './test-session.component.html',
  styleUrls: ['./test-session.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TestSessionComponent implements OnInit, OnDestroy {

  currentUser: any;
  meetingSession: DefaultMeetingSession;
  audioVideo: AudioVideoFacade;
  meeting: any;
  meeting_id: any;
  attendee: any;
  loader: boolean = true;
  private startVideo: boolean = false;
  cameralabel: any = "Turn off";
  selfAttendeeId: any;
  documentId: any;
  taskEndtime: any;
  taskStartTime: any;
  calldestroy: boolean = false;

  options = {
    'elements': { 'audio': 'meeting-audio' },
    'callbackStart': () => { },
    callbackFailMeetingStart: (e: any) => { }
  };

  constructor(public afs: AngularFirestore, public authService: AuthService, private aswservice: AwsChimeService, private router: Router, private commonser: CommonService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.taskStartTime = new Date();
    this.taskEndtime = new Date();
    this.taskEndtime.setMinutes(this.taskEndtime.getMinutes() + 20);
    new AsyncScheduler().start(async () => {
      await this.JoinMeeting();
      await this.aws_meeting();
    });
    var timer2 = "20:00";

    var interval = setInterval(function () {
      var timer = timer2.split(':');
      let minutes = parseInt(timer[0], 10);
      let seconds = parseInt(timer[1], 10);
      --seconds;
      minutes = (seconds < 0) ? --minutes : minutes;
      if (minutes < 0) clearInterval(interval);

      seconds = (seconds < 0) ? 59 : seconds;
      seconds = (seconds < 10) ? seconds : seconds;
      let secd = (seconds < 10) ? '0' + seconds : seconds;
      let min = (minutes < 10) ? '0' + minutes : minutes;
      $('#countdown').html(min + ':' + secd);
      timer2 = minutes + ':' + seconds;
    }, 1000);

    setInterval(() => {
      if (!this.calldestroy) {
        this.TimeDifference();
      }
    }, 1000);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() { this.RemoveAttendeeOnLeaveMeeting(); this.calldestroy = true; }


  async JoinMeeting() {
    return new Promise((resolve, reject) => {
      this.aswservice.createMeeting(this.currentUser.firstname).subscribe(async (response: any) => {
        this.documentId = response.message.documentId;
        this.meeting = response.message.meeting.Meeting;
        this.meeting_id = response.message.meeting.Meeting.MeetingId;
        this.attendee = response.message.attendee.Attendee;
        resolve("Join Meeting");
      }, error => {
        reject(error);
      });
    });
  }

  private async setConfiguration() {
    const audioInputs = await this.audioVideo.listAudioInputDevices();
    const audioOutputs = await this.audioVideo.listAudioOutputDevices();
    const audioElement = document.getElementById(this.options.elements.audio) as HTMLAudioElement;
    if (audioInputs.length > 0) {
      await this.meetingSession.audioVideo.chooseAudioInputDevice(audioInputs[0].deviceId);
    } else {
      await this.meetingSession.audioVideo.chooseAudioInputDevice(null);
    }
    if (audioOutputs.length > 0) {
      await this.meetingSession.audioVideo.chooseAudioOutputDevice(audioOutputs[0].deviceId);
    } else {
      await this.meetingSession.audioVideo.chooseAudioOutputDevice(null);
    }
    this.audioVideo.addObserver({
      audioVideoDidStart: () => {
        this.options.callbackStart();
      }
    });
    if (audioElement !== null) {
      this.audioVideo.bindAudioElement(audioElement);
    }
  }

  async aws_meeting() {
    return new Promise(async (resolve, rejects) => {
      try {
        if (!this.calldestroy) {

          const logger = new ConsoleLogger('MyLogger', LogLevel.OFF);
          const deviceController = new DefaultDeviceController(logger);
          const configuration = new MeetingSessionConfiguration(this.meeting, this.attendee);
          this.meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);

          this.audioVideo = this.meetingSession.audioVideo;
          const observer = {
            audioVideoDidStart: () => {
              this.loader = false;
            }
          };

          this.meetingSession.audioVideo.addObserver(observer);
          await this.setConfiguration();
          this.setupSubscribePresenceHandle();
          this.EndMeeting();
          this.bindEvents();
          this.meetingSession.audioVideo.start();
          resolve("done");
        }
      }
      catch {
        console.error("Error");
        rejects("Error")
      }
    });
  }

  setupSubscribePresenceHandle(): void {
    const handler = async (attendeeId: string, present: boolean, externalUserId: string, dropped: boolean) => {
      const isContentAttendee = new DefaultModality(attendeeId).hasModality(DefaultModality.MODALITY_CONTENT);
      let isSelfAttendee = new DefaultModality(attendeeId).base() === this.meetingSession.configuration.credentials.attendeeId;
      if (!present) {
        return;
      }
    };
    this.audioVideo.realtimeSubscribeToAttendeeIdPresence(handler);
  }

  async bindEvents(): Promise<void> {
    this.HandleCamera();
    this.HandleMic();
  }

  async HandleCamera() {
    if (this.startVideo) {
      this.audioVideo.stopLocalVideoTile();
      this.startVideo = false;
      this.cameralabel = "Video off";
      $("#self-camera-icon").hide();
      $("#self-camera-off-icon").show();
      $("#self-description-camera-on-icon").hide();
      $("#self-description-camera-off-icon").show();
    }
    else {
      const videoInputDevices = await this.audioVideo.listVideoInputDevices();
      if (videoInputDevices.length > 0) {
        await this.audioVideo.chooseVideoInputDevice(videoInputDevices[0].deviceId);
        this.audioVideo.startLocalVideoTile();
        this.startVideo = true;
        this.cameralabel = "Video on";
        $("#self-camera-icon").show();
        $("#self-camera-off-icon").hide();
        $("#self-description-camera-on-icon").show();
        $("#self-description-camera-off-icon").hide();
      }
      else {
        await this.audioVideo.chooseVideoInputDevice(null);
        this.cameralabel = "Video off";
        $("#self-camera-icon").hide();
        $("#self-camera-off-icon").show();
        $("#self-description-camera-on-icon").hide();
        $("#self-description-camera-off-icon").show();
      }
    }

    const observer = {
      videoTileDidUpdate: tileState => {
        if (!tileState.boundAttendeeId) {
          return;
        }

        this.selfAttendeeId = this.meetingSession.configuration.credentials.attendeeId;
        const videoElement = document.getElementById("self-video") as HTMLVideoElement;
        if (videoElement !== null) {
          this.audioVideo.bindVideoElement(tileState.tileId, videoElement);
        }


      },
      videoTileWasRemoved: tileId => {
      }
    };
    if (this.audioVideo !== undefined) {

      this.audioVideo.addObserver(observer);
    }
  }

  HandleMic() {
    if (this.audioVideo.realtimeIsLocalAudioMuted()) {
      $("#self-mic-icon").show();
      $("#self-mic-off-icon").hide();
      this.audioVideo.realtimeUnmuteLocalAudio();

    } else {
      $("#self-mic-icon").hide();
      $("#self-mic-off-icon").show();
      this.audioVideo.realtimeMuteLocalAudio();
    }
  }

  HandleChat() {
    if ($('.session-video').hasClass('rp-open')) {
      if ($('#pills-profile-tab').hasClass("active")) {
        $('.session-video').removeClass('rp-open');
      }
    } else {
      $('.session-video').addClass('rp-open');
    }

    $('#pills-profile-tab').click();

  }

  HandleTask() {
    if ($('.session-video').hasClass('rp-open')) {
      if ($('#pills-home-tab').hasClass("active")) {
        $('.session-video').removeClass('rp-open');
      }
    } else {
      $('.session-video').addClass('rp-open');
    }
    $('#pills-home-tab').click();
  }

  chatMessageSend() {
    if ($('#chat-input').val().length > 0) {
      let chat = $("#chat-input").val();
      $('#chat-dashboard').append("<div class='msg right-msg'><div class='msg-img' style='background-image: url(" + this.currentUser.photoURL + ")'></div><div class='msg-bubble'><div class='msg-info'><div class='msg-info-name'>" + this.currentUser.firstname + "</div><div class='msg-info-time'>" + this.getCurrentTime(new Date) + "</div></div><div class='msg-text'>" + $('#chat-input').val() + "</div></div></div>");
      $("#chat-input").val("");
    }
  }

  getCurrentTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes;
  };

  LeaveMeeting() {
    const observer = {
      audioVideoDidStop: async sessionStatus => {
        const sessionStatusCode = sessionStatus.statusCode();
        if (sessionStatusCode === MeetingSessionStatusCode.Left) {
          /*
            - You called meetingSession.audioVideo.stop().
            - When closing a browser window or page, Chime SDK attempts to leave the session.
          */
          this.aswservice.deletemeetingattendee(this.selfAttendeeId, this.documentId).subscribe((data: any) => { });
          this.audioVideo.realtimeMuteLocalAudio();
          this.audioVideo.stopLocalVideoTile();
          await this.meetingSession.audioVideo.chooseVideoInputDevice(null);
          this.router.navigate(['/calendar-dashboard']);
          // //console.log('You left the session');
        } else {
          //console.log('Stopped with a session status code: ', sessionStatusCode);
        }
      }
    };
    if (this.audioVideo !== undefined) {
      this.audioVideo.addObserver(observer);
      this.audioVideo.stop();
    }
     this.commonser.Toaster("error", "😲<br /> Focus Session Ended")
     
  }



  async EndMeeting(): Promise<any> {
    const observer = {
      audioVideoDidStop: sessionStatus => {
        const sessionStatusCode = sessionStatus.statusCode();
        if (sessionStatusCode === MeetingSessionStatusCode.MeetingEnded) {
          this.deletemeetingattendeecollection();
          this.router.navigate(['/calendar-dashboard']);
        } else {
          // console.log('Stopped with a session status code: ', sessionStatusCode);
        }
      }
    };

    this.meetingSession.audioVideo.addObserver(observer);
  }

  deletemeetingattendeecollection() {
    this.aswservice.deletemeetingattendeecollection(this.documentId).subscribe((data: any) => { })
  }

  RemoveAttendeeOnLeaveMeeting() {
    this.LeaveMeeting();
  }

  TimeDifference() {
    var date = new Date();
    var dt2 = new Date(this.taskStartTime);
    var dt3 = new Date(this.taskEndtime);
    var diff = (date.getTime() - dt2.getTime()) / 1000;
    diff /= 60;
    if (Math.abs(Math.round(diff)) < 180) {
      if (date.getTime() >= dt2.getTime() && date.getTime() <= dt3.getTime()) {
        if (Math.abs(Math.round((dt3.getTime() - date.getTime()) / 1000)) == 600) {
          var audio = document.getElementById("audio-notification") as HTMLAudioElement;
          audio.play();
          this.commonser.Toaster("success", "The session is about to end in 10 minutes. Start a discussion with your partner and share if you have achieved your set targets.")
        }
      } else if (date.getTime() > dt3.getTime()) {
        this.commonser.Toaster("success", "Meeting Ended! Hoping it served your purpose.")
        this.LeaveMeeting();
        this.router.navigate(['/calendar-dashboard']);
      } else { console.log('No meeting'); }
    }
  }

}
