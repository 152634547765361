import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from "../../services/user";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';

declare var $: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  @ViewChild('closeModal') closeModal: ElementRef;

  currentUID: string;
  userDetials: any;
  fileToUpload: File = null;
  profileForm: FormGroup;
  submitted = false;
  editprofile: boolean = false;
  mySelect = '2';
  errorEmail: string;

  region = [
    {
      title: 'United States',
      id: 1
    },
    {
      title: 'Africa',
      id: 2
    },
    {
      title: 'America',
      id: 3
    },
    {
      title: 'Asia',
      id: 4
    },
    {
      title: 'Atlantic',
      id: 5
    },
    {
      title: 'Australia',
      id: 6
    },
    {
      title: 'Canada',
      id: 7
    },
    {
      title: 'Europe',
      id: 8
    },
    {
      title: 'Indian',
      id: 9
    },
    {
      title: 'Pacific',
      id: 10
    }
  ]

  timezone = [
    {
      title: 'Alaska AKDT, -08:00',
      parentId: 1
    },
    {
      title: 'Aleutian HDT, -09:00',
      parentId: 1
    },
    {
      title: 'Arizona MST, -07:00',
      parentId: 1
    },
    {
      title: 'Central CDT, -05:00',
      parentId: 1
    },
    {
      title: 'East-Indiana EDT, -04:00',
      parentId: 1
    },
    {
      title: 'Eastern EDT, -04:00',
      parentId: 1
    },
    {
      title: 'Hawaii HST, -10:00',
      parentId: 1
    },
    {
      title: 'Indiana-Starke CDT, -05:00',
      parentId: 1
    },
    {
      title: 'Michigan EDT, -04:00',
      parentId: 1
    },
    {
      title: 'Mountain MDT, -06:00',
      parentId: 1
    },
    {
      title: 'Pacific PDT, -07:00',
      parentId: 1
    },
    {
      title: 'Samoa SST, -11:00',
      parentId: 1
    },
    {
      title: 'Abidjan GMT, +00:00',
      parentId: 2
    },
    {
      title: 'Accra GMT, +00:00',
      parentId: 2
    },
    {
      title: 'Addis_Ababa EAT, +03:00',
      parentId: 2
    },
    {
      title: 'Algiers CET, +01:00',
      parentId: 2
    },
    {
      title: 'Asmara EAT, +03:00',
      parentId: 2
    },
    {
      title: 'Asmera EAT, +03:00',
      parentId: 2
    },
    {
      title: 'Bamako GMT, +00:00',
      parentId: 2
    },
    {
      title: 'Bangui WAT, +01:00',
      parentId: 2
    },
    {
      title: 'Banjul GMT, +00:00',
      parentId: 2
    },
    {
      title: 'Bissau GMT, +00:00',
      parentId: 2
    },
    {
      title: 'Blantyre CAT, +02:00',
      parentId: 2
    },
    {
      title: 'Brazzaville WAT, +01:00',
      parentId: 2
    },
    {
      title: 'Bujumbura CAT, +02:00',
      parentId: 2
    },
    {
      title: 'Cairo EET, +02:00',
      parentId: 2
    },
    {
      title: 'Casablanca +01, +01:00',
      parentId: 2
    },
    {
      title: 'Ceuta CET, +01:00',
      parentId: 2
    },
    {
      title: 'Conakry GMT, +00:00',
      parentId: 2
    },
    {
      title: 'Dakar GMT, +00:00',
      parentId: 2
    },
    {
      title: 'Dar_es_Salaam EAT, +03:00',
      parentId: 2
    },
    {
      title: 'Djibouti EAT, +03:00',
      parentId: 2
    },
    {
      title: 'Douala WAT, +01:00',
      parentId: 2
    },
    {
      title: 'El_Aaiun +01, +01:00',
      parentId: 2
    },
    {
      title: 'Freetown GMT, +00:00',
      parentId: 2
    },
    {
      title: 'Gaborone CAT, +02:00',
      parentId: 2
    },
    {
      title: 'Harare CAT, +02:00',
      parentId: 2
    },
    {
      title: 'Johannesburg SAST, +02:00',
      parentId: 2
    },
    {
      title: 'Juba EAT, +03:00',
      parentId: 2
    },
    {
      title: 'Kampala EAT, +03:00',
      parentId: 2
    },
    {
      title: 'Khartoum CAT, +02:00',
      parentId: 2
    },
    {
      title: 'Kigali CAT, +02:00',
      parentId: 2
    },
    {
      title: 'Kinshasa WAT, +01:00',
      parentId: 2
    },
    {
      title: 'Lagos WAT, +01:00',
      parentId: 2
    },
    {
      title: 'Libreville WAT, +01:00',
      parentId: 2
    },
    {
      title: 'Lome GMT, +00:00',
      parentId: 2
    },
    {
      title: 'Luanda WAT, +01:00 ',
      parentId: 2
    },
    {
      title: 'Lubumbashi CAT, +02:00',
      parentId: 2
    },
    {
      title: 'Lusaka CAT, +02:00',
      parentId: 2
    },
    {
      title: 'Malabo WAT, +01:00',
      parentId: 2
    },
    {
      title: 'Maputo CAT, +02:00',
      parentId: 2
    },
    {
      title: 'Maseru SAST, +02:00',
      parentId: 2
    },
    {
      title: 'Mbabane SAST, +02:00',
      parentId: 2
    },
    {
      title: 'Mogadishu EAT, +03:00',
      parentId: 2
    },
    {
      title: 'Monrovia GMT, +00:00',
      parentId: 2
    },
    {
      title: 'Nairobi EAT, +03:00',
      parentId: 2
    },
    {
      title: 'Ndjamena WAT, +01:00',
      parentId: 2
    },
    {
      title: 'Niamey WAT, +01:00',
      parentId: 2
    },
    {
      title: 'Nouakchott GMT, +00:00',
      parentId: 2
    },
    {
      title: 'Ouagadougou GMT, +00:00',
      parentId: 2
    },
    {
      title: 'Porto-Novo WAT, +01:00',
      parentId: 2
    },
    {
      title: 'Sao_Tome GMT, +00:00',
      parentId: 2
    },
    {
      title: 'Timbuktu GMT, +00:00',
      parentId: 2
    },
    {
      title: 'Tripoli EET, +02:00',
      parentId: 2
    },
    {
      title: 'Tunis CET, +01:00',
      parentId: 2
    }, {
      title: 'Windhoek CAT, +02:00',
      parentId: 2
    },
    { title: 'Adak HDT, -09:00                               ', parentId: 3 },
    { title: 'Anchorage AKDT, -08:00                         ', parentId: 3 },
    { title: 'Anguilla AST, -04:00                           ', parentId: 3 },
    { title: 'Antigua AST, -04:00                            ', parentId: 3 },
    { title: 'Araguaina -03, -03:00                          ', parentId: 3 },
    { title: 'Argentina/Buenos_Aires -03, -03:00             ', parentId: 3 },
    { title: 'Argentina/Catamarca -03, -03:00                ', parentId: 3 },
    { title: 'Argentina/ComodRivadavia -03, -03:00           ', parentId: 3 },
    { title: 'Argentina/Cordoba -03, -03:00                  ', parentId: 3 },
    { title: 'Argentina/Jujuy -03, -03:00                    ', parentId: 3 },
    { title: 'Argentina/La_Rioja -03, -03:00                 ', parentId: 3 },
    { title: 'Argentina/Mendoza -03, -03:00                  ', parentId: 3 },
    { title: 'Argentina/Rio_Gallegos -03, -03:00             ', parentId: 3 },
    { title: 'Argentina/Salta -03, -03:00                    ', parentId: 3 },
    { title: 'Argentina/San_Juan -03, -03:00                 ', parentId: 3 },
    { title: 'Argentina/San_Luis -03, -03:00                 ', parentId: 3 },
    { title: 'Argentina/Tucuman -03, -03:00                  ', parentId: 3 },
    { title: 'Argentina/Ushuaia -03, -03:00                  ', parentId: 3 },
    { title: 'Aruba AST, -04:00                              ', parentId: 3 },
    { title: 'Asuncion -03, -03:00                           ', parentId: 3 },
    { title: 'Atikokan EST, -05:00                           ', parentId: 3 },
    { title: 'Atka HDT, -09:00                               ', parentId: 3 },
    { title: 'Bahia -03, -03:00                              ', parentId: 3 },
    { title: 'Bahia_Banderas CST, -06:00                     ', parentId: 3 },
    { title: 'Barbados AST, -04:00                           ', parentId: 3 },
    { title: 'Belem -03, -03:00                              ', parentId: 3 },
    { title: 'Belize CST, -06:00                             ', parentId: 3 },
    { title: 'Blanc-Sablon AST, -04:00                       ', parentId: 3 },
    { title: 'Boa_Vista -04, -04:00                          ', parentId: 3 },
    { title: 'Bogota -05, -05:00                             ', parentId: 3 },
    { title: 'Boise MDT, -06:00                              ', parentId: 3 },
    { title: 'Buenos_Aires -03, -03:00                       ', parentId: 3 },
    { title: 'Cambridge_Bay MDT, -06:00                      ', parentId: 3 },
    { title: 'Campo_Grande -04, -04:00                       ', parentId: 3 },
    { title: 'Cancun EST, -05:00                             ', parentId: 3 },
    { title: 'Caracas -04, -04:00                            ', parentId: 3 },
    { title: 'Catamarca -03, -03:00                          ', parentId: 3 },
    { title: 'Cayenne -03, -03:00                            ', parentId: 3 },
    { title: 'Cayman EST, -05:00                             ', parentId: 3 },
    { title: 'Chicago CDT, -05:00                            ', parentId: 3 },
    { title: 'Chihuahua MST, -07:00                          ', parentId: 3 },
    { title: 'Coral_Harbour EST, -05:00                      ', parentId: 3 },
    { title: 'Cordoba -03, -03:00                            ', parentId: 3 },
    { title: 'Costa_Rica CST, -06:00                         ', parentId: 3 },
    { title: 'Creston MST, -07:00                            ', parentId: 3 },
    { title: 'Cuiaba -04, -04:00                             ', parentId: 3 },
    { title: 'Curacao AST, -04:00                            ', parentId: 3 },
    { title: 'Danmarkshavn GMT, +00:00                       ', parentId: 3 },
    { title: 'Dawson MST, -07:00                             ', parentId: 3 },
    { title: 'Dawson_Creek MST, -07:00                       ', parentId: 3 },
    { title: 'Denver MDT, -06:00                             ', parentId: 3 },
    { title: 'Detroit EDT, -04:00                            ', parentId: 3 },
    { title: 'Dominica AST, -04:00                           ', parentId: 3 },
    { title: 'Edmonton MDT, -06:00                           ', parentId: 3 },
    { title: 'Eirunepe -05, -05:00                           ', parentId: 3 },
    { title: 'El_Salvador CST, -06:00                        ', parentId: 3 },
    { title: 'Ensenada PDT, -07:00                           ', parentId: 3 },
    { title: 'Fort_Nelson MST, -07:00                        ', parentId: 3 },
    { title: 'Fort_Wayne EDT, -04:00                         ', parentId: 3 },
    { title: 'Fortaleza -03, -03:00                          ', parentId: 3 },
    { title: 'Glace_Bay ADT, -03:00                          ', parentId: 3 },
    { title: 'Godthab -03, -03:00                            ', parentId: 3 },
    { title: 'Goose_Bay ADT, -03:00                          ', parentId: 3 },
    { title: 'Grand_Turk EDT, -04:00                         ', parentId: 3 },
    { title: 'Grenada AST, -04:00                            ', parentId: 3 },
    { title: 'Guadeloupe AST, -04:00                         ', parentId: 3 },
    { title: 'Guatemala CST, -06:00                          ', parentId: 3 },
    { title: 'Guayaquil -05, -05:00                          ', parentId: 3 },
    { title: 'Guyana -04, -04:00                             ', parentId: 3 },
    { title: 'Halifax ADT, -03:00                            ', parentId: 3 },
    { title: 'Havana CDT, -04:00                             ', parentId: 3 },
    { title: 'Hermosillo MST, -07:00                         ', parentId: 3 },
    { title: 'Indiana/Indianapolis EDT, -04:00               ', parentId: 3 },
    { title: 'Indiana/Knox CDT, -05:00                       ', parentId: 3 },
    { title: 'Indiana/Marengo EDT, -04:00                    ', parentId: 3 },
    { title: 'Indiana/Petersburg EDT, -04:00                 ', parentId: 3 },
    { title: 'Indiana/Tell_City CDT, -05:00                  ', parentId: 3 },
    { title: 'Indiana/Vevay EDT, -04:00                      ', parentId: 3 },
    { title: 'Indiana/Vincennes EDT, -04:00                  ', parentId: 3 },
    { title: 'Indiana/Winamac EDT, -04:00                    ', parentId: 3 },
    { title: 'Indianapolis EDT, -04:00                       ', parentId: 3 },
    { title: 'Inuvik MDT, -06:00                             ', parentId: 3 },
    { title: 'Iqaluit EDT, -04:00                            ', parentId: 3 },
    { title: 'Jamaica EST, -05:00                            ', parentId: 3 },
    { title: 'Jujuy -03, -03:00                              ', parentId: 3 },
    { title: 'Juneau AKDT, -08:00                            ', parentId: 3 },
    { title: 'Kentucky/Louisville EDT, -04:00                ', parentId: 3 },
    { title: 'Kentucky/Monticello EDT, -04:00                ', parentId: 3 },
    { title: 'Knox_IN CDT, -05:00                            ', parentId: 3 },
    { title: 'Kralendijk AST, -04:00                         ', parentId: 3 },
    { title: 'La_Paz -04, -04:00                             ', parentId: 3 },
    { title: 'Lima -05, -05:00                               ', parentId: 3 },
    { title: 'Los_Angeles PDT, -07:00                        ', parentId: 3 },
    { title: 'Louisville EDT, -04:00                         ', parentId: 3 },
    { title: 'Lower_Princes AST, -04:00                      ', parentId: 3 },
    { title: 'Maceio -03, -03:00                             ', parentId: 3 },
    { title: 'Managua CST, -06:00                            ', parentId: 3 },
    { title: 'Manaus -04, -04:00                             ', parentId: 3 },
    { title: 'Marigot AST, -04:00                            ', parentId: 3 },
    { title: 'Martinique AST, -04:00                         ', parentId: 3 },
    { title: 'Matamoros CDT, -05:00                          ', parentId: 3 },
    { title: 'Mazatlan MST, -07:00                           ', parentId: 3 },
    { title: 'Mendoza -03, -03:00                            ', parentId: 3 },
    { title: 'Menominee CDT, -05:00                          ', parentId: 3 },
    { title: 'Merida CST, -06:00                             ', parentId: 3 },
    { title: 'Metlakatla AKDT, -08:00                        ', parentId: 3 },
    { title: 'Mexico_City CST, -06:00                        ', parentId: 3 },
    { title: 'Miquelon -02, -02:00                           ', parentId: 3 },
    { title: 'Moncton ADT, -03:00                            ', parentId: 3 },
    { title: 'Monterrey CST, -06:00                          ', parentId: 3 },
    { title: 'Montevideo -03, -03:00                         ', parentId: 3 },
    { title: 'Montreal EDT, -04:00                           ', parentId: 3 },
    { title: 'Montserrat AST, -04:00                         ', parentId: 3 },
    { title: 'Nassau EDT, -04:00                             ', parentId: 3 },
    { title: 'New_York EDT, -04:00                           ', parentId: 3 },
    { title: 'Nipigon EDT, -04:00                            ', parentId: 3 },
    { title: 'Nome AKDT, -08:00                              ', parentId: 3 },
    { title: 'Noronha -02, -02:00                            ', parentId: 3 },
    { title: 'North_Dakota/Beulah CDT, -05:00                ', parentId: 3 },
    { title: 'North_Dakota/Center CDT, -05:00                ', parentId: 3 },
    { title: 'North_Dakota/New_Salem CDT, -05:00             ', parentId: 3 },
    { title: 'Nuuk -03, -03:00                               ', parentId: 3 },
    { title: 'Ojinaga MDT, -06:00                            ', parentId: 3 },
    { title: 'Panama EST, -05:00                             ', parentId: 3 },
    { title: 'Pangnirtung EDT, -04:00                        ', parentId: 3 },
    { title: 'Paramaribo -03, -03:00                         ', parentId: 3 },
    { title: 'Phoenix MST, -07:00                            ', parentId: 3 },
    { title: 'Port-au-Prince EDT, -04:00                     ', parentId: 3 },
    { title: 'Port_of_Spain AST, -04:00                      ', parentId: 3 },
    { title: 'Porto_Acre -05, -05:00                         ', parentId: 3 },
    { title: 'Porto_Velho -04, -04:00                        ', parentId: 3 },
    { title: 'Puerto_Rico AST, -04:00                        ', parentId: 3 },
    { title: 'Punta_Arenas -03, -03:00                       ', parentId: 3 },
    { title: 'Rainy_River CDT, -05:00                        ', parentId: 3 },
    { title: 'Rankin_Inlet CDT, -05:00                       ', parentId: 3 },
    { title: 'Recife -03, -03:00                             ', parentId: 3 },
    { title: 'Regina CST, -06:00                             ', parentId: 3 },
    { title: 'Resolute CDT, -05:00                           ', parentId: 3 },
    { title: 'Rio_Branco -05, -05:00                         ', parentId: 3 },
    { title: 'Rosario -03, -03:00                            ', parentId: 3 },
    { title: 'Santa_Isabel PDT, -07:00                       ', parentId: 3 },
    { title: 'Santarem -03, -03:00                           ', parentId: 3 },
    { title: 'Santiago -03, -03:00                           ', parentId: 3 },
    { title: 'Santo_Domingo AST, -04:00                      ', parentId: 3 },
    { title: 'Sao_Paulo -03, -03:00                          ', parentId: 3 },
    { title: 'Scoresbysund -01, -01:00                       ', parentId: 3 },
    { title: 'Shiprock MDT, -06:00                           ', parentId: 3 },
    { title: 'Sitka AKDT, -08:00                             ', parentId: 3 },
    { title: 'St_Barthelemy AST, -04:00                      ', parentId: 3 },
    { title: 'St_Johns NDT, -02:30                           ', parentId: 3 },
    { title: 'St_Kitts AST, -04:00                           ', parentId: 3 },
    { title: 'St_Lucia AST, -04:00                           ', parentId: 3 },
    { title: 'St_Thomas AST, -04:00                          ', parentId: 3 },
    { title: 'St_Vincent AST, -04:00                         ', parentId: 3 },
    { title: 'Swift_Current CST, -06:00                      ', parentId: 3 },
    { title: 'Tegucigalpa CST, -06:00                        ', parentId: 3 },
    { title: 'Thule ADT, -03:00                              ', parentId: 3 },
    { title: 'Thunder_Bay EDT, -04:00                        ', parentId: 3 },
    { title: 'Tijuana PDT, -07:00                            ', parentId: 3 },
    { title: 'Toronto EDT, -04:00                            ', parentId: 3 },
    { title: 'Tortola AST, -04:00                            ', parentId: 3 },
    { title: 'Vancouver PDT, -07:00                          ', parentId: 3 },
    { title: 'Virgin AST, -04:00                             ', parentId: 3 },
    { title: 'Whitehorse MST, -07:00                         ', parentId: 3 },
    { title: 'Winnipeg CDT, -05:00                           ', parentId: 3 },
    { title: 'Yakutat AKDT, -08:00                           ', parentId: 3 },
    { title: 'Yellowknife MDT, -06:00                        ', parentId: 3 },
    { title: 'Aden +03, +03:00 ', parentId: 4 },
    { title: 'Almaty +06, +06:00 ', parentId: 4 },
    { title: 'Amman EEST, +03:00 ', parentId: 4 },
    { title: 'Anadyr +12, +12:00 ', parentId: 4 },
    { title: 'Aqtau +05, +05:00 ', parentId: 4 },
    { title: 'Aqtobe +05, +05:00 ', parentId: 4 },
    { title: 'Ashgabat +05, +05:00 ', parentId: 4 },
    { title: 'Ashkhabad +05, +05:00 ', parentId: 4 },
    { title: 'Atyrau +05, +05:00 ', parentId: 4 },
    { title: 'Baghdad +03, +03:00 ', parentId: 4 },
    { title: 'Bahrain +03, +03:00 ', parentId: 4 },
    { title: 'Baku +04, +04:00 ', parentId: 4 },
    { title: 'Bangkok +07, +07:00 ', parentId: 4 },
    { title: 'Barnaul +07, +07:00 ', parentId: 4 },
    { title: 'Beirut EET, +02:00 ', parentId: 4 },
    { title: 'Bishkek +06, +06:00 ', parentId: 4 },
    { title: 'Brunei +08, +08:00 ', parentId: 4 },
    { title: 'Calcutta IST, +05:30 ', parentId: 4 },
    { title: 'Chita +09, +09:00 ', parentId: 4 },
    { title: 'Choibalsan +08, +08:00 ', parentId: 4 },
    { title: 'Chongqing CST, +08:00 ', parentId: 4 },
    { title: 'Chungking CST, +08:00 ', parentId: 4 },
    { title: 'Colombo +0530, +05:30 ', parentId: 4 },
    { title: 'Dacca +06, +06:00 ', parentId: 4 },
    { title: 'Damascus EEST, +03:00 ', parentId: 4 },
    { title: 'Dhaka +06, +06:00 ', parentId: 4 },
    { title: 'Dili +09, +09:00 ', parentId: 4 },
    { title: 'Dubai +04, +04:00 ', parentId: 4 },
    { title: 'Dushanbe +05, +05:00 ', parentId: 4 },
    { title: 'Famagusta EET, +02:00 ', parentId: 4 },
    { title: 'Gaza EEST, +03:00 ', parentId: 4 },
    { title: 'Harbin CST, +08:00 ', parentId: 4 },
    { title: 'Hebron EEST, +03:00 ', parentId: 4 },
    { title: 'Ho_Chi_Minh +07, +07:00 ', parentId: 4 },
    { title: 'Hong_Kong HKT, +08:00 ', parentId: 4 },
    { title: 'Hovd +07, +07:00 ', parentId: 4 },
    { title: 'Irkutsk +08, +08:00 ', parentId: 4 },
    { title: 'Istanbul +03, +03:00 ', parentId: 4 },
    { title: 'Jakarta WIB, +07:00 ', parentId: 4 },
    { title: 'Jayapura WIT, +09:00 ', parentId: 4 },
    { title: 'Jerusalem IST, +02:00 ', parentId: 4 },
    { title: 'Kabul +0430, +04:30 ', parentId: 4 },
    { title: 'Kamchatka +12, +12:00 ', parentId: 4 },
    { title: 'Karachi PKT, +05:00 ', parentId: 4 },
    { title: 'Kashgar +06, +06:00 ', parentId: 4 },
    { title: 'Kathmandu +0545, +05:45 ', parentId: 4 },
    { title: 'Katmandu +0545, +05:45 ', parentId: 4 },
    { title: 'Khandyga +09, +09:00 ', parentId: 4 },
    { title: 'Kolkata IST, +05:30 ', parentId: 4 },
    { title: 'Krasnoyarsk +07, +07:00 ', parentId: 4 },
    { title: 'Kuala_Lumpur +08, +08:00 ', parentId: 4 },
    { title: 'Kuching +08, +08:00 ', parentId: 4 },
    { title: 'Kuwait +03, +03:00 ', parentId: 4 },
    { title: 'Macao CST, +08:00 ', parentId: 4 },
    { title: 'Macau CST, +08:00 ', parentId: 4 },
    { title: 'Magadan +11, +11:00 ', parentId: 4 },
    { title: 'Makassar WITA, +08:00 ', parentId: 4 },
    { title: 'Manila PST, +08:00 ', parentId: 4 },
    { title: 'Muscat +04, +04:00 ', parentId: 4 },
    { title: 'Nicosia EET, +02:00 ', parentId: 4 },
    { title: 'Novokuznetsk +07, +07:00 ', parentId: 4 },
    { title: 'Novosibirsk +07, +07:00 ', parentId: 4 },
    { title: 'Omsk +06, +06:00 ', parentId: 4 },
    { title: 'Oral +05, +05:00 ', parentId: 4 },
    { title: 'Phnom_Penh +07, +07:00 ', parentId: 4 },
    { title: 'Pontianak WIB, +07:00 ', parentId: 4 },
    { title: 'Pyongyang KST, +09:00 ', parentId: 4 },
    { title: 'Qatar +03, +03:00 ', parentId: 4 },
    { title: 'Qostanay +06, +06:00 ', parentId: 4 },
    { title: 'Qyzylorda +05, +05:00 ', parentId: 4 },
    { title: 'Rangoon +0630, +06:30 ', parentId: 4 },
    { title: 'Riyadh +03, +03:00 ', parentId: 4 },
    { title: 'Saigon +07, +07:00 ', parentId: 4 },
    { title: 'Sakhalin +11, +11:00 ', parentId: 4 },
    { title: 'Samarkand +05, +05:00 ', parentId: 4 },
    { title: 'Seoul KST, +09:00 ', parentId: 4 },
    { title: 'Shanghai CST, +08:00 ', parentId: 4 },
    { title: 'Singapore +08, +08:00 ', parentId: 4 },
    { title: 'Srednekolymsk +11, +11:00 ', parentId: 4 },
    { title: 'Taipei CST, +08:00 ', parentId: 4 },
    { title: 'Tashkent +05, +05:00 ', parentId: 4 },
    { title: 'Tbilisi +04, +04:00 ', parentId: 4 },
    { title: 'Tehran +0330, +03:30 ', parentId: 4 },
    { title: 'Tel_Aviv IST, +02:00 ', parentId: 4 },
    { title: 'Thimbu +06, +06:00 ', parentId: 4 },
    { title: 'Thimphu +06, +06:00 ', parentId: 4 },
    { title: 'Tokyo JST, +09:00 ', parentId: 4 },
    { title: 'Tomsk +07, +07:00 ', parentId: 4 },
    { title: 'Ujung_Pandang WITA, +08:00 ', parentId: 4 },
    { title: 'Ulaanbaatar +08, +08:00 ', parentId: 4 },
    { title: 'Ulan_Bator +08, +08:00 ', parentId: 4 },
    { title: 'Urumqi +06, +06:00 ', parentId: 4 },
    { title: 'Ust-Nera +10, +10:00 ', parentId: 4 },
    { title: 'Vientiane +07, +07:00 ', parentId: 4 },
    { title: 'Vladivostok +10, +10:00 ', parentId: 4 },
    { title: 'Yakutsk +09, +09:00 ', parentId: 4 },
    { title: 'Yangon +0630, +06:30 ', parentId: 4 },
    { title: 'Yekaterinburg +05, +05:00 ', parentId: 4 },
    { title: 'Yerevan +04, +04:00 ', parentId: 4 },
    { title: 'Azores -01, -01:00', parentId: 5 },
    { title: 'Bermuda ADT, -03:00', parentId: 5 },
    { title: 'Canary WET, +00:00', parentId: 5 },
    { title: 'Cape_Verde -01, -01:00', parentId: 5 },
    { title: 'Faeroe WET, +00:00', parentId: 5 },
    { title: 'Faroe WET, +00:00', parentId: 5 },
    { title: 'Jan_Mayen CET, +01:00', parentId: 5 },
    { title: 'Madeira WET, +00:00', parentId: 5 },
    { title: 'Reykjavik GMT, +00:00', parentId: 5 },
    { title: 'South_Georgia -02, -02:00', parentId: 5 },
    { title: 'St_Helena GMT, +00:00', parentId: 5 },
    { title: 'Stanley -03, -03:00', parentId: 5 },
    { title: 'ACT AEDT, +11:00', parentId: 6 },
    { title: 'Adelaide ACDT, +10:30', parentId: 6 },
    { title: 'Brisbane AEST, +10:00', parentId: 6 },
    { title: 'Broken_Hill ACDT, +10:30', parentId: 6 },
    { title: 'Canberra AEDT, +11:00', parentId: 6 },
    { title: 'Currie AEDT, +11:00', parentId: 6 },
    { title: 'Darwin ACST, +09:30', parentId: 6 },
    { title: 'Eucla +0845, +08:45', parentId: 6 },
    { title: 'Hobart AEDT, +11:00', parentId: 6 },
    { title: 'LHI +11, +11:00', parentId: 6 },
    { title: 'Lindeman AEST, +10:00', parentId: 6 },
    { title: 'Lord_Howe +11, +11:00', parentId: 6 },
    { title: 'Melbourne AEDT, +11:00', parentId: 6 },
    { title: 'NSW AEDT, +11:00', parentId: 6 },
    { title: 'North ACST, +09:30', parentId: 6 },
    { title: 'Perth AWST, +08:00', parentId: 6 },
    { title: 'Queensland AEST, +10:00', parentId: 6 },
    { title: 'South ACDT, +10:30', parentId: 6 },
    { title: 'Sydney AEDT, +11:00', parentId: 6 },
    { title: 'Tasmania AEDT, +11:00', parentId: 6 },
    { title: 'Victoria AEDT, +11:00', parentId: 6 },
    { title: 'West AWST, +08:00', parentId: 6 },
    { title: 'Yancowinna ACDT, +10:30', parentId: 6 },
    { title: 'Atlantic ADT, -03:00', parentId: 7 },
    { title: 'Central CDT, -05:00', parentId: 7 },
    { title: 'Eastern EDT, -04:00', parentId: 7 },
    { title: 'Mountain MDT, -06:00', parentId: 7 },
    { title: 'Newfoundland NDT, -02:30', parentId: 7 },
    { title: 'Pacific PDT, -07:00', parentId: 7 },
    { title: 'Saskatchewan CST, -06:00', parentId: 7 },
    { title: 'Yukon MST, -07:00', parentId: 7 },

    { title: 'Amsterdam CET, +01:00', parentId: 8 },
    { title: 'Andorra CET, +01:00', parentId: 8 },
    { title: 'Astrakhan +04, +04:00', parentId: 8 },
    { title: 'Athens EET, +02:00', parentId: 8 },
    { title: 'Belfast GMT, +00:00', parentId: 8 },
    { title: 'Belgrade CET, +01:00', parentId: 8 },
    { title: 'Berlin CET, +01:00', parentId: 8 },
    { title: 'Bratislava CET, +01:00', parentId: 8 },
    { title: 'Brussels CET, +01:00', parentId: 8 },
    { title: 'Bucharest EET, +02:00', parentId: 8 },
    { title: 'Budapest CET, +01:00', parentId: 8 },
    { title: 'Busingen CET, +01:00', parentId: 8 },
    { title: 'Chisinau EET, +02:00', parentId: 8 },
    { title: 'Copenhagen CET, +01:00', parentId: 8 },
    { title: 'Dublin GMT, +00:00', parentId: 8 },
    { title: 'Gibraltar CET, +01:00', parentId: 8 },
    { title: 'Guernsey GMT, +00:00', parentId: 8 },
    { title: 'Helsinki EET, +02:00', parentId: 8 },
    { title: 'Isle_of_Man GMT, +00:00', parentId: 8 },
    { title: 'Istanbul +03, +03:00', parentId: 8 },
    { title: 'Jersey GMT, +00:00', parentId: 8 },
    { title: 'Kaliningrad EET, +02:00', parentId: 8 },
    { title: 'Kiev EET, +02:00', parentId: 8 },
    { title: 'Kirov +03, +03:00', parentId: 8 },
    { title: 'Lisbon WET, +00:00', parentId: 8 },
    { title: 'Ljubljana CET, +01:00', parentId: 8 },
    { title: 'London GMT, +00:00', parentId: 8 },
    { title: 'Luxembourg CET, +01:00', parentId: 8 },
    { title: 'Madrid CET, +01:00', parentId: 8 },
    { title: 'Malta CET, +01:00', parentId: 8 },
    { title: 'Mariehamn EET, +02:00', parentId: 8 },
    { title: 'Minsk +03, +03:00', parentId: 8 },
    { title: 'Monaco CET, +01:00', parentId: 8 },
    { title: 'Moscow MSK, +03:00', parentId: 8 },
    { title: 'Nicosia EET, +02:00', parentId: 8 },
    { title: 'Oslo CET, +01:00', parentId: 8 },
    { title: 'Paris CET, +01:00', parentId: 8 },
    { title: 'Podgorica CET, +01:00', parentId: 8 },
    { title: 'Prague CET, +01:00', parentId: 8 },
    { title: 'Riga EET, +02:00', parentId: 8 },
    { title: 'Rome CET, +01:00', parentId: 8 },
    { title: 'Samara +04, +04:00', parentId: 8 },
    { title: 'San_Marino CET, +01:00', parentId: 8 },
    { title: 'Sarajevo CET, +01:00', parentId: 8 },
    { title: 'Saratov +04, +04:00', parentId: 8 },
    { title: 'Simferopol MSK, +03:00', parentId: 8 },
    { title: 'Skopje CET, +01:00', parentId: 8 },
    { title: 'Sofia EET, +02:00', parentId: 8 },
    { title: 'Stockholm CET, +01:00', parentId: 8 },
    { title: 'Tallinn EET, +02:00', parentId: 8 },
    { title: 'Tirane CET, +01:00', parentId: 8 },
    { title: 'Tiraspol EET, +02:00', parentId: 8 },
    { title: 'Ulyanovsk +04, +04:00', parentId: 8 },
    { title: 'Uzhgorod EET, +02:00', parentId: 8 },
    { title: 'Vaduz CET, +01:00', parentId: 8 },
    { title: 'Vatican CET, +01:00', parentId: 8 },
    { title: 'Vienna CET, +01:00', parentId: 8 },
    { title: 'Vilnius EET, +02:00', parentId: 8 },
    { title: 'Volgograd +04, +04:00', parentId: 8 },
    { title: 'Warsaw CET, +01:00', parentId: 8 },
    { title: 'Zagreb CET, +01:00', parentId: 8 },
    { title: 'Zaporozhye EET, +02:00', parentId: 8 },
    { title: 'Zurich CET, +01:00', parentId: 8 },
    { title: 'Antananarivo EAT, +03:00', parentId: 9 },
    { title: 'Chagos +06, +06:00', parentId: 9 },
    { title: 'Christmas +07, +07:00', parentId: 9 },
    { title: 'Cocos +0630, +06:30', parentId: 9 },
    { title: 'Comoro EAT, +03:00', parentId: 9 },
    { title: 'Kerguelen +05, +05:00', parentId: 9 },
    { title: 'Mahe +04, +04:00', parentId: 9 },
    { title: 'Maldives +05, +05:00', parentId: 9 },
    { title: 'Mauritius +04, +04:00', parentId: 9 },
    { title: 'Mayotte EAT, +03:00', parentId: 9 },
    { title: 'Reunion +04, +04:00', parentId: 9 },
    { title: 'Auckland NZDT, +13:00                          ', parentId: 10 },
    { title: 'Bougainville +11, +11:00                          ', parentId: 10 },
    { title: 'Chatham +1345, +13:45                          ', parentId: 10 },
    { title: 'Chuuk +10, +10:00                          ', parentId: 10 },
    { title: 'Easter -05, -05:00                          ', parentId: 10 },
    { title: 'Efate +11, +11:00                          ', parentId: 10 },
    { title: 'Enderbury +13, +13:00                          ', parentId: 10 },
    { title: 'Fakaofo +13, +13:00                          ', parentId: 10 },
    { title: 'Fiji +12, +12:00                          ', parentId: 10 },
    { title: 'Funafuti +12, +12:00                          ', parentId: 10 },
    { title: 'Galapagos -06, -06:00                          ', parentId: 10 },
    { title: 'Gambier -09, -09:00                          ', parentId: 10 },
    { title: 'Guadalcanal +11, +11:00                          ', parentId: 10 },
    { title: 'Guam ChST, +10:00                          ', parentId: 10 },
    { title: 'Honolulu HST, -10:00                          ', parentId: 10 },
    { title: 'Johnston HST, -10:00                          ', parentId: 10 },
    { title: 'Kiritimati +14, +14:00                          ', parentId: 10 },
    { title: 'Kosrae +11, +11:00                          ', parentId: 10 },
    { title: 'Kwajalein +12, +12:00                          ', parentId: 10 },
    { title: 'Majuro +12, +12:00                          ', parentId: 10 },
    { title: 'Marquesas -0930, -09:30                          ', parentId: 10 },
    { title: 'Midway SST, -11:00                          ', parentId: 10 },
    { title: 'Nauru +12, +12:00                          ', parentId: 10 },
    { title: 'Niue -11, -11:00                          ', parentId: 10 },
    { title: 'Norfolk +12, +12:00                          ', parentId: 10 },
    { title: 'Noumea +11, +11:00                          ', parentId: 10 },
    { title: 'Pago_Pago SST, -11:00                          ', parentId: 10 },
    { title: 'Palau +09, +09:00                          ', parentId: 10 },
    { title: 'Pitcairn -08, -08:00                          ', parentId: 10 },
    { title: 'Pohnpei +11, +11:00                          ', parentId: 10 },
    { title: 'Ponape +11, +11:00                          ', parentId: 10 },
    { title: 'Port_Moresby +10, +10:00                          ', parentId: 10 },
    { title: 'Rarotonga -10, -10:00                          ', parentId: 10 },
    { title: 'Saipan ChST, +10:00                          ', parentId: 10 },
    { title: 'Samoa SST, -11:00                          ', parentId: 10 },
    { title: 'Tahiti -10, -10:00                          ', parentId: 10 },
    { title: 'Tarawa +12, +12:00                          ', parentId: 10 },
    { title: 'Tongatapu +13, +13:00                          ', parentId: 10 },
    { title: 'Truk +10, +10:00                          ', parentId: 10 },
    { title: 'Wake +12, +12:00                          ', parentId: 10 },
    { title: 'Wallis +12, +12:00                          ', parentId: 10 },
    { title: 'Yap +10, +10:00                          ', parentId: 10 },
  ]

  timezonelist: any;
  task: AngularFireUploadTask;
  downloadableURL = '';
  completedsessioncount: number = 0;
  timelinessScore: any = 100;
  imageSrc: string = "../../../assets/avtar.png";
  tooltipmsg: any;
  que_first: any;
  que_second: any;
  que_third: any;

  ans_first: any = null;
  ans_second: any = null;
  third: any;
  ans_third: any = [];

  editonboarding: boolean = false;
  disabled: boolean = true;
  firsttask: any;
  secondTask: any;
  thirdTask: any;
  removed_task: any = [];
  constructor(
    public authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public router: Router,
    public afs: AngularFirestore,   // Inject Firestore service
    private fireStorage: AngularFireStorage,
    public afAuth: AngularFireAuth, // Inject Firebase auth service
  ) { }

  ngOnInit(): void {
    this.currentUID = this.activatedRoute.snapshot.paramMap.get('uid');
    var data = JSON.parse(localStorage.getItem('user'));
    if (data.uid == this.currentUID) {
      this.userDetials = data;
    }
    if (this.userDetials.photoURL) {
      this.imageSrc = this.userDetials.photoURL;
    }
    this.profileForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      photoURL: [''],
      uid: [''],
      emailVerified: [''],
      region: [''],
      timezone: [''],
      creationDate: [''],
      regionwithtimezone: ['']
    });
    if (this.userDetials.timezone != null && this.userDetials.timezone != "") {
      var parentId = this.region.filter(x => x.title == this.userDetials.region);
      this.timezonelist = this.timezone.filter(item => item.parentId == parentId[0].id);
    }
    this.ProfileInfo();
    this.OnboardingQuestion_Ansewer();
  }

  get f() {
    return this.profileForm.controls;;
  }

  selectRegion(e) {
    var parentId = this.region.filter(x => x.title == e.target.value)
    this.timezonelist = this.timezone.filter(item => item.parentId == parentId[0].id);
    this.profileForm.patchValue({ region: e.target.value });
  }

  filterSubById(id) {
    return this.timezone.filter(item => item.parentId === id);
  }

  selectTimezone(e) {
    this.profileForm.patchValue({ timezone: e.target.value });
    //alert(e.target.value);
  }

  editprofiledetails() {
    this.editprofile = true;
    this.profileForm.patchValue({
      firstname: this.userDetials.firstname,
      lastname: this.userDetials.lastname,
      email: this.userDetials.email,
      region: this.userDetials.region,
      timezone: this.userDetials.timezone,
      creationDate: this.userDetials.creationDate,
      regionwithtimezone: this.userDetials.regionwithtimezone
    });
  }

  cancelEdit() {
    this.editprofile = false;
    this.submitted = false;
    this.profileForm.reset();
  }

  onSubmit() {
    this.submitted = true;
    this.profileForm.value.uid = this.userDetials.uid;
    this.profileForm.value.emailVerified = this.userDetials.emailVerified;
    this.profileForm.value.photoURL = this.imageSrc;
    if (this.profileForm.invalid) {
      return;
    }
    this.authService.updateData(this.profileForm.value);
  }

  async handleFileInput(event) {
    const file = event.target.files[0];
    if (file) {
      const filePath = `images/${file.name}`;
      this.task = this.fireStorage.upload(filePath, file);
      (await this.task).ref.getDownloadURL().then(url => {
        this.downloadableURL = url;
        this.imageSrc = url;
        this.profileForm.patchValue({
          photoURL: url
        });
      });  // <<< url is found here
    } else {
      alert('No images selected');
      this.downloadableURL = '';
    }
  }

  delete_User() {
    this.afAuth.currentUser.then(rs => {
      rs.delete().then((res: any) => {
        this.afs.collection('tasks', ref => ref.where('userId', '==', this.currentUID)).get().subscribe((querySnapshot) => {
          if (querySnapshot.size > 0) {
            querySnapshot.forEach((doc: any) => {
              doc.ref.delete();
            });
          }
        });
        this.afs.collection('tasks', ref => ref.where('joinuserflaguserid', '==', this.currentUID)).get().subscribe((querySnapshot) => {
          if (querySnapshot.size > 0) {
            querySnapshot.forEach((doc: any) => {
              this.afs.collection('tasks').doc(doc.id).update({ joinuserflaguserid: '', joinuserflagtaskid: '', joinuserflag: 'false', bookedManual: 'false' })
            });
          }
        });
        this.afs.collection('users', ref => ref.where('uid', '==', this.currentUID)).get().subscribe((querySnapshot) => {
          querySnapshot.forEach((doc: any) => {
            doc.ref.delete();
          });
        });
        var userdata = JSON.parse(localStorage.getItem('user'));
        this.authService.delete_User(userdata);
        localStorage.setItem("user_delete", "User deleted Successfully");
      }).catch(error => {
        this.errorEmail = error.message;
      });
    }).catch(error => {
      console.error(error);
    });
    this.closeModal.nativeElement.click();
  }

  async GetUserById(uid) {
    var object = this;
    this.afs.collection('users', ref => ref.where('uid', '==', uid)).get().subscribe((querySnapshot) => {
      querySnapshot.forEach((doc: any) => {
        const userData: User = {
          uid: doc.data().uid,
          firstname: doc.data().firstname,
          lastname: doc.data().lastname,
          email: doc.data().email,
          displayName: doc.data().displayName,
          photoURL: doc.data().photoURL,
          emailVerified: doc.data().emailVerified,
          region: doc.data().region,
          timezone: doc.data().timezone,
          creationDate: doc.data().creationDate,
          regionwithtimezone: doc.data().regionwithtimezone
        }
        return userData;
      });
    });

  }

  ProfileInfo() {
    this.afs.collection("completed-session").doc(this.currentUID).get().subscribe((document: any) => {
      let completed_session_count: number = 0;
      if (document.exists) {
        completed_session_count = parseInt(document.data()["completed_session_count"]);
        this.completedsessioncount = (completed_session_count > 0) ? completed_session_count : 0;
      } else {
        this.completedsessioncount = 0;
      }
    });
    this.afs.collection("timeliness-score").doc(this.currentUID).get().subscribe((document: any) => {
      let score_count: number = 0;
      let score: number;
      if (document.exists) {
        score_count = parseInt(document.data()["score_count"]);
        score = (score_count > 0) ? score_count : 0;
      } else {
        score = 0;
      }
      if (score == 0 || this.completedsessioncount == 0) {
        this.timelinessScore = 0;
      }
      else {
        this.timelinessScore = Math.round(((score / this.completedsessioncount) * 100));
      }
      this.tooltipmsg = "The percentage of your last " + this.completedsessioncount + " sessions that you joined less than 5min late. Only you can see this score."
      //   console.log(score, this.completedsessioncount, ((score / this.completedsessioncount) * 100), typeof ((score / this.completedsessioncount) * 100), typeof this.timelinessScore)
    });
  }

  OnboardingQuestion_Ansewer() {
    this.removed_task = [];
    this.third = [];
    this.afs.collection("onboarding-questions").doc("joe59AhpUaeAn3keag58").get().subscribe(async (doc) => {
      if (doc.exists) {
        if (doc.data().hasOwnProperty("first") && doc.data()["first"]) {
          this.que_first = doc.data()["first"];
        }
        if (doc.data().hasOwnProperty("second") && doc.data()["second"]) {
          this.que_second = doc.data()["second"];
        }
        if (doc.data().hasOwnProperty("third") && doc.data()["third"]) {
          this.que_third = doc.data()["third"];
        }
        this.afs.collection("onboarding-answer").doc(this.currentUID).get().subscribe(async (document: any) => {
          if (document.exists) {
            if (document.data().hasOwnProperty("first") && document.data()["first"]) {
              this.ans_first = document.data()["first"];
            }
            if (document.data().hasOwnProperty("second") && document.data()["second"]) {
              this.ans_second = document.data()["second"];
            }
            if (document.data().hasOwnProperty("third") && document.data()["third"]) {
              this.third = document.data()["third"];
              for (let index = 0; index < this.third.length; index++) {
                const element = this.third[index];
                this.afs.collection('remaindertasks', ref => ref.where('taskId', '==', element)).get().subscribe((Snapshot) => {
                  // console.log(Snapshot.size == 0 ? "HI" : "Es")
                  if (Snapshot.size == 0) {
                    this.removed_task.push(element);
                  }
                  Snapshot.forEach((userdoc: any) => {
                    this.ans_third.push(userdoc.data());
                    if (index == 0) {
                      this.firsttask = userdoc.data()["taskName"]
                    } else if (index == 1) {
                      this.secondTask = userdoc.data()["taskName"]
                    } else if (index == 2) {
                      this.thirdTask = userdoc.data()["taskName"]
                    }
                  });
                });
              }

              // setTimeout(() => {
              // console.log(this.third, this.removed_task, this.except(this.third, this.removed_task));
              //   this.afs.doc('onboarding-answer' + '/' + this.currentUID).update({ third: this.except(this.third, this.removed_task) }).then(() => {
              //     this.editonboarding = false;
              //     this.OnboardingQuestion_Ansewer();
              //   });
              // }, 1500);
            }
          }
        });
      }
    });
  }

  validate() {
    if ((<HTMLInputElement>document.getElementById("secondAns")).value.length > 0) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }

  }

  SubmitAll() {
    this.ans_first;
    let second_answer = (<HTMLInputElement>document.getElementById("secondAns")).value;
    for (let index = 0; index < this.third.length; index++) {
      const element = this.third[index];
      let answer = (<HTMLInputElement>document.getElementById("task" + index)).value;
      if (answer.length === 0) {
        $("#error" + index).removeClass("err");
        return;
      } else {
        if (!$("#error" + index).hasClass("err")) {
          $("#error" + index).addClass("err");
        }

      }
      this.afs.doc('remaindertasks' + '/' + element).update({ taskName: answer });
    }
    this.afs.doc('onboarding-answer' + '/' + this.currentUID).update({ first: this.ans_first, second: second_answer }).then(() => {
      this.editonboarding = false;
      this.OnboardingQuestion_Ansewer();
    });

  }

  selectValue(obj) {
    if (obj.target.checked) {
      this.ans_first = obj.target.defaultValue;
    }
  }

  EditAnswers() {
    if (this.editonboarding) {
      this.editonboarding = false;
    } else {
      this.editonboarding = true;
    }
  }

  except(array, excluded) {
    var check1 = array.filter(function (value) {
      return excluded.indexOf(value) == -1;
    });

    var check2 = excluded.filter(function (value) {
      return array.indexOf(value) == -1;
    });

    var output = check1.concat(check2);
    return output;

  }
  mouseEnter(){
    $("#tooltip14682").show();
  }
  mouseLeave(){
    $("#tooltip14682").hide();
  }

  //tooltip14682
}
