import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SecureInnerPagesGuard implements CanActivate {

  constructor(public authService: AuthService,public router: Router){ }


  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      console.log("SecureInnerPagesGuard");
      // if(this.authService.isLoggedIn) {
      //  // console.log("You are already signed in, access denied!");
      //    // this.router.navigate(['calendar-dashboard']);
      // }
      return true;
  }

}
