import { Component, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-new-home-template',
  templateUrl: './new-home-template.component.html',
  styleUrls: ['./new-home-template.component.css']
})
export class NewHomeTemplateComponent implements OnInit {

  constructor() {  }

  ngOnInit(): void {
    $(document).ready(function() { 
     
      // var promise = document.querySelector('video').play();

      // if (promise !== undefined) {
      //   promise.then(_ => {
      //     // Autoplay started!
      //     console.log('---ok----')
      //   }).catch(error => {
      //     console.log('---error----', error)
      //     // Autoplay was prevented.
      //     // Show a "Play" button so that user can start playback.
      //   });
      // }

      setTimeout(function(){
      $("#pf-members").owlCarousel({
        autoplay: 3000, //Set AutoPlay to 3 seconds
        items : 3,
        itemsDesktop : [1199,3],
        itemsDesktopSmall : [979,1],
        responsiveClass: true,
        responsive: {
          0:{
          items: 1
          },
          480:{
          items: 1
          },
          769:{
          items: 3
          }
        }
      });
      $("#pf-testimony").owlCarousel({
        autoplay: 500, //Set AutoPlay to 3 seconds
        items : 3,
        loop: true,
        responsiveClass: true,
        responsive: {
          0:{
          items: 1
          },
          480:{
          items: 1
          },
          769:{
          items: 3
          }
        }
      });
    },2000);
    });
  }

  playPause(){
    (document.getElementById('videobg') as HTMLMediaElement).play();
  }
  
}
