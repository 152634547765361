import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../services/auth.service'
import { AwsChimeService } from '../../services/aws-chime/aws-chime.service'
import { AsyncScheduler, AudioVideoFacade, ConsoleLogger, DefaultModality, DefaultDeviceController, DefaultMeetingSession, LogLevel, MeetingSessionConfiguration, MeetingSessionStatusCode } from 'amazon-chime-sdk-js';
import { Router } from '@angular/router';
import { Admission, DataEvent } from '../model/admission'
import { CommonService } from '../../services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-chimemeeting',
  templateUrl: './awsChime.component.html',
  styleUrls: ['./awsChime.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AwsChimeComponent implements OnInit, OnDestroy {

  @ViewChild('openBlockUserModal') openBlockUserModal: ElementRef;
  @ViewChild('CloseBlockUserModal') CloseBlockUserModal: ElementRef;
  @ViewChild('deleteopenModal') deleteopenModal: ElementRef;
  @ViewChild('deleteCloseModal') deleteCloseModal: ElementRef;
  @ViewChild('taskeventdetailopenModal') taskeventdetailopenModal: ElementRef;
  @ViewChild('closeModal') closeModal: ElementRef;

  currentUser: any;
  totaltaskList: any[] = [];
  outputText: string;
  admissions: Admission;
  meeting: any;
  meeting_id: any;
  attendee: any;
  meetingSession: DefaultMeetingSession;
  audioVideo: AudioVideoFacade;
  documentId: any;
  oldDocumentID: any;
  activeMeetingId: any;
  private startVideo: boolean = false;
  cameralabel: any = "Turn off";
  attendees: any = {};
  selfAttendeeId: any;
  notificationCount: any = 0;
  static readonly UNMUTE = "unmute";
  static readonly MUTE = "mute";
  static readonly PRIVATEMESSAGE = "privatemessage";
  static readonly BLOCKPARTNER = "blockpartner";
  static readonly DATA_MESSAGE_LIFETIME_MS = 30000;
  calldestroy: boolean = false;
  taskId: string = ""
  attendeeMuteStatus: string = "MUTE";
  loader: boolean = true;
  taskEndtime: any;
  taskStartTime: any;
  markmeetingEnd: boolean = false;
  toggle: boolean = true;
  togglemaster: boolean = true;
  showPartnerDetails: boolean = false;
  partnerId: any;
  partnerDisplayName: any = "";
  partnerProfileImage: any;
  partnercompletedsessioncount: any;
  lateMinutes: number = 0;
  deleteTask: any;
  taskName: any;
  taskForDetails: any = [{ taskName: '', taskTime: '', taskDate: '', days: '', taskDuration: '', durationmin: '' }];
  TaskModalTitle: string;
  taskForm: FormGroup;
  submitted: boolean = false;
  items = ['1', '3', '5', '10', '15', '30', '45'];
  endtimeCounter: any;
  timer: any;
  options = {
    'elements': { 'audio': 'meeting-audio' },
    'callbackStart': () => { },
    callbackFailMeetingStart: (e: any) => { }
  };

  public activeSpeakerListener: any;

  constructor(
    public afs: AngularFirestore,
    public authService: AuthService,
    private aswservice: AwsChimeService,
    private router: Router,
    private commonser: CommonService,
    private formBuilder: FormBuilder
  ) {
    let obj = this;
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.getTop3Prioritylist();
    this.taskId = localStorage.getItem("taskId");
    $("#self-camera-off-icon").show();
    $("#self-camera-icon").hide();
    new AsyncScheduler().start(async () => {
      await this.JoinMeeting();
      await this.aws_meeting();
    });
    this.taskEndtime = localStorage.getItem("taskEndTime");
    this.taskStartTime = localStorage.getItem("taskStartTime");
    this.partnerId = localStorage.getItem("taskpartner");
    let startdatetime = new Date(this.taskStartTime);
    let currentdatetime = new Date();
    let diff = currentdatetime.getTime() - startdatetime.getTime();
    this.lateMinutes = Math.floor(diff / 60000);
    let res = Math.abs(currentdatetime.getTime() - startdatetime.getTime()) / 1000;
    let minutes = Math.floor(res / 60) % 60;
    let seconds = Math.floor(res % 60);
    seconds = Math.floor(diff % 60);
    let timer2 = 50 - minutes + ":" + seconds;
    let countGo: number = 0;
    let interval = setInterval(function () {
      let stDateTime = new Date(obj.taskStartTime);
      let currDateTime = new Date();
      let difference = currDateTime.getTime() - stDateTime.getTime();
      if (Math.floor(difference / 60000) <= 0 && countGo == 0) {
        $('#countdown').html("00:00");
        if (Math.floor(difference / 60000) == 0) {
          countGo++;
          timer2 = "50:59";
        }
        return false;
      }

      let timer = timer2.split(':');
      let minutes = parseInt(timer[0], 10);
      let seconds = parseInt(timer[1], 10);
      --seconds;
      minutes = (seconds < 0) ? --minutes : minutes;
      if (minutes < 0) clearInterval(interval);
      seconds = (seconds < 0) ? 59 : seconds;
      seconds = (seconds < 10) ? seconds : seconds;
      let secd = (seconds < 10) ? '0' + seconds : seconds;
      let min = (minutes < 10) ? '0' + minutes : minutes;
      if (minutes < 0) { $('#countdown').html("00:00"); } else { $('#countdown').html(min + ':' + secd); }

      timer2 = minutes + ':' + seconds;
      if (minutes == 0 && seconds == 0) {
        let audio = document.getElementById("audio-notification") as HTMLAudioElement;
        audio.play();
        obj.commonser.Toaster("success", "The session is about to end in 10 minutes. Start a discussion with your partner and share if you have achieved your set targets.")
      }
    }, 1000);

    setInterval(() => {
      if (!this.markmeetingEnd && !this.calldestroy) {
        obj.TimeDifference();
      }
    }, 1000);

    if (this.partnerId !== "") {
      this.ProfileInfo(this.partnerId);
      this.showPartnerDetails = true;
    }
    if (this.lateMinutes >= 0 && this.lateMinutes <= 2) {
      this.afs.collection("timeliness-score").doc(this.currentUser.uid).get().subscribe((document: any) => {
        let score_count: number = 0;
        if (document.exists) {
          score_count = parseInt(document.data()["score_count"]);
          score_count = (score_count > 0) ? score_count + 1 : 1;
        } else {
          score_count = 1;
        }
        this.afs.collection("timeliness-score").doc(this.currentUser.uid).set({ 'score_count': score_count })
      });
    }

  }

  ngOnInit() {
    $(document).on("click", '#copybuttontext', function (e) {
      e.preventDefault();
      let outputText = "";
      let targets = document.getElementsByClassName('copytext');
      for (let i = 0; i < targets.length; i++) {
        outputText = outputText + ' ' + targets[i].innerHTML;
      }
      let output = document.getElementById('output');
      output.innerText = outputText;
      let range = document.createRange();
      range.selectNodeContents(output);
      let selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
      output.style.display = 'none';
      this.commonser.Toaster("success", "Text Copied!")
    })
    this.taskForm = this.formBuilder.group({
      userId: [this.currentUser.uid],
      taskName: ['', [Validators.required]],
      date: [''],
      duration: ['', [Validators.required]],
      days: [''],
      dateTime: [''],
      durationmin: ['']
    });
    let object = this;
    $(document).ready(function () {
      let tomorrow = new Date();
      $('#datetimepicker1').datetimepicker({
        format: 'DD-MM-YYYY',
        showClear: true,
        showClose: true,
        showTodayButton: true,
        minDate: tomorrow
      }).on("dp.change", function () {
        object.taskForm.patchValue({ date: $("#datetimepicker1").val() });
      }).on("dp.update", function () { });
    });
  }

  ngOnDestroy() { this.RemoveAttendeeOnLeaveMeeting(); this.calldestroy = true; }

  HandleChat() {
    if ($('.session-video').hasClass('rp-open')) {
      if ($('#pills-profile-tab').hasClass("active")) {
        $('.session-video').removeClass('rp-open');
      }
    } else {
      $('.session-video').addClass('rp-open');
      this.notificationCount = 0;
    }

    $('#pills-profile-tab').click();

  }

  HandleTask() {
    if ($('.session-video').hasClass('rp-open')) {
      if ($('#pills-home-tab').hasClass("active")) {
        $('.session-video').removeClass('rp-open');
      }
    } else {
      $('.session-video').addClass('rp-open');
    }
    $('#pills-home-tab').click();
  }

  async getTop3Prioritylist() {
    this.totaltaskList = [];
    let object = this;
    await this.afs.collection('remaindertasks', ref => ref.where('userId', '==', this.currentUser.uid).orderBy('postionIndex', 'asc')).get().subscribe((querySnapshot) => {
      querySnapshot.forEach((doc: any) => {
        let date_new = doc.data().dueDate !== null ? doc.data().dueDate.split('-') : null;
        object.totaltaskList.push({
          id: doc.data().taskId,
          title: doc.data().taskName,
          taskuserId: doc.data().userId,
          start: date_new !== null ? date_new[2] + '-' + date_new[1] + '-' + date_new[0] : null,
          taskDuration: doc.data().taskDuration,
          taskDurationmin: doc.data().taskMin,
          time: doc.data().Starttime,
          taskDate: doc.data().dueDate !== null ? doc.data().dueDate.split(' ')[0] : null,
          days: doc.data().days,
        });
      });
    });
  }

  async JoinMeeting() {
    return new Promise((resolve, reject) => {
      this.oldDocumentID = this.documentId;
      this.aswservice.JoinMeeting(this.currentUser.firstname, this.taskId).subscribe(async (response: any) => {
        console.log("response: ", response);
        this.documentId = response.message.documentId;
        this.meeting = response.message.meeting.Meeting;
        this.meeting_id = response.message.meeting.Meeting.MeetingId;
        this.attendee = response.message.attendee.Attendee;
        resolve("Join Meeting");
      }, error => {
        console.log("Error: ", error);
        reject(error);
      });
    });
  }

  private async setConfiguration() {
    const audioInputs = await this.audioVideo.listAudioInputDevices();
    const audioOutputs = await this.audioVideo.listAudioOutputDevices();
    const audioElement = document.getElementById(this.options.elements.audio) as HTMLAudioElement;
    if (audioInputs.length > 0) {
      await this.meetingSession.audioVideo.chooseAudioInputDevice(audioInputs[0].deviceId);
    } else {
      await this.meetingSession.audioVideo.chooseAudioInputDevice(null);
    }

    if (audioOutputs.length > 0) {
      await this.meetingSession.audioVideo.chooseAudioOutputDevice(audioOutputs[0].deviceId);
    } else {
      await this.meetingSession.audioVideo.chooseAudioOutputDevice(null);
    }

    this.audioVideo.addObserver({
      audioVideoDidStart: () => {
        this.options.callbackStart();
      }
    });
    if (audioElement !== null) {
      this.audioVideo.bindAudioElement(audioElement);
    }
  }

  setupSubscribePresenceHandle(): void {
    const handler = async (attendeeId: string, present: boolean, externalUserId: string, dropped: boolean) => {
      const isContentAttendee = new DefaultModality(attendeeId).hasModality(DefaultModality.MODALITY_CONTENT);
      let isSelfAttendee = new DefaultModality(attendeeId).base() === this.meetingSession.configuration.credentials.attendeeId;
      if (!present) {
        if (this.selfAttendeeId !== attendeeId) {
          $(".info").show();
          $("#attendee-video").hide();
          $(".attendee-icon").hide();
          $("#attendee-camera-icon").hide();
          $("#attendee-camera-off-icon").hide();
          $("#attendee-mic-icon").hide();
          $("#attendee-mic-off-icon").hide();
          delete this.attendees[attendeeId];
        }
        return;
      }

      if (attendeeId !== this.meetingSession.configuration.credentials.attendeeId && !this.attendeeExists(attendeeId)) {
        $(".info").hide();
        $("#attendee-video").show();
        $(".attendee-icon").show();
        $("#attendee-camera-icon").hide();
        $("#attendee-camera-off-icon").show();
        $("#attendee-mic-icon").hide();
        $("#attendee-mic-off-icon").show();
        if (this.attendeeMuteStatus == "UNMUTE") {
          $("#attendee-mic-icon").show();
        } else if (this.attendeeMuteStatus == "MUTE") {
          $("#attendee-mic-off-icon").show();
        }
      }
      if (!this.attendeeExists(attendeeId)) {
        this.Addattendees(this.createAttendee(0, attendeeId, externalUserId, isSelfAttendee))
      }
    };
    this.audioVideo.realtimeSubscribeToAttendeeIdPresence(handler);
  }

  attendeeExists(id) {
    return id in this.attendees;
  }

  Addattendees(attendee: any) {
    this.attendees[attendee.id] = attendee
  }

  private handlePublicActions() {
    this.audioVideo.realtimeSubscribeToReceiveDataMessage(AwsChimeComponent.MUTE, (dataMessage) => {
      if (dataMessage.throttled) {
        return;
      }
      this.attendeeMuteStatus = "MUTE";
      $("#attendee-mic-icon").hide();
      $("#attendee-mic-off-icon").show();
    })
    this.audioVideo.realtimeSubscribeToReceiveDataMessage(AwsChimeComponent.UNMUTE, (dataMessage) => {
      if (dataMessage.throttled) {
        return;
      }
      this.attendeeMuteStatus = "UNMUTE";
      $("#attendee-mic-icon").show();
      $("#attendee-mic-off-icon").hide();
    })
    this.audioVideo.realtimeSubscribeToReceiveDataMessage(AwsChimeComponent.BLOCKPARTNER, (dataMessage) => {
      if (dataMessage.throttled) {
        return;
      }
      this.commonser.Toaster("success", "Meeting partner blocked you!");
      this.LeaveMeeting();
    })
  }

  protected initPrivateMessaging() {

    this.audioVideo.realtimeSubscribeToReceiveDataMessage(this.meetingSession.configuration.credentials.attendeeId, (dataMessage) => {
      if (dataMessage.throttled) {
        return;
      }

      const message = dataMessage.json();
      switch (message.action) {
        case AwsChimeComponent.PRIVATEMESSAGE:
          if ($('.session-video').hasClass('rp-open')) {
            this.notificationCount = 0;

          } else {
            this.notificationCount = this.notificationCount + 1;

          }
          $('#chat-dashboard').append("<div class='msg left-msg'><div class='msg-img' style='background-image: url(" + message.data.thumbnail + ")'></div><div class='msg-bubble'><div class='msg-info'><div class='msg-info-name'>" + message.data.sendername + "</div><div class='msg-info-time'>" + this.getCurrentTime(new Date) + "</div></div><div class='msg-text'>" + this.Convert(message.data.text) + "</div></div></div>");
          break;
      }
    })

  }

  async aws_meeting() {
    return new Promise(async (resolve, rejects) => {
      try {
        if (!this.calldestroy) {
          const logger = new ConsoleLogger('MyLogger', LogLevel.OFF);
          const deviceController = new DefaultDeviceController(logger);
          const configuration = new MeetingSessionConfiguration(this.meeting, this.attendee);
          this.meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);

          this.audioVideo = this.meetingSession.audioVideo;
          let obj = this;
          const observer = {
            audioVideoDidStart: () => {
              this.loader = false;
            }
          };

          this.meetingSession.audioVideo.addObserver(observer);
          await this.setConfiguration();

          this.setupSubscribePresenceHandle();
          this.handlePublicActions();
          this.initPrivateMessaging();
          this.EndMeeting();
          this.bindEvents();
          this.meetingSession.audioVideo.start();
          resolve("done");
        }
      }
      catch (error) {
        rejects("Error")
      }
    });
  }

  async bindEvents(): Promise<void> {
    this.HandleCamera();
    this.HandleMic();
  }

  async HandleCamera() {
    if (this.startVideo) {
      this.audioVideo.stopLocalVideoTile();
      this.startVideo = false;
      this.cameralabel = "Video off";
      $("#self-camera-icon").hide();
      $("#self-camera-off-icon").show();
      $("#self-description-camera-on-icon").hide();
      $("#self-description-camera-off-icon").show();
    }
    else {
      const videoInputDevices = await this.audioVideo.listVideoInputDevices();
      if (videoInputDevices.length > 0) {
        await this.audioVideo.chooseVideoInputDevice(videoInputDevices[0].deviceId);
        this.audioVideo.startLocalVideoTile();
        this.startVideo = true;
        this.cameralabel = "Video on";
        $("#self-camera-icon").show();
        $("#self-camera-off-icon").hide();
        $("#self-description-camera-on-icon").show();
        $("#self-description-camera-off-icon").hide();
      }
      else {
        await this.audioVideo.chooseVideoInputDevice(null);
        this.cameralabel = "Video off";
        $("#self-camera-icon").hide();
        $("#self-camera-off-icon").show();
        $("#self-description-camera-on-icon").hide();
        $("#self-description-camera-off-icon").show();
      }
    }

    const observer = {
      videoTileDidUpdate: tileState => {
        if (!this.calldestroy) {
          if (!tileState.boundAttendeeId) {
            return;
          }

          this.selfAttendeeId = this.meetingSession.configuration.credentials.attendeeId;
          let isSelfId = (this.selfAttendeeId == tileState.boundAttendeeId) ? true : false;
          if (!this.attendeeExists(tileState.boundAttendeeId)) {
            this.Addattendees(this.createAttendee(tileState.tileId, tileState.boundAttendeeId, tileState.boundExternalUserId, isSelfId))
          } else {
            this.attendees[tileState.boundAttendeeId]["tileId"] = tileState.tileId;
          }
          if (this.attendeeExists(tileState.boundAttendeeId) && this.attendees[tileState.boundAttendeeId] && tileState.boundAttendeeId !== this.meetingSession.configuration.credentials.attendeeId) {
            const videoElement = document.getElementById("attendee-video") as HTMLVideoElement;
            if (videoElement !== null) {
              $(".info").hide();
              $("#attendee-video").show();
              $(".attendee-icon").show();
              this.audioVideo.bindVideoElement(tileState.tileId, videoElement);
              $("#attendee-camera-icon").show();
              $("#attendee-camera-off-icon").hide();
              $("#attendee-mic-icon").hide();
              $("#attendee-mic-off-icon").hide();
              if (this.attendeeMuteStatus == "UNMUTE") {
                $("#attendee-mic-icon").show();
              } else if (this.attendeeMuteStatus == "MUTE") {
                $("#attendee-mic-off-icon").show();
              }
              if (this.partnerId === "") {
                this.afs.collection('tasks', ref => ref.where("taskId", "==", this.taskId)).get().subscribe((querySnapshot) => {
                  querySnapshot.forEach((doc: any) => {
                    this.ProfileInfo(doc.data()["joinuserflaguserid"]);
                    this.showPartnerDetails = true;
                  });
                });
              }
            }
          } else {
            const videoElement = document.getElementById("self-video") as HTMLVideoElement;
            if (videoElement !== null) {
              this.audioVideo.bindVideoElement(tileState.tileId, videoElement);
            }
          }
        }
      },
      videoTileWasRemoved: tileId => {
        for (const keys in this.attendees) {
          if (tileId == this.attendees[keys]["tileId"]) {
            if (this.attendees[keys]["id"] !== this.selfAttendeeId) {
              $("#attendee-camera-icon").hide();
              $("#attendee-camera-off-icon").show();
            }
          }
        }
      }
    };
    if (this.audioVideo !== undefined) {

      this.audioVideo.addObserver(observer);
    }
  }

  getAttendeeName(externalId: string): string {
    return (externalId.split('#').slice(1).join('#'));
  }

  createAttendee(tileId: any, attendeeId: string, externalId: string, isSelf: boolean = false): any {
    let attendee: any = {};
    const name = this.getAttendeeName(externalId)
    attendee.id = attendeeId;
    attendee.name = name;
    attendee.mute = false;
    attendee.self = isSelf;
    attendee.tileId = tileId;
    return attendee;
  }

  HandleMic() {
    if (this.audioVideo.realtimeIsLocalAudioMuted()) {
      $("#self-mic-icon").show();
      $("#self-mic-off-icon").hide();
      this.audioVideo.realtimeUnmuteLocalAudio();
      this.audioVideo.realtimeSendDataMessage(AwsChimeComponent.UNMUTE, new DataEvent('action', AwsChimeComponent.UNMUTE), AwsChimeComponent.DATA_MESSAGE_LIFETIME_MS);

    } else {
      $("#self-mic-icon").hide();
      $("#self-mic-off-icon").show();
      this.audioVideo.realtimeMuteLocalAudio();
      this.audioVideo.realtimeSendDataMessage(AwsChimeComponent.MUTE, new DataEvent('action', AwsChimeComponent.MUTE), AwsChimeComponent.DATA_MESSAGE_LIFETIME_MS);
    }
  }

  EndMeetingClick() {
    this.LeaveMeeting();
  }

  async EndMeeting(): Promise<any> {
    const observer = {
      audioVideoDidStop: sessionStatus => {
        const sessionStatusCode = sessionStatus.statusCode();
        if (sessionStatusCode === MeetingSessionStatusCode.MeetingEnded) {
          this.deletemeetingattendeecollection();
          this.router.navigate(['/calendar-dashboard']);
        } else {
        }
      }
    };

    this.meetingSession.audioVideo.addObserver(observer);
  }

  LeaveMeeting() {
    const observer = {
      audioVideoDidStop: async sessionStatus => {
        const sessionStatusCode = sessionStatus.statusCode();
        if (sessionStatusCode === MeetingSessionStatusCode.Left) {
          /*
            - You called meetingSession.audioVideo.stop().
            - When closing a browser window or page, Chime SDK attempts to leave the session.
          */
          this.aswservice.deletemeetingattendee(this.selfAttendeeId, this.documentId).subscribe((data: any) => { });
          this.audioVideo.realtimeMuteLocalAudio();
          this.audioVideo.stopLocalVideoTile();
          await this.meetingSession.audioVideo.chooseVideoInputDevice(null);
          this.router.navigate(['/calendar-dashboard']);
        }
      }
    };
    if (this.audioVideo !== undefined) {
      this.audioVideo.addObserver(observer);
      this.audioVideo.stop();
    }
  }

  RemoveAttendeeOnLeaveMeeting() {
    this.LeaveMeeting();
  }

  deletemeetingattendeecollection() {
    this.aswservice.deletemeetingattendeecollection(this.documentId).subscribe((data: any) => { })
  }

  chatMessageSend() {
    if ($('#chat-input').val().length > 0) {
      let chat = $("#chat-input").val();
      $('#chat-dashboard').append("<div class='msg right-msg'><div class='msg-img' style='background-image: url(" + this.currentUser.photoURL + ")'></div><div class='msg-bubble'><div class='msg-info'><div class='msg-info-name'>" + this.currentUser.firstname + "</div><div class='msg-info-time'>" + this.getCurrentTime(new Date) + "</div></div><div class='msg-text'>" + this.Convert($('#chat-input').val()) + "</div></div></div>");
      $("#chat-input").val("");
      let attendeeID: any;
      for (const keys in this.attendees) {
        if (this.attendees[keys]["id"] !== this.selfAttendeeId) {
          attendeeID = this.attendees[keys]["id"];
          break;
        }
      }
      this.audioVideo.realtimeSendDataMessage(attendeeID, new DataEvent('message', AwsChimeComponent.PRIVATEMESSAGE, { text: chat, sendername: this.currentUser.firstname, thumbnail: this.currentUser.photoURL }), AwsChimeComponent.DATA_MESSAGE_LIFETIME_MS);
    }
  }

  Convert(text) {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return '<a target="_blank" href="' + url + '">' + url + '</a>';
    })
  }

  getCurrentTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes;
  };

  TimeDifference() {
    let date = new Date();
    let dt2 = new Date(this.taskStartTime);
    let dt3 = new Date(this.taskEndtime);
    let diff = (date.getTime() - dt2.getTime()) / 1000;
    diff /= 60;
    if (Math.abs(Math.round(diff)) < 180) {
      if (date.getTime() >= dt2.getTime() && date.getTime() <= dt3.getTime()) {
        if (Math.abs(Math.round((dt3.getTime() - date.getTime()) / 1000)) == 600) {
          if (this.lateMinutes >= 0 && this.lateMinutes <= 10) {
            this.afs.collection("completed-session").doc(this.currentUser.uid).get().subscribe((document: any) => {
              let completed_session_count: number = 0;
              if (document.exists) {
                completed_session_count = parseInt(document.data()["completed_session_count"]);
                completed_session_count = (completed_session_count > 0) ? completed_session_count + 1 : 1;
              } else {
                completed_session_count = 1;
              }
              this.afs.collection("completed-session").doc(this.currentUser.uid).set({ 'completed_session_count': completed_session_count })
            });
          }
        }
      } else if (date.getTime() > dt3.getTime() && !this.markmeetingEnd) {
        this.markmeetingEnd = true;
        this.commonser.Toaster("success", "Meeting Ended! Hoping it served your purpose.")
        this.LeaveMeeting();
        this.router.navigate(['/calendar-dashboard']);
      }
    }
  }

  btn_tgl_dwn() {
    this.toggle = !this.toggle;
  }

  btn_tgl_dwn_master() {
    this.togglemaster = !this.togglemaster;
  }

  BlockUser() {
    let obj = this;
    let UserUID = this.partnerId;
    this.afs.collection("blocked-user").doc(this.currentUser.uid).get().subscribe((document: any) => {
      let storedblocked = [];
      if (document.exists) {
        storedblocked = document.data()["blockUsers"];
        if (!storedblocked.includes(UserUID)) {
          // add blockuser in array
          storedblocked.push(UserUID);
        }
      } else {
        // First user in block list
        storedblocked.push(UserUID);
      }
      let taskCount = 0;
      obj.afs.collection("blocked-user").doc(obj.currentUser.uid).set({ 'blockUsers': storedblocked }).then(() => {
        obj.afs.collection('tasks', ref => ref.where('userId', '==', obj.currentUser.uid)).get().subscribe((querySnapshot) => {
          if (querySnapshot.size > 0) {
            querySnapshot.forEach((doc: any) => {
              if (doc.data()["joinuserflaguserid"] === UserUID) {
                obj.afs.collection('tasks', ref => ref.where('joinuserflaguserid', '==', obj.currentUser.uid)).get().subscribe((querySnapshot) => {
                  if (querySnapshot.size > 0) {
                    querySnapshot.forEach((doc1: any) => {
                      obj.afs.collection('tasks').get().subscribe((querySnapshot) => {
                        if (querySnapshot.size > 0) {
                          querySnapshot.forEach((doc3: any) => {
                            if (taskCount === 0) {
                              if ((new Date(doc3.data()["taskStartTime"]).getTime() == new Date(doc1.data()["taskStartTime"]).getTime()) && doc3.data()["joinuserflag"] == 'false' && doc3.data()["userId"] != UserUID) {
                                obj.afs.collection('tasks').doc(doc1.id).update({ joinuserflaguserid: doc3.data()["userId"], joinuserflagtaskid: doc3.data()["taskId"], joinuserflag: 'True', bookedManual: 'True' });
                                obj.afs.collection('tasks').doc(doc3.id).update({ joinuserflaguserid: doc1.data()["userId"], joinuserflagtaskid: doc1.data()["taskId"], joinuserflag: 'True', bookedManual: 'True' });
                                taskCount++;
                              }
                            }
                          })
                        }
                      });
                      if (taskCount === 0) {
                        obj.afs.collection('tasks').doc(doc1.id).update({ joinuserflaguserid: '', joinuserflagtaskid: '', joinuserflag: 'false', bookedManual: 'false' })
                      }
                    });
                  }
                });
                obj.afs.collection('tasks').doc(doc.id).delete();
              }
            });
          }
        });
        this.afs.collection("blocked-user").doc(UserUID).get().subscribe((document: any) => {
          let storedblockedOther = [];
          if (document.exists) {
            storedblockedOther = document.data()["blockUsers"];
            if (!storedblockedOther.includes(this.currentUser.uid)) {
              // add blockuser in array
              storedblockedOther.push(this.currentUser.uid);
            }
          } else {
            // First user in block list
            storedblockedOther.push(this.currentUser.uid);
          }
          this.afs.collection("blocked-user").doc(UserUID).set({ 'blockUsers': storedblockedOther })
        });

        this.audioVideo.realtimeSendDataMessage(AwsChimeComponent.BLOCKPARTNER, new DataEvent('action', AwsChimeComponent.BLOCKPARTNER), AwsChimeComponent.DATA_MESSAGE_LIFETIME_MS);
        this.CloseBlockUserModal.nativeElement.click();
        this.LeaveMeeting();


      }, error => {
        console.log(error);
        this.CloseBlockUserModal.nativeElement.click();
      });
    })

  }

  OpenPopUpModal() {
    this.afs.collection('users', ref => ref.where('uid', '==', this.partnerId)).get().subscribe((querySnapshot) => {
      querySnapshot.forEach((element: any) => {
        this.partnerDisplayName = element.data()["displayName"];
      });
      this.openBlockUserModal.nativeElement.click();
    });
  }

  ProfileInfo(UID) {
    this.afs.collection('users', ref => ref.where('uid', '==', UID)).get().subscribe((querySnapshot) => {
      querySnapshot.forEach((element: any) => {
        this.partnerDisplayName = element.data()["displayName"];
        this.partnerProfileImage = element.data()["photoURL"];
      });
      this.afs.collection("completed-session").doc(UID).get().subscribe((document: any) => {
        let completed_session_count: number = 0;
        if (document.exists) {
          completed_session_count = parseInt(document.data()["completed_session_count"]);
          this.partnercompletedsessioncount = (completed_session_count > 0) ? completed_session_count + " Sessions" : 0 + " Sessions";
        } else {
          this.partnercompletedsessioncount = 0 + " Sessions";
        }
      });
    });
  }

  deleteTaskClick(eventId) {
    this.deleteTask = eventId;
    this.taskName = this.totaltaskList.filter(x => x.id == eventId)[0].title;

    this.deleteopenModal.nativeElement.click();
  }

  async sure_Delete_Task(deleteTask) {
    await this.afs.doc('remaindertasks/' + deleteTask).delete();
    this.getTop3Prioritylist();
    this.deleteCloseModal.nativeElement.click();
  }

  remainderDetails(obj, eventId) {
    this.TaskModalTitle = "Edit Task";
    this.taskForDetails = this.totaltaskList.filter(x => x.id == eventId);
    this.taskeventdetailopenModal.nativeElement.click();
    this.taskForm.patchValue({
      userId: this.currentUser.uid,
      taskName: this.taskForDetails[0].title,
      date: this.taskForDetails[0].start == null ? "" : this.taskForDetails[0].start,
      time: this.taskForDetails[0].time,
      duration: this.taskForDetails[0].taskDuration,
      durationmin: this.taskForDetails[0].taskDurationmin,
      days: this.taskForDetails[0].days
    });
    let ob = this;
    let dateval = ob.taskForDetails[0].start == null ? "" : ob.taskForDetails[0].start;
    if (dateval !== "") {
      dateval = dateval.split('-');
      dateval = dateval[2] + '-' + dateval[1] + '-' + dateval[0];
    }
    $('#datetimepicker1').val(dateval);

  }
  get f() { return this.taskForm.controls; }

  async remainderSubmit() {
    let alltask: any = [];
    let taskvalue = '';
    this.submitted = true;
    if (this.taskForm.invalid) {
      return;
    }
    this.loader = true;
    taskvalue = 'Priority';
    alltask = await this.getallTask('remaindertasks', this.taskForm.value.userId);
    if (alltask.length > 2) {
      taskvalue = 'Master';
    }
    if (this.TaskModalTitle == "New Task") {
      const taskData = {
        taskId: this.afs.createId(),
        userId: this.taskForm.value.userId,
        taskName: this.taskForm.value.taskName,
        dueDate: this.taskForm.value.date,
        taskDuration: this.taskForm.value.duration ? this.taskForm.value.duration : '1',
        taskMin: this.taskForm.value.durationmin ? this.taskForm.value.durationmin : '0',
        days: this.taskForm.value.days,
        categoryList: taskvalue,
        postionIndex: alltask.length
      }
      await this.AddNewRemainderTask(taskData, 'remaindertasks');
      this.loader = false;
    } else {
      const taskData = {
        taskId: this.taskForDetails[0].id,
        userId: this.taskForm.value.userId,
        taskName: this.taskForm.value.taskName,
        dueDate: this.taskForm.value.date,
        taskDuration: this.taskForm.value.duration ? this.taskForm.value.duration : '1',
        taskMin: this.taskForm.value.durationmin ? this.taskForm.value.durationmin : '0',
        days: this.taskForm.value.days
      }
      await this.updateTask(taskData, 'remaindertasks');
    }
    this.closeModal.nativeElement.click();
    setTimeout(() => {
      this.loader = false;
      this.getTop3Prioritylist();
    }, 1000);
  }

  getallTask(tablename, userId) {
    return new Promise((resolve, reject) => {
      let data = [];
      this.afs.collection(tablename, ref => ref.where('userId', '==', userId)).get().subscribe((querySnapshot) => {
        querySnapshot.forEach((doc: any) => {
          data.push(doc.data());
        })
        resolve(data);
      });
    });
  }

  AddNewRemainderTask(taskData, tablename) {
    return new Promise<void>((resolve, reject) => {
      this.afs.collection(tablename).doc(taskData.taskId).set(taskData);
      resolve()
    })
  }

  updateTask(data, tablename) {
    return new Promise<void>((resolve, reject) => {
      this.afs.doc(tablename + '/' + data.taskId).update(data);
      resolve()
    })
  }

  CreateTask() {
    this.TaskModalTitle = "New Task";
    this.submitted = false;
    $('#datetimepicker1').val('');
    $('.check').prop("checked", false);
    this.taskForm.patchValue({
      userId: this.currentUser.uid,
      taskName: '',
      durationmin: '',
      duration: ''
    });
  }

}
