import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../../services/auth.service";
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})

export class VerifyEmailComponent implements OnInit {
  showemail: boolean = false;
  email: string;

  constructor(public authService: AuthService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.email = this.activatedRoute.snapshot.paramMap.get('id');
  }
}
