import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AwsChimeService {

  constructor(private http: HttpClient) { }

  createMeeting(username: string) {
    var body = { username: username };
    return this.http.post("https://us-central1-superbrain-c2070.cloudfunctions.net/createmeeting", body);
  }

  JoinMeeting(username: string, taskId: string) {
    var body = { username: username, taskId: taskId };
    return this.http.post("https://us-central1-superbrain-c2070.cloudfunctions.net/joinmeeting", body);
  }

  deletemeetingattendee(attendeeid: string, documentId: string) {
    var body = { attendeeid: attendeeid, docId: documentId }
    return this.http.post("https://us-central1-superbrain-c2070.cloudfunctions.net/deletemeetingattendee", body);
  }

  deletemeetingattendeecollection(documentId: string) {
    var body = { documentId: documentId }
    return this.http.post("https://us-central1-superbrain-c2070.cloudfunctions.net/deletemeetingandattendeecollection", body);
  }

  SendContactEmail(email: string, name: string) {
    var body = { name: name, email: email }
    return this.http.post("https://us-central1-superbrain-c2070.cloudfunctions.net/sendContactEmail", body);
  }

}
