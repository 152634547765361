<div class="row">
  <div class="col-3"></div>
  <div class="col-6 alert-secondary rounded">
    <div class="card">
      <div class="card-header h6">
        Create Post
      </div>
      <div class="card-body">
        <textarea [(ngModel)]="post.postValue" class="form-control" cols="100" rows="2"
          placeholder="Write something here"></textarea>
      </div>
      <div class="card-footer pb-0">
        <button (click)="postText()" class="badge badge-pill badge-primary" style="cursor: pointer;"
          [disabled]="!post.postValue" [ngClass]="{'my-color': !post.postValue}">Post Text</button>
        <label class="badge badge-pill badge-success ml-2" style="cursor: pointer;">
          Post Image
          <input (change)="postImage($event)" type="file" style="display: none;" />
        </label>

        <label class="badge badge-pill badge-info ml-2" style="cursor: pointer;">
          Post Video
          <input (change)="postVideo($event)" type="file" style="display: none;" />
        </label>
      </div>
    </div>
  </div>
  <div class="col-3"></div>
</div>

<div class="row mt-4">
  <div class="col-3"></div>
  <!-- Parent section-->
  <div class="col-6">
    <ng-container *ngFor="let item of postList; let itemIndex=index;">
      <!-- Text card -->
      <div *ngIf="item.postType == 'Text'" class="card mt-2">
        <div class="card-header h6">
          <!-- PostId {{itemIndex + 1}} -->
        </div>
        <div class="card-body pb-0">
          <div class="make-center beautify-post">
            {{item.postValue}}
          </div>
        </div>
      </div>

      <!-- Image card -->
      <div *ngIf="item.postType == 'Image'" class="card mt-2">
        <div class="card-header h6">
        </div>
        <div class="card-body pb-0">
          <div class="make-center" style="height: 250px;">
            <img [src]="item.postValue" alt="" style="height: 100%; width: 100%; object-fit: contain;" />
          </div>
        </div>
      </div>


      <!-- Video card -->
      <div *ngIf="item.postType == 'Video'" class="card mt-2">
        <div class="card-header h6">

        </div>
        <div class="card-body pb-0">
          <div class="make-center" style="height: 250px;">
            <video style="height: 100%; width: 100%;" controls>
              <source [src]="item.postValue" />
            </video>
          </div>
        </div>
      </div>

      <div class="card-footer d-flex justify-content-between">
        <div>
          <a (click)="likePost(post)" class="badge badge-pill badge-primary ml-2" style="cursor: pointer;">Like</a>
          <div *ngIf="post.likeCount>0" class="d-flex justify-content-between text-muted">
            <span style="font-size: small;">{{post.likeCount}}</span>
          </div>
          <a (click)="unlikePost(post)" class="badge badge-pill badge-danger ml-2" style="cursor: pointer;">Unlike</a>
          <div *ngIf="post.unlikeCount>0" class="d-flex justify-content-between text-muted">
            <span style="font-size: small;">{{post.unlikeCount}}</span>
          </div>
          <button (click)="addComment(item)" class="badge badge-pill badge-success ml-2" style="cursor: pointer;"
            [disabled]="!item.draftComment" [ngClass]="{'commentbtn-color': !item.draftComment}">
            Comment
          </button>
          <a (click)="editPost(item)" class="badge badge-pill badge-secondary ml-2" aria-hidden="true"
            style="cursor:pointer">Edit Post</a>

          <a (click)="deletePost(itemIndex)" style="cursor: pointer;" class="badge badge-pill badge-danger ml-2">Delete
            Post</a>
          <textarea [(ngModel)]="item.draftComment" class="form-control" cols="100" rows="2"
            placeholder="Write a comment....."></textarea>
          <div *ngFor="let comment of item.commentList; let commentIndex=index;">
            <p *ngIf="!comment.updateMode">{{comment.Value}}</p>
            <textarea *ngIf="comment.updateMode" [(ngModel)]="comment.draft" class="form-control" cols="100" rows="2"
              placeholder="Write a comment....."></textarea>
            <div>
              <a *ngIf="!comment.updateMode" (click)="updateComment(comment)" class="badge badge-pill badge-info ml-2"
                style="cursor: pointer;">Update</a>
              <a *ngIf="comment.updateMode" (click)="updateCommentDone(comment)"
                class="badge badge-pill badge-success ml-2" style="cursor: pointer;">Done</a>
              <button (click)="likeComment(comment)" class="badge badge-pill badge-success ml-2"
                style="cursor: pointer;">Like</button>
              <div *ngIf="comment.likeCount>0" class="d-flex justify-content-between text-muted">
                <span style="font-size: small;">{{comment.likeCount}}</span>
              </div>
              <button (click)="unlikeComment(comment)" class="badge badge-pill badge-light ml-2"
                style="cursor: pointer;">Unlike</button>
              <div *ngIf="comment.unlikeCount>0" class="d-flex justify-content-between text-muted">
                <span style="font-size: small;">{{comment.unlikeCount}}</span>
              </div>
              <!-- <a (click)="editPost(item)" class="badge badge-pill badge-secondary ml-2">Edit</a> -->
              <a (click)="deleteComment(commentIndex,item)" style="cursor: pointer;"
                class="badge badge-pill badge-danger ml-2">Delete</a>
            </div>
          </div>
        </div>
        <div>

        </div>
      </div>
    </ng-container>
    <!-- NO POST Available -->
    <div class="card" *ngIf="postList.length === 0">
      <div class="card-header">
        Header
      </div>
      <div class="card-body make-center" style="height: 250px;">
        No Posts Available
      </div>
      <div class="card-footer">
        Footer
      </div>
    </div>
  </div>
  <div class="col-3"></div>
</div>