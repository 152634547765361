<!-- <div class="loading" *ngIf="loader">Loading&#8230;</div> -->
<nav class="navbar navbar-expand-lg navbar-dark top-nav session-head">
	<div class="btn-group">
		<!-- <div class="partner-info">
			<img src="../../../assets/avtar.png" />
			<div class="detail">
				<span class="name">Shaked</span>
				<span class="sessions">590 Sessions</span>
			</div>
		</div> -->
	</div>
	<div class="test-header-left">
		<h6>👋 Hey! It’s focus time 😀 Tell your partner:</h6>
		<ol> 
			<li>1. What you plan to complete</li>
			<li>2. Which “No Gos” you will avoid</li>
		</ol>
	</div>
	<a class="navbar-brand" href="javascript:void(0)">
		<img class="brand-img" src="../assets/template/img/Peak-Focus-Logo.png" /><!-- <span>How Doers, Get More Done.</span> -->
	</a>
	<div class="btn-group btn-primary">
		<!-- <div id="helpdropdownshowhide" class="dropdown-menu" aria-labelledby="dropdownHelpMenuButton"></div> -->
		<button class="btn end-count" type="button" style="font-weight: bold;">Ends in<span
				id="countdown"></span></button>
		<button class="btn" type="button" (click)="LeaveMeeting()" style="font-weight: bold;">Leave <i
				class="fas fa-sign-out-alt"></i></button>

	</div>
</nav>
<div class="session-video">
	<div class="waiting">
		<span class="info">You’re here!</span>
		<span class="info">Waiting for other person to join…</span>
		<video src="" id="attendee-video" class="preview_local_view none-element" autoplay="true" playsinline="true"
			muted="true" style="object-fit: cover;"></video>
	</div>

	<div class="my-cam">
		<video src="" id="self-video" class="preview_local_view" autoplay="true" playsinline="true" muted="true"
			style="object-fit: cover;"></video>
		<span class="self-description">
			<i class="fas fa-video" id="self-description-camera-on-icon"></i>
			<i class="fas fa-video-slash" id="self-description-camera-off-icon"></i>
			<span>{{cameralabel}}</span>
		</span>
	</div>
	<div class="session-right">
		<ul class="nav nav-pills mb-2" id="pills-tab" role="tablist">
			<li class="nav-item mr-1" role="presentation">
				<a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#btn-task" role="tab"
					aria-controls="pills-home" aria-selected="true">Tasks</a>
			</li>
			<li class="nav-item ml-1" role="presentation">
				<a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#btn-chat" role="tab"
					aria-controls="pills-profile" aria-selected="false">Chat</a>
			</li>
		</ul>
		<div class="tab-content" id="pills-tabContent">
			<div class="tab-pane fade show active" id="btn-task" role="tabpanel" aria-labelledby="task-tab">
				<div class="task-list">
					<div class="task-row head">
						<div class="task-column asign"></div>
						<div class="task-column time">Est Time</div>
						<div class="task-column date">Due Date</div>
					</div>
					<h5>
						<span>Top 3 Priority</span>
						<button id="copybuttontext"> <i class="fa fa-clone" aria-hidden="true"></i> </button>
						<button class="open">
							<i class="fa fa-angle-down" id="toggledown"></i>
							<i class="fa fa-angle-up"></i>
						</button>
					</h5>
					<div class="task-container open">

					</div>
				</div>
			</div>
			<div id="output"></div>
			<div class="tab-pane fade" id="btn-chat" role="tabpanel" aria-labelledby="chat-tab">
				<div class="chat-scroll">
					<main class="msger-chat" id="chat-dashboard"></main>
				</div>

				<form class="msger-inputarea mb-0">
					<input type="text" class="msger-input" id="chat-input" autocomplete="off"
						placeholder="Enter your message...">
					<button type="submit" class="msger-send-btn btn-primary"
						(click)="chatMessageSend()">Send</button>
				</form>
			</div>
		</div>
	</div>
</div>
<div class="session-footer">
	<audio id="meeting-audio" class="hide"></audio>
	<div class="btn-group">
		<button class="btn" type="button" (click)="HandleCamera()">
			<i class="fa fa-video text-success" id="self-camera-icon"></i>
			<i class="fa fa-video-slash off-element" id="self-camera-off-icon"></i>
			<span>{{cameralabel}}</span></button>
		<button class="btn" type="button" (click)="HandleMic()">
			<i class="fa fa-microphone text-success" id="self-mic-icon"></i>
			<i class="fa fa-microphone-slash off-element" id="self-mic-off-icon"></i>

			<span>Mic</span></button>
		<button class="btn" type="button" id="btn-chat" (click)="HandleChat()"><i
				class="fa fa-comment"></i><span>Chat</span></button>

	</div>
	<!--
	<div class="btn-group ml-auto">
-->
	<audio id="audio-notification">
		<source src="../../../assets/time-up.mp3" type="audio/ogg">
	</audio>
	<button class="btn" type="button" id="btn-more" (click)="HandleTask()">
		<i class="fa fa-th-list"></i>
		<span>Task List</span>
	</button>
	<button class="blockuser"> Block Partner</button>

	<!--
	</div>
-->
</div>