<div class="loading" *ngIf="loader">Loading&#8230;</div>
<ul class="nav nav-pills" id="pills-tab" role="tablist">
  <li class="nav-item ml-1" role="presentation" (click)="changeTab('Upcoming')">
    <a class="nav-link active" id="pills-profile-tab" data-toggle="pill" href="#upcoming" role="tab"
      aria-controls="pills-profile" aria-selected="false">Upcoming</a>
  </li>
  <li class="nav-item mr-1" role="presentation" (click)="changeTab('Past')">
    <a class="nav-link " id="pills-home-tab" data-toggle="pill" href="#past" role="tab" aria-controls="pills-home"
      aria-selected="true">Past</a>
  </li>
  <li class="nav-item ml-auto" role="presentation"><button class="btn btn-flat"
      (click)="backtocalendar()">Calendar</button></li>
</ul>
<div class="tab-content" id="pills-tabContent">
  <div class="tab-pane fade show" id="past" role="tabpanel" aria-labelledby="task-tab">
    <div class="upcoming-eve">
      <div class="event" *ngFor="let item of pasttaskList;let i = index">
        <span class="name">
          <i class="fa fa-users"></i>
          <a href="javascript:void(0)" *ngIf="item.taskName!='Not matched'" class="ml-2" data-toggle="modal"
            data-target="#userProfileDetails" #userProfileDetails
            (click)="profile(item.joinuserflaguserid)">{{item.taskName}}</a>
          <a href="javascript:void(0)" *ngIf="item.taskName=='Not matched'" class="ml-2">{{item.taskName}}</a>
        </span>
        <div class="date-time">
          <span>{{item.taskDate | date:'dd-MM-yy'}}</span>
          <span>{{item.taskTime}}</span>
        </div>
        <div class="repeat-container">
          <label>Frequency</label>
          <div class="mr-auto repeat" [ngClass]="toggle[i] ||  togglemaster[i] ? 'd-none': ''">
            <button class="btn btn-flat-theme" (click)="toggle[i] = !toggle[i]">Repeat Daily</button>
            <button class="btn btn-flat-theme" (click)="togglemaster[i] = !togglemaster[i]">Repeat Weekly</button>
          </div>
          <div class="form-group d-flex" [ngClass]="toggle[i] ? 'active': ''">
            <label class="mr-1">Repeat Daily</label>
            <select class="form-control mr-1" (change)="countselect($event)">
              <option *ngFor="let itemd of itemsarr;" value="{{itemd}}">{{itemd}}</option>
            </select>
            <button class="btn btn-flat-theme save" (click)="repeat_event('daily', item.taskId)">Save</button>
            <button class="btn btn-primary close" (click)="toggle[i] = !toggle[i]"><i class="fas fa-times"></i></button>
          </div>
          <div class="form-group d-flex" [ngClass]="togglemaster[i] ? 'active': ''">
            <label class="mr-1">Repeat Weekly</label>
            <select class="form-control mr-1" (change)="countselect($event)">
              <option *ngFor="let itemd of itemsarr;" value="{{itemd}}">{{itemd}}</option>
            </select>
            <button class="btn btn-flat-theme save" (click)="repeat_event('weekly', item.taskId)">Save</button>
            <button class="btn btn-primary close" (click)="togglemaster[i] = !togglemaster[i]"><i
                class="fas fa-times"></i></button>
          </div>
        </div>
        <div class="actions">
          <div class="btn-group">
            <button class="btn btn-flat" (click)="taskDetails('Past',item.taskId)"><i class="fa fa-edit"></i></button>
            <button class="btn btn-flat" (click)="deleteTaskClick(item.taskId)"><i class="fas fa-times"></i></button>
          </div>
        </div>
      </div>
      <div *ngIf="!pasttaskList || pasttaskList.length === 0">
        <p>No Task Found</p>
      </div>
    </div>
  </div>

  <!-- <div id="output"></div> -->
  <div class="tab-pane active" id="upcoming" role="tabpanel" aria-labelledby="chat-tab">
    <div class="tab-pane fade show active" id="past" role="tabpanel" aria-labelledby="task-tab">
      <div class="upcoming-eve">
        <div class="event" *ngFor="let item of upcommingtaskList;let i = index">
          <span class="name">
            <i class="fa fa-users"></i>
            <a href="javascript:void(0)" class="ml-2" *ngIf="item.joinuserflaguserid==''">{{item.taskName}}</a>
            <a href="javascript:void(0)" class="ml-2" *ngIf="item.joinuserflaguserid!=''" data-toggle="modal"
              data-target="#userProfileDetails" #userProfileDetails
              (click)="profile(item.joinuserflaguserid)">{{item.taskName}}</a>
          </span>
          <div class="date-time">
            <span>{{item.taskDate | date:'dd-MM-yy'}}</span>
            <span>{{item.taskTime}}</span>
          </div>

          <div class="repeat-container">
            <label>Frequency</label>
            <div class="mr-auto repeat" [ngClass]="toggle[i] ||  togglemaster[i] ? 'd-none': ''">
              <button class="btn btn-flat-theme" (click)="toggle[i] = !toggle[i]">Repeat Daily</button>
              <button class="btn btn-flat-theme" (click)="togglemaster[i] = !togglemaster[i]">Repeat Weekly</button>
            </div>
            <div class="form-group d-flex" [ngClass]="toggle[i] ? 'active': ''">
              <label class="mr-1">Repeat Daily</label>
              <select class="form-control mr-1" (change)="countselect($event)">
                <option *ngFor="let itemd of itemsarr;" value="{{itemd}}">{{itemd}}</option>
              </select>
              <button class="btn btn-flat-theme save" (click)="repeat_event('daily', item.taskId)">Save</button>
              <button class="btn btn-primary close" (click)="toggle[i] = !toggle[i]"><i
                  class="fas fa-times"></i></button>
            </div>
            <div class="form-group d-flex" [ngClass]="togglemaster[i] ? 'active': ''">
              <label class="mr-1">Repeat Weekly</label>
              <select class="form-control mr-1" (change)="countselect($event)">
                <option *ngFor="let itemd of itemsarr;" value="{{itemd}}">{{itemd}}</option>
              </select>
              <button class="btn btn-flat-theme save" (click)="repeat_event('weekly', item.taskId)">Save</button>
              <button class="btn btn-primary close" (click)="togglemaster[i] = !togglemaster[i]"><i
                  class="fas fa-times"></i></button>
            </div>
          </div>
          <div class="actions">
            <div class="btn-group">
              <button class="btn btn-flat" (click)="taskDetails('Upcoming',item.taskId)"><i
                  class="fa fa-edit"></i></button>
              <button class="btn btn-flat" (click)="deleteTaskClick(item.taskId)"><i class="fas fa-times"></i></button>
            </div>
          </div>
        </div>
        <div *ngIf="!upcommingtaskList || upcommingtaskList.length === 0">
          <p>No Task Found</p>
        </div>
      </div>
    </div>


  </div>
</div>

<div class="modal fade" id="userProfileDetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered custom-alert" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">User Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closePopUpModal()"
          #CloseuserProfileDetailsModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row align-items-center">
          <div class="col-md-4">
            <div class="profile">
              <div class="avatar">
                <img src="{{userimageSrc}}" alt="Circle Image" class="img-raised rounded-circle img-fluid">
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="name">
              <h3 class="title mb-0 text-capitalize">{{userDisplayName}}</h3>
              <h6 class="mb-0">{{userEmail}}</h6>
              <p class="mb-1 mt-1">Member since : {{userCreationDate}} </p>
              <div class="block-div">
                <p><i class="fa fa-map-marker" aria-hidden="true"></i>{{userRegionWithTimeZone}}</p>
                <button class="btn btn-danger blocked-user" (click)="OpenPopUpModal()" data-toggle="modal"
                  data-target="#openBlockUserModal">Block</button>
                <!-- (click)="BlockUser(UserUID)" -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button type="button" class="btn btn-primary" style="display: none;" data-toggle="modal" data-target="#detailTaskModel"
  #detailTaskModel></button>

<div class="modal fade" id="detailTaskModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog  modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="form-group">
          <input type="text" name="" value="{{taskForDetails[0].tasktittle}}"
            (focusout)="updateeventname($event, taskForDetails[0].taskId)" />
          <button></button>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closedetailsModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">

            <div class="form-group">
              <label>Date</label> <br />
              <span class="value">{{taskForDetails[0].taskDate | date:'dd-MM-yy'}}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Time</label> <br />
              <span class="value">{{taskForDetails[0].taskTime}}</span>
            </div>
          </div>
        </div>

        <div class="repeat-container">
          <label>Frequency</label>
          <div class="repeat" [ngClass]="toggledone ||  toggledone1 ? 'd-none': ''">
            <!-- <button class="btn btn-flat-theme" (click)="repeat_event('daily', taskForDetails[0].taskId)">Repeat Daily</button>
              <button class="btn btn-flat-theme" (click)="repeat_event('weekly', taskForDetails[0].taskId)">Repeat Weekly</button> -->
            <button class="btn btn-flat-theme" (click)="toggledone = !toggledone">Repeat Daily</button>
            <button class="btn btn-flat-theme" (click)="toggledone1 = !toggledone1">Repeat Weekly</button>
          </div>
          <div class="form-group d-flex" [ngClass]="toggledone ? 'active': ''">
            <label class="mr-1 repeat-label">Repeat Daily</label>
            <select class="form-control mr-1" (change)="countselect($event)">
              <option *ngFor="let itemd of itemsarr;" value="{{itemd}}">{{itemd}}</option>
            </select>
            <button class="btn btn-flat-theme save"
              (click)="repeat_event('daily', taskForDetails[0].taskId)">Save</button>
            <button class="btn btn-primary close" (click)="toggledone = !toggledone"><i
                class="fas fa-times"></i></button>
          </div>
          <div class="form-group d-flex" [ngClass]="toggledone1 ? 'active': ''">
            <label class="mr-1 repeat-label">Repeat Weekly</label>
            <select class="form-control mr-1" (change)="countselect($event)">
              <option *ngFor="let itemd of itemsarr;" value="{{itemd}}">{{itemd}}</option>
            </select>
            <button class="btn btn-flat-theme save"
              (click)="repeat_event('weekly', taskForDetails[0].taskId)">Save</button>
            <button class="btn btn-primary close" (click)="toggledone1 = !toggledone1"><i
                class="fas fa-times"></i></button>
          </div>
        </div>
        <hr />
        <div class="partner-detail">
          <div class="pp">
            <img src="https://cdn.pixabay.com/photo/2017/06/13/12/53/profile-2398782_1280.png">
          </div>
          <div class="detail">
            <label>PARTNER</label>
            <span class="name">{{JoinUser}}</span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary save" (click)="selfBooked('delete', taskForDetails[0].taskId)"
          *ngIf="joinflag == ''">Delete</button>
        <button type="submit" class="btn btn-danger" (click)=" deleteTaskClick(taskForDetails[0].taskId)"
          *ngIf="joinflag">Cancel
          Session</button>
        <!-- <button type="submit" class="btn btn-danger" (click)=" deleteTaskClick(taskForDetails[0].taskId)">Cancel
            Session</button> -->
        <button type="button" class="btn btn-light" (click)="close('detailsModal')">Close</button>
      </div>
    </div>
  </div>
</div>

<button type="button" class="btn btn-primary" style="display: none;" data-toggle="modal" data-target="#deleteTask"
  #deleteopenModal></button>

<div class="modal fade" id="deleteTask" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered custom-alert" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Delete Task</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #deleteCloseModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h5 class="text-center">You sure you about this?! ☹️</h5>
        <p class="text-center">Deleting task {{taskForDetails[0].taskName}}. This action is irreversible.</p>

        <div class="row text-center">
          <div class="col-md-12">
            <p class="confirm-button">
              <a class="mr-2"><button class="btn btn-primary" data-dismiss="modal" aria-label="Close"
                  #deleteCloseModal>Cancel</button>
              </a>
              <a>
                <button class="btn btn-danger" (click)="sure_Delete_Task(deleteTask)">delete it</button>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#openBlockUserModal">Launch demo
  modal</button> -->

<!-- Modal -->
<div class="modal fade" id="openBlockUserModal" tabindex="-1" role="dialog" aria-labelledby="openBlockUserModal"
  #openBlockUserModal aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Block {{userDisplayName}}?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #CloseBlockUserModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Sorry that {{userDisplayName}} isn't a good partner for you.</p>
        <p>Blocking will prevent {{userDisplayName}} from:</p>
        <ul class="viewD">
          <li class="viewb">Booking sessions with you</li>
          <li class="viewb">Seeing your sessions on the calendar</li>
          <li class="viewb">Messaging you</li>
          <li class="viewb">Seeing your name or profile anywhere on Focusmate</li>
        </ul>
        <p>Your upcoming sessions if any with {{userDisplayName}} will be deleted. And if you are currently in a session
          together, it will end automatically as you block {{userDisplayName}}.</p>
        <p>{{userDisplayName}} will not be notified.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="BlockUser(UserUID)">Block</button>
      </div>
    </div>
  </div>
</div>