<div style="background-color: #000;
height: 100%;
position: fixed;
width: 100%;
">
    <div class="space"></div>
</div>

<!-- WRAPPER -->
<div id="wrapper" style="position: absolute; height: unset;">
    <!-- HEADER -->
    <header class="site-header" style="position: static;">

        <div class="container-fluid">
            <div class="row">

                <div class="col-xs-4">
                    <a href="index.html" class="no-line logotype">PeakFocus</a>
                </div>
                <div class="col-xs-8 text-right social-icons-wrap">
                    <div class="social-icons">
                        <a href="//twitter.com/Itembridge "><i class="fa fa-twitter"></i></a>
                        <a href="//www.facebook.com/ItemBridge "><i class="fa fa-facebook"></i></a>
                        <a href="//instagram.com/itembridge/ "><i class="fa fa-instagram"></i></a>
                        <a href="//plus.google.com/+Itembridge/"><i class="fa fa-google-plus"></i></a>
                        <a href="//www.pinterest.com/itembridge/"><i class="fa fa-pinterest"></i></a>
                    </div>
                    <div class="need-social">
                        <div class="opened">we are social</div>
                        <div class="closes"></div>
                    </div>
                </div>

            </div>
        </div>

    </header>
    <!-- /HEADER -->

    <!-- CONTENT -->
    <section class="site-content">

        <div id="fullpage" class="fullpage-wrapper">

            <div class="all-section">

                <div class="sections" data-title="Home">

                    <div class="section-content">
                        <div class="section-wrap">

                            <div class="container">
                                <div class="row">
                                    <div class="col-xs-12 text-center">

                                        <!-- TIMER -->
                                        <!-- <div class="glitch-wrap glitch" data-time="">

                                            <div class="fadein">
                                                <div class="glitch-clock is-off">
                                                    <div class="glitch-time">
                                                        <div class="glitch-before">
                                                            <div class="first-numb"></div>
                                                            <i class="i1"></i>
                                                            <div class="time-dott">:</div>
                                                            <div class="glitch-triangle">
                                                                <div class="clip-figure">
                                                                    <div class="mask-first">
                                                                        <div class="mask-second">
                                                                            <div class="clip-numb">
                                                                                <div class="second-numb"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <i class="i2"></i>
                                                            <div class="time-dott-sec">:</div>
                                                            <div class="third-numb"></div>
                                                            <i class="i3"></i>
                                                        </div>
                                                        <div class="glitch-content">
                                                            <div class="first-numb"></div>
                                                            <i class="i1"></i>
                                                            <div class="time-dott">:</div>
                                                            <div class="glitch-triangle">
                                                                <div class="clip-figure">
                                                                    <div class="mask-first">
                                                                        <div class="mask-second">
                                                                            <div class="clip-numb">
                                                                                <div class="second-numb"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <i class="i2"></i>
                                                            <div class="time-dott-sec">:</div>
                                                            <div class="third-numb"></div>
                                                            <i class="i3"></i>
                                                        </div>
                                                        <div class="glitch-after">
                                                            <div class="first-numb"></div>
                                                            <i class="i1"></i>
                                                            <div class="time-dott">:</div>
                                                            <div class="glitch-triangle">
                                                                <div class="clip-figure">
                                                                    <div class="mask-first">
                                                                        <div class="mask-second">
                                                                            <div class="clip-numb">
                                                                                <div class="second-numb"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <i class="i2"></i>
                                                            <div class="time-dott-sec">:</div>
                                                            <div class="third-numb"></div>
                                                            <i class="i3"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="glitch-figure">
                                                    <div class="glitch-triang">
                                                    </div>
                                                </div>
                                            </div>

                                        </div> -->
                                        <!-- /TIMER -->

                                        <h1 style="color: #fff;">DISCOVER HOW MUCH</h1>
                                        <h1 style="color: #fff;">MORE FOCUSED </h1>
                                        <h1 style="color: #fff;">YOU CAN BE </h1><br>
                                        <h2 style="color: #fff;" class="et-description">Boost Your Productivity by 300%
                                            With START TIMES.
                                        </h2>
                                        <!-- <div class="et-description-small">Our site is under construction</div> -->

                                    </div><!-- col-xs-12 -->
                                </div><!-- row -->
                            </div><!-- container -->

                        </div><!-- section-wrap -->
                    </div><!-- section-content -->

                </div>

                <!-- <div class="sections" data-title="Notify me">

                    <div class="section-content">
                        <div class="section-wrap">

                            <div class="container">
                                <div class="row">
                                    <div class="col-xs-12 text-center">

                                        <div class="notyfy-me border-gradient">
                                            <h3>NOTIFY ME</h3>
                                            <div class="notyfy-decr">Enter your email address to get notified when
                                                site is ready</div>
                                            <form class="under-construction form-styler" method="post" role="form"
                                                data-toggle="validator">
                                                <div class="form-group">
                                                    <input type="email" name="email"
                                                        placeholder="Insert your e-mail" required="">
                                                </div>

                                                <div class="btn join-us form-group form-sender">
                                                    <input type="submit" class="" value="Send">
                                                    <span class="error"></span>
                                                    <span class="success">
                                                        <svg xmlns="//www.w3.org/2000/svg"
                                                            xmlns:xlink="//www.w3.org/1999/xlink" x="0px" y="0px"
                                                            viewBox="0 0 29 23" enable-background="new 0 0 29 23">
                                                            <polyline fill="#FFFFFF"
                                                                points="0.3,10.2 1.8,8.8 11.4,19.3 27.4,0.4 28.9,1.7 12.9,20.6 11.5,22.3 9.9,20.6 0.3,10.2 ">
                                                            </polyline>
                                                        </svg>
                                                    </span>
                                                    <span class="progress"></span>
                                                </div>
                                                <span class="form-message"></span>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div> -->

                <!-- <div class="sections" data-title="Portfolio">

                    <div class="section-content">
                        <div class="section-wrap">

                            <div class="container">
                                <div class="row">
                                    <div class="col-xs-12 text-center">

                                        <h3>POrtfolio</h3>

                                        <hr>

                                        <div class="portfolio owl-carousel noSwipe">

                                            <div class="portf-col">
                                                <div class="portfolio-block">
                                                    <div class="portfolio-block-in">

                                                        <img src="content/portfolio/first.jpg" alt="">
                                                        <div class="portfolio-info">
                                                            <div class="portfolio-info-content">

                                                                <div class="portfolio-info-title">
                                                                    <strong>Some item name</strong>
                                                                    <br>The line of text
                                                                </div>
                                                                <div class="portfolio-info-social">
                                                                    <a href="#">
                                                                        <i class="fa fa-pinterest"></i>
                                                                    </a>
                                                                    <a href="#">
                                                                        <i class="fa fa-twitter"></i>
                                                                    </a>
                                                                    <a href="#">
                                                                        <i class="fa fa-thumbs-o-up"></i>
                                                                    </a>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="portfolio-block">
                                                    <div class="portfolio-block-in">

                                                        <img src="content/portfolio/first.jpg" alt="">
                                                        <div class="portfolio-info">
                                                            <div class="portfolio-info-content">

                                                                <div class="portfolio-info-title">
                                                                    <strong>Some item name</strong>
                                                                    <br>The line of text
                                                                </div>
                                                                <div class="portfolio-info-social">
                                                                    <a href="#">
                                                                        <i class="fa fa-pinterest"></i>
                                                                    </a>
                                                                    <a href="#">
                                                                        <i class="fa fa-twitter"></i>
                                                                    </a>
                                                                    <a href="#">
                                                                        <i class="fa fa-thumbs-o-up"></i>
                                                                    </a>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="portf-col">
                                                <div class="portfolio-block">
                                                    <div class="portfolio-block-in">

                                                        <img src="content/portfolio/second.jpg" alt="">
                                                        <div class="portfolio-info">
                                                            <div class="portfolio-info-content">

                                                                <div class="portfolio-info-title">
                                                                    <strong>Some item name</strong>
                                                                    <br>The line of text
                                                                </div>
                                                                <div class="portfolio-info-social">
                                                                    <a href="#">
                                                                        <i class="fa fa-pinterest"></i>
                                                                    </a>
                                                                    <a href="#">
                                                                        <i class="fa fa-twitter"></i>
                                                                    </a>
                                                                    <a href="#">
                                                                        <i class="fa fa-thumbs-o-up"></i>
                                                                    </a>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="portfolio-block">
                                                    <div class="portfolio-block-in">

                                                        <img src="content/portfolio/second.jpg" alt="">
                                                        <div class="portfolio-info">
                                                            <div class="portfolio-info-content">

                                                                <div class="portfolio-info-title">
                                                                    <strong>Some item name</strong>
                                                                    <br>The line of text
                                                                </div>
                                                                <div class="portfolio-info-social">
                                                                    <a href="#">
                                                                        <i class="fa fa-pinterest"></i>
                                                                    </a>
                                                                    <a href="#">
                                                                        <i class="fa fa-twitter"></i>
                                                                    </a>
                                                                    <a href="#">
                                                                        <i class="fa fa-thumbs-o-up"></i>
                                                                    </a>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="portf-col">
                                                <div class="portfolio-block">
                                                    <div class="portfolio-block-in">

                                                        <img src="content/portfolio/third.jpg" alt="">
                                                        <div class="portfolio-info">
                                                            <div class="portfolio-info-content">

                                                                <div class="portfolio-info-title">
                                                                    <strong>Some item name</strong>
                                                                    <br>The line of text
                                                                </div>
                                                                <div class="portfolio-info-social">
                                                                    <a href="#">
                                                                        <i class="fa fa-pinterest"></i>
                                                                    </a>
                                                                    <a href="#">
                                                                        <i class="fa fa-twitter"></i>
                                                                    </a>
                                                                    <a href="#">
                                                                        <i class="fa fa-thumbs-o-up"></i>
                                                                    </a>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="portfolio-block">
                                                    <div class="portfolio-block-in">

                                                        <img src="content/portfolio/third.jpg" alt="">
                                                        <div class="portfolio-info">
                                                            <div class="portfolio-info-content">

                                                                <div class="portfolio-info-title">
                                                                    <strong>Some item name</strong>
                                                                    <br>The line of text
                                                                </div>
                                                                <div class="portfolio-info-social">
                                                                    <a href="#">
                                                                        <i class="fa fa-pinterest"></i>
                                                                    </a>
                                                                    <a href="#">
                                                                        <i class="fa fa-twitter"></i>
                                                                    </a>
                                                                    <a href="#">
                                                                        <i class="fa fa-thumbs-o-up"></i>
                                                                    </a>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="portf-col">
                                                <div class="portfolio-block">
                                                    <div class="portfolio-block-in">

                                                        <img src="content/portfolio/first.jpg" alt="">
                                                        <div class="portfolio-info">
                                                            <div class="portfolio-info-content">

                                                                <div class="portfolio-info-title">
                                                                    <strong>Some item name</strong>
                                                                    <br>The line of text
                                                                </div>
                                                                <div class="portfolio-info-social">
                                                                    <a href="#">
                                                                        <i class="fa fa-pinterest"></i>
                                                                    </a>
                                                                    <a href="#">
                                                                        <i class="fa fa-twitter"></i>
                                                                    </a>
                                                                    <a href="#">
                                                                        <i class="fa fa-thumbs-o-up"></i>
                                                                    </a>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="portfolio-block">
                                                    <div class="portfolio-block-in">

                                                        <img src="content/portfolio/first.jpg" alt="">
                                                        <div class="portfolio-info">
                                                            <div class="portfolio-info-content">

                                                                <div class="portfolio-info-title">
                                                                    <strong>Some item name</strong>
                                                                    <br>The line of text
                                                                </div>
                                                                <div class="portfolio-info-social">
                                                                    <a href="#">
                                                                        <i class="fa fa-pinterest"></i>
                                                                    </a>
                                                                    <a href="#">
                                                                        <i class="fa fa-twitter"></i>
                                                                    </a>
                                                                    <a href="#">
                                                                        <i class="fa fa-thumbs-o-up"></i>
                                                                    </a>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div> -->

                <div class="sections contact-block" data-title="Contact">

                    <div class="section-content">
                        <div class="section-wrap">

                            <div class="container">
                                <div class="row">
                                    <div class="col-xs-12 text-center">

                                        <h3 style="color: #fff;">Contact</h3>

                                        <hr>

                                        <div class="contact-me border-gradient border-gradient-hidden-xs ">

                                            <div class="row m0">
                                                <!-- <div class="col-sm-8 text-center"> -->
                                                <div class="col-sm-12 text-center">

                                                    <div class="contact-form border-gradient-visible-xs">

                                                        <form id="contactform" class="form-styler" role="form"
                                                            data-toggle="validator">

                                                            <div class="form-group">
                                                                <input type="text" required placeholder="Name"
                                                                    name="name">
                                                            </div>
                                                            <div class="form-group">
                                                                <input type="email" required placeholder="Email"
                                                                    name="email">
                                                            </div>
                                                            <div class="contact-comment form-group">
                                                                <textarea placeholder="Message" required
                                                                    name="comment"></textarea>
                                                            </div>

                                                            <div class="btn submit-form form-sender">
                                                                <input type="submit" value="send">
                                                                <span class="error"></span>
                                                                <span class="success">
                                                                    <svg xmlns="//www.w3.org/2000/svg"
                                                                        xmlns:xlink="//www.w3.org/1999/xlink" x="0px"
                                                                        y="0px" viewBox="0 0 29 23"
                                                                        enable-background="new 0 0 29 23">
                                                                        <polyline fill="#FFFFFF"
                                                                            points="0.3,10.2 1.8,8.8 11.4,19.3 27.4,0.4 28.9,1.7 12.9,20.6 11.5,22.3 9.9,20.6 0.3,10.2 ">
                                                                        </polyline>
                                                                    </svg>
                                                                </span>
                                                                <span class="progress"></span>
                                                            </div>

                                                        </form>

                                                        <div class="form-message"></div>

                                                    </div>

                                                </div>
                                                <!-- <div class="col-sm-4 text-left contact-info">

                                                    <div class="contact-street">
                                                        <i class="fa fa-map-marker"></i>
                                                        <div class="dashed">
                                                            123 Main Street city, AB 0123<br>
                                                            345 Not Main Street city,<br>
                                                            CD 4567
                                                        </div>
                                                    </div>

                                                    <p class="contact-tell">
                                                        <i class="fa fa-phone"></i>
                                                        012-345-6789<br>
                                                        025-698-9658
                                                    </p>

                                                    <div class="contact-email">
                                                        <i class="fa fa-envelope-o"></i>
                                                        <div class="dashed">
                                                            myemail@example.com<br>
                                                            heremail@sample.com
                                                        </div>
                                                    </div>

                                                </div> -->
                                            </div>

                                            <div class="map-canvas"></div>
                                            <div class="closed"></div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>

    </section>
    <!-- /CONTENT -->

    <!-- FOOTER -->
    <footer class="site-footer">

        <div class="container-fluid">
            <div class="row">

                <div class="col-xs-12 text-center-xs text-right">
                    <div class="copyright">
                        © PeakFocus 2022.
                    </div>
                </div>

            </div>
        </div>

    </footer>
    <!-- /FOOTER -->

</div>
<!-- /WRAPPER -->

<script src="../../../assets/new-template/js/jquery-1.11.1.min.js"></script>
<script src="../../../assets/new-template/js/bootstrap.min.js"></script>
<script src="../../../assets/new-template/js/jquery.fullPage.min.js"></script>
<script src="../../../assets/new-template/js/jquery.mCustomScrollbar.concat.min.js"></script>
<script src="../../../assets/new-template/js/owl.carousel.min.js"></script>
<script src="../../../assets/new-template/js/main.js"></script>