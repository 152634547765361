import { Injectable, NgZone } from '@angular/core';
import { User } from "../services/user";
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TribeService } from './tribe/tribe.service'

declare var moment: any, $: any;

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data
  baseURL: string = "https://us-central1-superbrain-c2070.cloudfunctions.net/tribe";
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private dbPath = '/customers';
  customersRef: AngularFirestoreCollection<User> = null;
  userList: any = [];
  public emailexist: string;
  current_Date: any;
  region_with_timezone: any;
  previousUrl: any;
  adminToken: any;
  usersList = [];

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    private db: AngularFirestore,
    private http: HttpClient,
    private tribe: TribeService
  ) {
    this.adminToken = localStorage.getItem("adminToken");

    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.router.url;
    this.customersRef = db.collection(this.dbPath);
    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
      });
    this.afAuth.authState.subscribe(user => {
      if (user && user.emailVerified) {
        this.userData = user;
        this.gerfirebaseUserbyUID(user.uid);
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    })
    this.region_with_timezone = moment.tz.guess();
    this.current_Date = moment().format("MMM Do YYYY");
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  async getCustomersList() {
    let obj = this;
    obj.userList = [];
    await this.afs.collection('users').get().subscribe((querySnapshot) => {
      querySnapshot.forEach((doc: any) => {
        //var d = doc.data();
        obj.userList.push(doc.data());
      });
    });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? true : false;
  }

  // Sign in with Google
  GoogleAuth(type) {
    console.log(type)
    return this.AuthLogin(new auth.GoogleAuthProvider(), "google");
  }

  FacebookAuth(type) {
    console.log(type)
    return this.AuthLogin(new auth.FacebookAuthProvider(), "facebook");
  }

  // Auth logic to run auth providers
  AuthLogin(provider, type) {
    return this.afAuth.signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          console.log("Test Calendar")
          this.router.navigate(['calendar-dashboard']);
        });
        if (type == "google") {
          result.user['firstname'] = result.additionalUserInfo.profile["given_name"];
          result.user['lastname'] = result.additionalUserInfo.profile["family_name"];
        }
        else if (type == "facebook") {
          result.user['firstname'] = result.additionalUserInfo.profile["first_name"];
          result.user['lastname'] = result.additionalUserInfo.profile["last_name"];
        }
        result.user['creationDate'] = this.current_Date;
        result.user['regionwithtimezone'] = this.region_with_timezone;
        result.user['region'] = '';
        result.user['timezone'] = '';
        console.log("result.user.email: ", result, result.user.email);

        this.afs.collection('users', ref => ref.where('email', '==', result.user.email)).get().subscribe((querySnapshot) => {
          console.log(querySnapshot.size)
          if (querySnapshot.size == 0) {
            // alert("user not exists");
            this.SetUserData(result.user, 0);
          } else {
            querySnapshot.forEach((doc: any) => {
              if (doc.data().uid === result.user.uid) {
                this.SetUserData(doc.data(), 1);
                console.log("User Data",)
                this.router.navigate(['calendar-dashboard']);
              }
              else {
                this.delete_User(result.user);
                this.SetUserData(result.user, 0);
                // alert("another")
              }
            });
          }
        })


        // if (this.checkemailExistance(result.user.email) === undefined || this.checkemailExistance(result.user.email) === null || this.checkemailExistance(result.user.email).length === 0) {
        //   this.SetUserData(result.user,0);
        // }
      }).catch((error) => {
        // window.alert(error);
      })
  }

  // Sign in with email/password
  SignIn(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        if (result.user.emailVerified) {
          this.ngZone.run(() => {
            this.router.navigate(['calendar-dashboard']);
          });
          localStorage.setItem('user', JSON.stringify(result.user));
        }
      }).catch((error) => {
        console.error(error);
      })
  }

  // Sign up with email/password
  SignUp(signupData) {
    return this.afAuth.createUserWithEmailAndPassword(signupData.value.email, signupData.value.password)
      .then((result) => {

        result.user['firstname'] = signupData.value.firstname;
        result.user['lastname'] = signupData.value.lastname;
        // this.SendVerificationMail(result.user.email);
        this.SendCustomVerificationMail(result.user.email, signupData.value.firstname + ' ' + signupData.value.lastname);
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${result.user.uid}`);
        const userData: User = {
          uid: result.user.uid,
          firstname: signupData.value.firstname,
          lastname: signupData.value.lastname,
          email: result.user.email,
          displayName: signupData.value.firstname + ' ' + signupData.value.lastname,
          photoURL: result.user.photoURL,
          emailVerified: result.user.emailVerified,
          creationDate: this.current_Date,
          regionwithtimezone: this.region_with_timezone,
          region: '',
          timezone: ''
        }
        //this.currentUserSubject.next(userData);
        userRef.set(userData, {
          merge: true
        })
      }).catch((error) => {
        console.error(error);
      })
  }

  async SendVerificationMail(email) {
    return await (await this.afAuth.currentUser).sendEmailVerification().then(() => {
      this.router.navigate(['verify-email', email]);
    });
  }

  async SendCustomVerificationMail(email, name) {
    let body = { email: email, name: name };
    return this.http.post("https://us-central1-superbrain-c2070.cloudfunctions.net/sendContactEmail", body).subscribe((res: any) => {
      this.router.navigate(['verify-email', email]);
    }, error => { });
  }


  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error)
      })
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */


  async SetUserData(user, num?: number) {

    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      displayName: user.firstname + ' ' + user.lastname,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      creationDate: user.creationDate,
      regionwithtimezone: user.regionwithtimezone,
      region: user.region,
      timezone: user.timezone
    }
    if (num == 0) {
      let tribeuserdata = {
        username: user.firstname + "_" + Math.floor(100000000 + Math.random() * 900000000),
        name: user.firstname + '' + user.lastname,
        email: user.email,
        password: "M2n1shlko@",
        externalId: user.uid,
        authorization: this.adminToken,
        role: "member"
      }
      await this.createTribeUser(tribeuserdata);
    }

    this.currentUserSubject.next(userData);
    localStorage.setItem('user', JSON.stringify(userData));
    userRef.set(userData, { merge: true });
  }

  SignOut() {
    return this.afAuth.signOut().then(() => {
      var logoutURL = window.open("https://asas.tribe.so/api/v1/logout");
      setTimeout(() => {
        logoutURL.close();
      }, 1000);
      localStorage.removeItem('user');
      this.currentUserSubject.next(null);
      this.router.navigate(['home']);
    })
  }

  checkemailExistance(email) {
    return this.userList.filter(x => x.email == email.toLowerCase());
  }

  getUserByUID(uid) {
    return this.userList.filter(x => x.uid == uid);
  }

  gerfirebaseUserbyUID(uid) {
    var object = this;
    this.afs.collection('users', ref => ref.where('uid', '==', uid)).get().subscribe((querySnapshot) => {
      if (querySnapshot.size > 0) {
        querySnapshot.forEach((doc: any) => {
          const userData: User = {
            uid: doc.data().uid,
            firstname: doc.data().firstname,
            lastname: doc.data().lastname,
            email: doc.data().email,
            displayName: doc.data().displayName,
            photoURL: doc.data().photoURL,
            emailVerified: doc.data().emailVerified,
            region: moment.tz.guess(),
            timezone: doc.data().timezone,
            creationDate: doc.data().creationDate,
            regionwithtimezone: doc.data().regionwithtimezone
          }
          let genarateSSOTOkenData = {
            sub: doc.data().uid,
            email: doc.data().email,
            name: doc.data().firstname + '' + doc.data().lastname
          }
          object.tribe.GenerateJWTSSOToken(genarateSSOTOkenData).subscribe((res: any) => {
            localStorage.setItem("SSOTOKEN", res.status);
            $("#tribesection").attr("src", "https://asas.tribe.so/auth/sso?jwt=" + res.status);
          });
          localStorage.setItem('user', JSON.stringify(userData));
          object.currentUserSubject.next(userData);
          object.ngZone.run(() => {
            if (this.router.url === "/login" || this.router.url === "/sign-up") {
              //console.log("123")
              object.router.navigate(['calendar-dashboard']);
            } else {
              object.router.navigate([this.router.url]);
            }
          });
        });
      } else { }
    });
  }


  updateData(data) {
    var object = this;
    const userData: User = {
      uid: data.uid,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      displayName: data.firstname + " " + data.lastname,
      photoURL: data.photoURL,
      emailVerified: data.emailVerified,
      region: data.region,
      timezone: data.timezone,
      creationDate: data.creationDate,
      regionwithtimezone: data.regionwithtimezone
    }
    this.afs.doc('users/' + data.uid).update(userData);
    localStorage.setItem('user', JSON.stringify(userData));
    object.currentUserSubject.next(userData);
    object.ngZone.run(() => {
      object.router.navigate(['calendar-dashboard']);
    });
  }

  delete_User(userdata) {
    this.tribe.GenerateAdminToken().subscribe((response: any) => {
      if (response.status_code == 200) {
        this.adminToken = 'Bearer ' + response.data.access_token;
        localStorage.setItem("adminToken", 'Bearer ' + response.data.access_token);
        this.tribe.getUserBasedOnEmail(this.adminToken, userdata.email).subscribe((response: any) => {
          var data = response.data;
          //  this.tribe.GetTribeUsersListWithToken('Bearer ' + response.data.access_token).subscribe((response: any) => {
          // if (response != undefined && response.successData.length > 0) {
          //  var data = response.successData.filter(x => x.email === userdata.email && x.externalId === userdata.uid);
          if (data.length > 0) {
            var reqdata = {
              authorization: this.adminToken,
              userid: data[0]._id
            };
            this.tribe.deleteUser(reqdata).subscribe((res: any) => {
            }, error => { console.log("Tribe error: ", error) });
          }
          localStorage.removeItem('user');
          this.currentUserSubject.next(null);
          this.router.navigate(['register-user']);
          //  }

          //   });
        });
      }
    }, error => {
      console.log("Error:", error);
    })
  }

  async createTribeUser(userdata) {
    await this.http.post(this.baseURL + "/createuser", userdata).subscribe((res: any) => { }, err => { console.log(err) });
  }

  async GetAllOnBoardingQuestions() {
    return new Promise((resolve, reject) => {
      let data = [];
      this.afs.collection("onboarding-questions").get().subscribe((querySnapshot) => {
        querySnapshot.forEach((doc: any) => {
          data.push(doc.data());
        })
        resolve(data);
      });
    });
  }


}
