import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FullCalendarComponent, CalendarOptions, DateSelectArg, EventClickArg, EventApi, CalendarApi, ViewApi } from '@fullcalendar/angular';
import { createEventId } from '../../event-utils';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from '../../services/auth.service'

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild('confirmBookingModal') confirmBookingModal: ElementRef;
  @ViewChild('confirmBookingCloseModal') confirmBookingCloseModal: ElementRef;
  @ViewChild('taskbooktaskopenModal') taskbooktaskopenModal: ElementRef;
  @ViewChild('deleteBookCloseModal') deleteBookCloseModal: ElementRef;

  questionList: any = [];
  first_question: any = "Test Question";
  second_question: any;
  third_question: any;
  currentUser: any;
  disabled: boolean = true;
  taskForm: FormGroup;
  submitted: boolean = false;
  items = ['1', '3', '5', '10', '15', '30', '45'];
  durationItem = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];
  answers: any = [];
  firstAnswer: any;
  playvideo = false;
  current_question: number;
  calendarOptions: CalendarOptions;
  eventList: any = []
  bookingselected = false;
  calenderEventsSelection: any[] = [];
  calendarEventSelect: any[] = [];
  clicked: boolean = false;
  bookingselctioncount = 0;
  calendarScroll: any = "";
  meetingbutton: any = "";
  bookmeeting: any;
  taskForDetails: any = [{ taskName: '', taskTime: '', taskDate: '', days: '', taskDuration: '', durationmin: '' }];
  remainder: any = [];
  fourth_question: any;
  final_disabled: boolean = true;
  first_Quiz_Ans: boolean = false;
  second_Quiz_Ans: boolean = false;
  first_one: number = 1;

  constructor(public afs: AngularFirestore, private formBuilder: FormBuilder, private router: Router, private common: CommonService,
    public authService: AuthService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
  }

  ngOnInit(): void {
    'use strict';
    this.taskForm = this.formBuilder.group({
      userId: [this.currentUser.uid],
      taskName: ['', [Validators.required]],
      date: [null],
      duration: ['',],
      days: [''],
      dateTime: [''],
      durationmin: [''],
      durationmin2: [''],
      duration2: [''],
      date2: [null],
      taskName2: ['', [Validators.required]],
      durationmin3: [''],
      duration3: [''],
      date3: [null],
      taskName3: ['', [Validators.required]],
    });

    this.getCalendarDatapubpriv();

    this.afs.collection("onboarding-questions").doc("joe59AhpUaeAn3keag58").get().subscribe(async (doc) => {
      // this.afs.collection("onboarding-questions").doc("OOfciUyg022vJjZ34mYO").get().subscribe(async (doc) => {
      if (doc.exists) {
        this.questionList.push(doc.data());
        this.afs.collection("onboarding-answer").doc(this.currentUser.uid).get().subscribe(async (document: any) => {
          if (document.exists) {
            console.log(document.data());
            if (!document.data().hasOwnProperty("first")) {
              this.first_question = this.questionList[0]["first"];
              this.current_question = 1;
            }
            else
             if (
              document.data().hasOwnProperty("first") && 
              !document.data().hasOwnProperty("second")) {
              this.first_question = "";
              this.second_question = this.questionList[0]["second"];
              this.current_question = 2;
            }
            else if (
              document.data().hasOwnProperty("first") && 
              document.data().hasOwnProperty("second") && !document.data().hasOwnProperty("third")) {
              this.first_question = "";
              this.second_question = "";
              this.third_question = this.questionList[0]["third"];
              this.current_question = 3;
            }
          //   else if (
          //     document.data().hasOwnProperty("first") &&
          //      document.data().hasOwnProperty("second") && document.data().hasOwnProperty("third") && !document.data().hasOwnProperty("fourth"))
          //  {
          //     this.third_question = "";
          //     this.first_question = "";
          //     this.second_question = "";
          //     this.fourth_question = "fourth";
          //     this.current_question = 3;
          //   }
            else if (
              document.data().hasOwnProperty("first") && 
              document.data().hasOwnProperty("second") && document.data().hasOwnProperty("third")) {
              this.router.navigate(["calendar-dashboard"]);
            }
          }
          else {
            console.log("questionList: ",doc.data());
            this.router.navigate(["/questions"]);
            this.first_question = this.questionList[0]["first"];
            this.current_question = 1;
          }
        });
      }
    });
  }

  get f() { return this.taskForm.controls; }

  async remainderSubmit() {

    var alltask: any = [];
    let taskvalue = '';
    this.submitted = true;
    console.log(this.taskForm);
    if (this.taskForm.invalid) {
      return;
    }

    taskvalue = 'Priority';
    alltask = await this.getallTask('remaindertasks', this.taskForm.value.userId);
    if (alltask.length > 2) {
      taskvalue = 'Master';
    }
    let firstPositionIndex = alltask.length;
    let secondPositionIndex = alltask.length + 1;
    let thirdPositionIndex = alltask.length + 2;

    const taskData = {
      taskId: this.afs.createId(),
      userId: this.taskForm.value.userId,
      taskName: this.taskForm.value.taskName,
      dueDate: this.taskForm.value.date,
      taskDuration: this.taskForm.value.duration,
      taskMin: this.taskForm.value.durationmin,
      days: this.taskForm.value.days,
      categoryList: taskvalue,
      postionIndex: firstPositionIndex
    }
    await this.AddNewRemainderTask(taskData, 'remaindertasks');

    const taskData2 = {
      taskId: this.afs.createId(),
      userId: this.taskForm.value.userId,
      taskName: this.taskForm.value.taskName2,
      dueDate: this.taskForm.value.date2,
      taskDuration: this.taskForm.value.duration2,
      taskMin: this.taskForm.value.durationmin2,
      days: this.taskForm.value.days,
      categoryList: taskvalue,
      postionIndex: secondPositionIndex
    }
    await this.AddNewRemainderTask(taskData2, 'remaindertasks');

    const taskData3 = {
      taskId: this.afs.createId(),
      userId: this.taskForm.value.userId,
      taskName: this.taskForm.value.taskName3,
      dueDate: this.taskForm.value.date3,
      taskDuration: this.taskForm.value.duration3,
      taskMin: this.taskForm.value.durationmin3,
      days: this.taskForm.value.days,
      categoryList: taskvalue,
      postionIndex: thirdPositionIndex
    }
    await this.AddNewRemainderTask(taskData3, 'remaindertasks');
    this.afs.collection("onboarding-answer").doc(this.currentUser.uid).update({ 'second': [taskData.taskId, taskData2.taskId, taskData3.taskId] }).then((success => {
      this.first_question = "";
      this.second_question = "";
      this.third_question = this.questionList[0]["third"];
      this.current_question = 3;
    }));
  }

  getallTask(tablename, userId) {
    return new Promise((resolve, reject) => {
      let data = [];
      this.afs.collection(tablename, ref => ref.where('userId', '==', userId)).get().subscribe((querySnapshot) => {
        querySnapshot.forEach((doc: any) => {
          data.push(doc.data());
        })
        resolve(data);
      });
    });
  }

  AddNewRemainderTask(taskData, tablename) {
    return new Promise<void>((resolve, reject) => {
      this.afs.collection(tablename).doc(taskData.taskId).set(taskData);
      resolve()
    })
  }

  selectValue(obj) {
    if (obj.target.checked) {
      this.firstAnswer = obj.target.defaultValue;
      this.disabled = false;
    }
  }

  validate() {
    if ((<HTMLInputElement>document.getElementById("secondAns")).value.length > 0) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  Next(obj) {
    if (obj === 'fourth') {
      this.afs.collection("onboarding-answer").doc(this.currentUser.uid).update({ 'third': 'Booked' }).then((success => {
        this.fourth_question = "";
        this.disabled = true;
        this.router.navigate(["calendar-dashboard"]);
      }));
      this.answers.push(this.firstAnswer);
    }
    else if (obj === 'second') {
      let second_answer = (<HTMLInputElement>document.getElementById("secondAns")).value;
      this.answers.push(second_answer);
      this.afs.collection("onboarding-answer").doc(this.currentUser.uid).set({ 'first': second_answer }).then((success => {
        this.first_question = "";
        this.second_question = this.questionList[0]["second"];
        this.current_question = 2;
      }));
    }
  }

  handleSelectAllow(info) {
    var start = info.start;
    var currentDate = new Date();
    if (start.getTime() > currentDate.getTime()) {
      if ((Math.abs(info.end - info.start) / 60000) > 60) {
        return false;
      } else {
        return true;
      }
    }
  }

  timeTo12HrFormat(time) {
    var timeformat = time.split("T");
    var time_part_array = timeformat[1].split(":");
    var ampm = '';

    if (time_part_array[0] >= 12) {
      ampm = 'PM';
    } else {
      ampm = 'AM';
    }

    if (time_part_array[0] > 12) {
      time_part_array[0] = time_part_array[0] - 12;
    }

    if (time_part_array[0].length > 1) {
      if (time_part_array[0].charAt(0) == '0') {
        time_part_array[0] = time_part_array[0].substring(1);
      }
    }

    if (time_part_array[0] == '0') {
      time_part_array[0] = '12';
    }
    var formatted_time = time_part_array[0] + ':' + time_part_array[1] + ampm;
    return formatted_time;
  }

  getCalendarDatapubpriv() {
    // this.loader = true;
    let object = this;
    let currentUserId: any;
    this.authService.currentUser.subscribe(x => currentUserId = x !== null ? x.uid : 0);
    let haveOwnEventOndate: any = [];
    const today = moment();
    this.afs.collection('tasks', ref => ref.where("taskDate", ">=", moment(today).weekday(0).format('YYYY-MM-DD')).where("taskDate", "<=", moment(today).weekday(6).format('YYYY-MM-DD'))).get().subscribe((querySnapshot) => {
      object.eventList = [];
      if (querySnapshot.size > 0) {
        querySnapshot.forEach((doc: any) => {
          if ((new Date().getTime() >= new Date(doc.data().taskStartTime).getTime() && new Date().getTime() <= new Date(doc.data().taskEndTime).getTime()) || (new Date(doc.data().taskStartTime).getTime() >= new Date().getTime())) {
            if ((doc.data().userId == currentUserId || doc.data().joinuserflag == 'false')) {
              let userId: any = '';

              if (doc.data().joinuserflaguserid != '') {
                userId = doc.data().joinuserflaguserid;
              } else {
                userId = doc.data().userId;
              }

              let todaysQuote = new Promise(function (result, rej) {
                object.afs.collection('users', ref => ref.where('uid', '==', userId)).get().subscribe((Snapshot) => {
                  // console.log(Snapshot.size);
                  if (Snapshot.size > 0) {

                    Snapshot.forEach((userdoc: any) => {
                      var storeddate = new Date(doc.data().taskStartTime);
                      var classname = 'otherUserEvent';
                      var colorcode = 'rgba(230,230,230,0.5)';
                      if ((doc.data().userId == currentUserId || doc.data().joinuserflaguserid == currentUserId)) {
                        classname = 'currentUserEvent';
                        colorcode = 'rgba(9, 162, 164, 0.5)';
                        haveOwnEventOndate.push(new Date(doc.data().taskStartTime).getTime());
                      }

                      let data = {
                        id: doc.data().taskId,
                        taskId: doc.data().taskId,
                        userId: doc.data().userId,
                        title: userdoc.data().displayName.split(' ')[0],
                        taskName: userdoc.data().displayName.split(' ')[0],
                        taskemail: userdoc.data().email,
                        tasktittle: doc.data().taskName,
                        taskuserId: doc.data().userId,
                        start: doc.data().taskStartTime,
                        endtime: doc.data().taskEndTime,
                        joinuserflag: doc.data().joinuserflag,
                        taskDate: doc.data().taskDate,
                        imageUrl: userdoc.data().photoURL,
                        taskTime: object.timeTo12HrFormat(doc.data().taskStartTime),
                        taskDuration: doc.data().taskDuration,
                        bookedManual: doc.data().bookedManual,
                        days: doc.data().days,
                        dateTime: doc.data().dateTime,
                        joinuserflaguserid: doc.data().joinuserflaguserid,
                        joinuserflagtaskid: doc.data().joinuserflagtaskid,
                        className: classname,
                        color: colorcode,
                        display: 'block',
                        textColor: '#080808',
                        borderColor: colorcode,
                        time: storeddate.getTime()
                      };
                      result(data);

                    });
                  }
                }, error => { console.log("err :", error) });
              });
              object.eventList.push(todaysQuote);
            }
          }
        });
        Promise.all(object.eventList)
          .then((results) => {
            // object.eventList=[];
            object.eventList = results;
            this.calendarOptions = {
              firstDay: 1,
              expandRows: true,
              nowIndicator: true,
              timeZone: 'local',
              validRange: function (now) {
                return {
                  start: new Date()
                }
              },
              initialView: 'timeGridWeek',
              allDaySlot: false,
              displayEventTime: false,
              eventBackgroundColor: 'rgba(9, 162, 164, 0.5)',
              eventBorderColor: 'rgba(9, 162, 164, 0.5)',
              eventTextColor: 'black',
              eventDisplay: 'block',
              progressiveEventRendering: true,
              headerToolbar: {
                left: '',
                center: 'title',
                right: ''
              },
              views: {
                timeGridWeek: { // name of view
                  titleFormat: { month: 'short', day: '2-digit' }
                }
              },
              events: results,
              weekends: true,
              eventDragStart: function (info) {
                this.ToasterAlert("error", "Feature is restricted!");

                alert('Feature is restricted');
                return false;
              },
              height: "auto",
              contentHeight: "auto",
              dayMaxEvents: 3,
              aspectRatio: 1.5,
              scrollTime: '00:00',
              slotDuration: '01:00:00',
              selectable: true,
              selectAllow: this.handleSelectAllow.bind(this),
              editable: false,
              droppable: false,
              eventStartEditable: false,
              eventDurationEditable: false,
              select: this.handleDateSelect.bind(this),
              eventClick: this.handleEventClick.bind(this),
              eventContent: function (arg, createElement) {
                var innerText, innerid, imageUrl;
                let italicEl = document.createElement('span');
                italicEl.className = "pp-name";
                if (arg.event._def.extendedProps.imageUrl) {
                  innerText = arg.event._def.title;
                  imageUrl = arg.event._def.extendedProps.imageUrl;
                  innerid = arg.event._def.publicId;
                  var classnamei = 'itsnotmyevent';
                  if (arg.event._def.extendedProps.taskuserId == currentUserId || arg.event._def.extendedProps.joinuserflaguserid == currentUserId) {
                    var classnamei = 'itsmyevent';
                  }
                  italicEl.innerHTML = '<img style="width:20px; height:20px; border-radius:50px; margin-right:3px;" class="imageUser" src="' + imageUrl + '"/>' + innerText + '<br /><div class="btn-repeat ' + classnamei + '"></div>';
                } else {

                  innerText = arg.event._def.title;
                  innerid = arg.event._def.publicId;
                  italicEl.innerHTML = '<span>' + innerText + '</span></div>';
                }
                let arrayOfDomNodes = [italicEl]
                return { domNodes: arrayOfDomNodes }
              },
            };
            var doc = document.documentElement;
            var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
            setTimeout(function () {
              if (object.calendarScroll) {
                var posi = document.getElementsByClassName('fc-timegrid-body')[0];
                posi.scrollTop = object.calendarScroll;
              } else {
                var z = document.querySelector('[data-time="06:00:00"]');
                z.scrollIntoView();
              }
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = top;

            }, 500);
          }).catch(err => console.log(err));
      } else {
        this.calendarOptions = {
          firstDay: 1,
          expandRows: true,
          nowIndicator: true,
          timeZone: 'local',
          validRange: function (now) {
            return {
              start: new Date()
            }
          },
          initialView: 'timeGridWeek',
          allDaySlot: false,
          displayEventTime: false,
          eventBackgroundColor: 'rgba(9, 162, 164, 0.5)',
          eventBorderColor: 'rgba(9, 162, 164, 0.5)',
          eventTextColor: 'black',
          eventDisplay: 'block',
          progressiveEventRendering: true,
          headerToolbar: {
            left: '',
            center: 'title',
            right: ''
          },
          views: {
            timeGridWeek: { // name of view
              titleFormat: { month: 'short', day: '2-digit' }
            }
          },
          events: [],
          weekends: true,
          eventDragStart: function (info) {
            this.ToasterAlert("error", "Feature is restricted!");

            alert('Feature is restricted');
            return false;
          },
          height: "auto",
          contentHeight: "auto",
          dayMaxEvents: 3,
          aspectRatio: 1.5,
          scrollTime: '00:00',
          slotDuration: '01:00:00',
          selectable: true,
          selectAllow: this.handleSelectAllow.bind(this),
          editable: false,
          droppable: false,
          eventStartEditable: false,
          eventDurationEditable: false,
          select: this.handleDateSelect.bind(this),
          eventClick: this.handleEventClick.bind(this),
          eventContent: function (arg, createElement) {
            var innerText, innerid, imageUrl;
            let italicEl = document.createElement('span');
            italicEl.className = "pp-name";
            if (arg.event._def.extendedProps.imageUrl) {
              innerText = arg.event._def.title;
              imageUrl = arg.event._def.extendedProps.imageUrl;
              innerid = arg.event._def.publicId;
              var classnamei = 'itsnotmyevent';
              if (arg.event._def.extendedProps.taskuserId == currentUserId || arg.event._def.extendedProps.joinuserflaguserid == currentUserId) {
                var classnamei = 'itsmyevent';
              }
              italicEl.innerHTML = '<img style="width:20px; height:20px; border-radius:50px; margin-right:3px;" class="imageUser" src="' + imageUrl + '"/>' + innerText + '<br /><div class="btn-repeat ' + classnamei + '"></div>';
            } else {

              innerText = arg.event._def.title;
              innerid = arg.event._def.publicId;
              italicEl.innerHTML = '<span>' + innerText + '</span></div>';
            }
            let arrayOfDomNodes = [italicEl]
            return { domNodes: arrayOfDomNodes }
          },
        };
      }
    });
  }

  async handleDateSelect(selectInfo: DateSelectArg) {
    if (this.bookingselected != true) {
      this.calenderEventsSelection = [];
      this.calendarEventSelect = [];
    }
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    const calendarApi = selectInfo.view.calendar;
    let eventid = createEventId();
    var indexdata = 0;
    this.eventList.forEach(eventdata => {
      if (eventdata.start == selectInfo.startStr) {
        indexdata++;
      }
    });
    var calendardata = 0;
    this.calenderEventsSelection.forEach(element => {
      if (element.start == selectInfo.startStr) {
        calendardata++;
      }
    });

    if (calendardata > 0) {
      this.ToasterAlert("success", "<i class='fa fa-exclamation-triangle'></i> This session is already selected for booking!");
      calendarApi.unselect();
      return false;
    }

    if (indexdata > 0) {
      this.ToasterAlert("success", "<i class='fa fa-exclamation-triangle'></i> This session is already Booked!");
      calendarApi.unselect();
      this.getCalendarDatapubpriv();
      return false;
    } else {

      calendarApi.addEvent({
        id: eventid,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      });
      const data = {
        id: eventid,
        start: moment(selectInfo.startStr).tz("America/Los_Angeles").format(),
        end: moment(selectInfo.endStr).tz("America/Los_Angeles").format(),
        allDay: selectInfo.allDay
      }
      this.calendarEventSelect.push(selectInfo);
      this.calenderEventsSelection.push(data);
      this.bookingselctioncount = this.calenderEventsSelection.length;
      this.bookingselected = true;
      this.clicked = false;
    }
  }

  handleEventClick(clickInfo: EventClickArg) {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    var taskDatetime = moment(clickInfo.event._def.extendedProps.dateTime).format();
    taskDatetime = new Date(taskDatetime);
    var currentDate = new Date();
    if (taskDatetime.getTime() >= currentDate.getTime()) {
      if (clickInfo.event._def.extendedProps.taskuserId == this.currentUser.uid || clickInfo.event._def.extendedProps.joinuserflag == 'True') {
      } else {
        this.meetingbutton = clickInfo.event._def.title;
        if (clickInfo.event._def.extendedProps.joinuserflag != 'none') {
          if (clickInfo.event._def.extendedProps.taskemail === "johndoe@mailinator.com") {
            this.ToasterAlert("success", "<i class=''fa fa-exclamation-triangle'></i> Matching...");
            return;
          }
          this.bookmeeting = clickInfo.event._def.publicId;
          this.taskbooktaskopenModal.nativeElement.click();
        } else
          if (clickInfo.event._def.extendedProps.joinuserflag == 'none') {
            this.ToasterAlert("warning", "<i class='fa fa-exclamation-triangle'></i> Gmail Events cannot be edited!");
            return false;
          } else {
            this.ToasterAlert("warning", "<i class='fa fa-exclamation-triangle'></i> Self Created Session cannot be booked!");
            return false;
          }
      }
    } else {
      if (clickInfo.event._def.extendedProps.joinuserflag == 'none') {
        this.ToasterAlert("warning", "<i class='fa fa-exclamation-triangle'></i> Gmail Events cannot be edited!");
        return false;
      } else {
        this.ToasterAlert("warning", "<i class='fa fa-exclamation-triangle'></i> Past events can not be edited or booked!");
        return false;
      }
    }
  }

  checkspecificUsersSlot(time, userId) {
    return new Promise((resolve, reject) => {
      let data = [];
      this.afs.collection('tasks', ref => ref.where('userId', '==', userId)).get().subscribe((querySnapshot) => {
        querySnapshot.forEach((doc: any) => {
          if (new Date(time).getTime() == new Date(doc.data().taskStartTime).getTime()) {
            data.push(doc.data());
          }
        })
        resolve(data);
      })
    });
  }

  confirmremoveevent() {
    let calendarApi = this.calendarComponent.getApi()
    const calendarEvent = this.calenderEventsSelection;
    calendarEvent.forEach(eventid => {
      let event = calendarApi.getEventById(eventid.id)
      if (event != null) {
        event.remove();
      }
    });
    this.bookingselctioncount = 0;
    this.bookingselected = false;
    this.confirmBookingCloseModal.nativeElement.click();
  }

  cancelevent() {
    this.ToasterAlert("success", "<i class='fa fa-exclamation-triangle'></i> Please proceed to book session!");
    this.confirmBookingCloseModal.nativeElement.click();
  }

  async continueBooking() {
    var docum = document.getElementsByClassName('fc-timegrid-body')[0];
    this.calendarScroll = (window.pageYOffset || docum.scrollTop) - (docum.clientTop || 0);

    this.clicked = true;
    var dataSelected = this.calenderEventsSelection;
    dataSelected.forEach(async (element, index) => {
      let remainder: any;
      remainder = await this.checkspecificUsersSlot(element.start, this.currentUser.uid);
      if (remainder.length > 0) {
        this.ToasterAlert("warning", "You are already having a scheduled meeting at this time!");
        // this.loader = false;
        this.confirmremoveevent();
        return false;
      }
      const taskData = {
        taskId: this.afs.createId(),
        userId: this.currentUser.uid,
        taskName: 'FocusBlocks',
        taskStartTime: element.start,
        taskEndTime: element.end,
        taskDate: element.start.split('T')[0],
        bookedManual: 'false',
        days: '',
        dateTime: element.start,
        joinuserflag: 'false',
        joinuserflagtaskid: '',
        joinuserflaguserid: '',
        isRemiderEmailSent: false
      };

      this.afs.collection('tasks').doc(taskData.taskId).set(taskData);
      /*continue booking*/
      // var event = {
      //   'summary': 'ClubDone Session',
      //   'location': 'ClubDone Web Meeting',
      //   'description': 'Get ready for 50-minutes of super-productivity! To make changes to this go to your dashboard www.clubdone.com/Dashboard',
      //   'start': {
      //     'dateTime': element.start,
      //     'timeZone': 'America/Los_Angeles'
      //   },
      //   'end': {
      //     'dateTime': element.end,
      //     'timeZone': 'America/Los_Angeles'
      //   },
      //   'attendees': [
      //     { 'email': this.currentUser.email },
      //   ],
      //   'reminders': {
      //     'useDefault': false,
      //     'overrides': [
      //       { 'method': 'email', 'minutes': 24 * 60 },
      //       { 'method': 'popup', 'minutes': 10 }
      //     ]
      //   }
      // };
      /*Genrating calendar requests*/
      // var request = gapi.client.calendar.events.insert({
      //   'calendarId': 'jenhoustan@gmail.com',
      //   'resource': event,
      //   'maxAttendees': 1,
      //   'sendNotifications': true
      // });

      // /*Executing calendar requests*/
      // request.execute(function (event) {
      //   console.log("event: ", event)
      //   console.log('Event created: ' + event.htmlLink);
      // });
      // this.ToasterAlert("success", "<i class='fa fa-exclamation-triangle'></i> Meeting Booked!");
      let notificationMessage = document.getElementById("book-meeting-notification") as HTMLInputElement;
      notificationMessage.style.display = "block";
      setTimeout(() => {
        notificationMessage.style.display = "none";
      }, 5500);

      // if (this.preferedGroup !== null) {
      // console.log("Group preference exists");
      //  this.RandomMemberofPreferedGroupJoinEventMatch(taskData);
      // }
      this.confirmremoveevent();
      this.disabled = false;
      this.getCalendarDatapubpriv();

    });
  }

  sure_book_it() {
    var docum = document.getElementsByClassName('fc-timegrid-body')[0];
    this.calendarScroll = (window.pageYOffset || docum.scrollTop) - (docum.clientTop || 0);

    this.disabled = false;
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    // console.log(this.eventList.length);
    this.taskForDetails = this.eventList.filter(x => x.id == this.bookmeeting);

    if (this.taskForDetails.length > 0) {
      var startdateTime = new Date(this.taskForDetails[0].start);
      var enddateTime = new Date(this.taskForDetails[0].endtime);
      var startTime = moment(startdateTime).tz("America/Los_Angeles").format();
      var endTime = moment(enddateTime).tz("America/Los_Angeles").format();
      this.remainder = this.checkspecifictime(startTime, this.currentUser.uid);
      let remainder2: any;
      remainder2 = this.checkspecificUsersSlot(startTime, this.currentUser.uid);

      if (remainder2.length > 0) {
        this.ToasterAlert("warning", "<i class='fa fa-exclamation-triangle'></i>  You are already having a scheduled meeting at this time!");
        this.confirmremoveevent();
        this.deleteBookCloseModal.nativeElement.click();

        return;
      } else if (this.remainder.length == 1) {
        const taskData = {
          taskId: this.bookmeeting,
          joinuserflag: 'True',
          joinuserflagtaskid: this.remainder[0].taskId,
          joinuserflaguserid: this.currentUser.uid,
          isRemiderEmailSent: false
        }
        this.updateTask(taskData, 'tasks');

        const updateData = {
          taskId: this.remainder[0].taskId,
          joinuserflag: 'True',
          bookedManual: 'True',
          joinuserflagtaskid: this.bookmeeting,
          joinuserflaguserid: this.taskForDetails[0].taskuserId,
          isRemiderEmailSent: false
        }
        this.updateTask(taskData, 'tasks');

        const inviteData = {
          'EventStart': this.taskForDetails[0].start,
          'Eventend': this.taskForDetails[0].endtime,
          'EventCreatedBy': this.taskForDetails[0].taskemail,
          'EventBookedBy': this.currentUser.email,
        };
        // this.ToasterAlert("success", "<i class='fa fa-exclamation-triangle'></i> Meeting Booked!");
        let notificationMessage = document.getElementById("book-meeting-notification") as HTMLInputElement;
        notificationMessage.style.display = "block";
        setTimeout(() => {
          notificationMessage.style.display = "none";
        }, 5500);
      } else {
        var taskIdnew = this.afs.createId();
        const taskData = {
          taskId: this.bookmeeting,
          joinuserflag: 'True',
          joinuserflagtaskid: taskIdnew,
          joinuserflaguserid: this.currentUser.uid,
          isRemiderEmailSent: false
        }
        this.updateTask(taskData, 'tasks');

        const createTask = {
          taskId: taskIdnew,
          userId: this.currentUser.uid,
          taskName: this.taskForDetails[0].tasktittle,
          taskStartTime: startTime,
          taskEndTime: endTime,
          taskDate: moment(startTime).tz("America/Los_Angeles").format("YYYY-MM-DD"),
          days: '',
          bookedManual: 'True',
          dateTime: startTime,
          joinuserflag: 'True',
          joinuserflagtaskid: this.bookmeeting,
          joinuserflaguserid: this.taskForDetails[0].taskuserId,
          isRemiderEmailSent: false
        }
        this.AddNewRemainderTask(createTask, 'tasks');
        const inviteData = {
          'EventStart': this.taskForDetails[0].start,
          'Eventend': this.taskForDetails[0].endtime,
          'EventCreatedBy': this.taskForDetails[0].taskemail,
          'EventBookedBy': this.currentUser.email,
        };

        // this.ToasterAlert("success", "<i class='fa fa-exclamation-triangle'></i> Meeting Booked!");
        let notificationMessage = document.getElementById("book-meeting-notification") as HTMLInputElement;
        notificationMessage.style.display = "block";
        setTimeout(() => {
          notificationMessage.style.display = "none";
        }, 5500);
      }
    }
    this.deleteBookCloseModal.nativeElement.click();
    this.getCalendarDatapubpriv();

  }

  removeSelectedBooking() {
    this.confirmBookingModal.nativeElement.click();
  }

  ToasterAlert(type, message) {
    this.common.Toaster(type, message);
  }

  checkspecifictime(time, userId) {
    return new Promise((resolve, reject) => {
      let data = [];
      this.afs.collection('tasks', ref => ref.where('userId', '==', userId)).get().subscribe((querySnapshot) => {
        querySnapshot.forEach((doc: any) => {
          if (time == doc.data().dateTime && doc.data().joinuserflag != 'True') {
            data.push(doc.data());
          }
        })
        resolve(data);
      })
    });
  }

  updateTask(data, tablename) {
    return new Promise<void>((resolve, reject) => {
      this.afs.doc(tablename + '/' + data.taskId).update(data);
      resolve()
    })
  }

  playVideo() {
    var videoElem = document.getElementById("videoele") as HTMLMediaElement;
    videoElem.play();
  }

  videoEnd() {
    this.playvideo = false;
    this.afs.collection("onboarding-answer").doc(this.currentUser.uid).update({ 'fourth': true }).then(() => { });
    this.fourth_question = "fourth";
  }

  FirstQuiz(obj, id) {
    $('input[name="fav_language"]').prop('disabled', true);
    if (id == "opt1") {
      $("#" + id).html("<i class='fa fa-check ml-3 text-success' aria-hidden='true' style='margin-top: 12px'></i>");
    } else {
      $("#" + id).html("<i class='fa fa-times ml-3 text-danger' aria-hidden='true' style='margin-top: 12px'></i>");
    }
    this.first_Quiz_Ans = true;
    if (this.second_Quiz_Ans && this.first_Quiz_Ans) {
      this.final_disabled = false;
    }
  }

  SecondQuiz(obj, id) {
    $('input[name="second_language"]').prop('disabled', true);
    if (id == "opt22") {
      $("#" + id).html("<i class='fa fa-check ml-3 text-success' aria-hidden='true' style='margin-top: 12px'></i>");
    } else {
      $("#" + id).html("<i class='fa fa-times ml-3 text-danger' aria-hidden='true' style='margin-top: 12px'></i>");
    }
    this.second_Quiz_Ans = true;
    if (this.second_Quiz_Ans && this.first_Quiz_Ans) {
      this.final_disabled = false;
    }
  }

  submitQuiz(para) {
    if (para === 1) {
      this.first_one = 2;
      return;
    }
    else if (para === 2) {
      this.afs.collection("onboarding-answer").doc(this.currentUser.uid).set({ 'first': "Done" }).then(() => {
        this.first_question = "";
        this.second_question = this.questionList[0]["second"];
        this.current_question = 1;
      });
    }
  }




}
