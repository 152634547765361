import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Post } from "./post.modal";
import { Comment } from "./comment.modal";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser"; 
import {CustomerService} from '../../services/customers/customer.service'

@Component({
  selector: 'app-post', 
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})
export class PostComponent implements OnInit {
  //@ViewChild('postText') addBtn: ElementRef;
 
  public postList: Post[];
  public post: Post;
  public comment: any;
  
  constructor(private sanitizer: DomSanitizer,private service:CustomerService) {
    // this.commonservice.postEdit_Observable.subscribe(res => {
    //   this.addBtn.nativeElement.click();
  //});
    this.post = new Post();
    this.postList = [];
    // const samplePostImage = new Post();
    // samplePostImage.postType = 'Image';
    // samplePostImage.postValue = ''

    this.postList = [];
  }

  public postText() {

    this.postList.unshift(this.post);
    this.post = new Post();
  }

  public postImage(event) {

    // var upload = confirm("Are you sure to upload this image");
    // if (upload == true) {
    // const refElement = event.target;
    // const uploadedFile = refElement.files[0];
    // let uploadedFileAsUrl = URL.createObjectURL(uploadedFile);
    // uploadedFileAsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
    //   uploadedFileAsUrl
    // );
    // this.post.postType = "Image";
    // this.post.postValue = uploadedFileAsUrl;
    //   this.postList.unshift(this.post);
    // } else {
    //   alert("You cancelled uploading the selected image")
    // }    

    //Re- initialize
    this.post = new Post();
  }

  public postVideo(event) {
    // var upload = confirm("Are you sure to upload this Video");
    // if (upload == true) {
    // const refElement = event.target;
    // const uploadedFile = refElement.files[0];
    // let uploadedFileAsUrl = URL.createObjectURL(uploadedFile);
    // uploadedFileAsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
    //   uploadedFileAsUrl
    // );
    // this.post.postType = "Video";
    // this.post.postValue = uploadedFileAsUrl;

    // this.postList.unshift(this.post);
    // } else {
    //   alert("You cancelled uploading the selected video")
    // }

    //Re- initialize
    this.post = new Post();
  }

  public editPost(item : Post) {
    console.log("editpostcalled", item);

  }

  // public updateComment(comment: Comment) {
  //   console.log("update comment called", comment);
  //   comment.draft = comment.Value;
  //   comment.updateMode = true;
  // }

  public editPostDone(item: Post) {
    // console.log("update post done called", item);
    // comment.Value = comment.draft;
    // comment.updateMode = false;
    // comment.draft = "";
  }

  public deletePost(postIndex) {
    
    this.postList.splice(postIndex, 1);
  }

  public deleteComment(commentIndex,item : Post) {
   
    item.commentList.splice(commentIndex, 1);
  }

  public likePost(item: Post) {
    let val = item.status;
    if (val === "none") { item.likeCount += 1; } 
    else if (val === "unlike") { item.likeCount += 1; item.unlikeCount -= 1; } 
    else { item.likeCount -= 1; }
    item.status = val === "like" ? "none" : "like";
  }

  public unlikePost(item: Post) {
    let val = item.status;
    if (val === "none") { item.unlikeCount += 1; }
    else if (val === "like") { item.likeCount -= 1; item.unlikeCount += 1; }
    else { item.unlikeCount -= 1; }
    item.status = val === "unlike" ? "none" : "unlike";
  }

  public addComment(item: Post) {
    debugger;
    console.log("commmentsalll", item.commentList);
    // let comment = new Comment(item.draftComment);
    // item.commentList.unshift(comment: Comment);
    // item.draftComment = "";
  }

  public updateComment(comment: Comment) {
    console.log("update comment called", comment);
    comment.draft = comment.Value;
    comment.updateMode = true;
  }

  public updateCommentDone(comment: Comment) {
    console.log("update comment done called", comment);
    comment.Value = comment.draft;
    comment.updateMode = false;
    comment.draft = "";
  }

  public likeComment(item: Comment) {
    // api call
    debugger;
    let val = item.status;
    if (val === "none") {
      item.likeCount += 1;
    } else if (val === "unlike") {
      item.likeCount += 1;
      item.unlikeCount -= 1;
    } else {
      item.likeCount -= 1;
    }
    item.status = val === "like" ? "none" : "like";
  }

  public unlikeComment(item: Comment) {
    // api call
    let val = item.status;
    if (val === "none") {
      item.unlikeCount += 1;
    } else if (val === "like") {
      item.likeCount -= 1;
      item.unlikeCount += 1;
    } else {
      item.unlikeCount -= 1;
    }
    item.status = val === "unlike" ? "none" : "unlike";
  }

  ngOnInit() {
    
  }

}
