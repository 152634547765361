import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AwsChimeService } from "../../services/aws-chime/aws-chime.service";


@Component({
  selector: 'app-home-img',
  templateUrl: './home-img.component.html',
  styleUrls: ['./home-img.component.css']
})
export class HomeImgComponent implements OnInit {

  ContactForm: FormGroup;
  submitted = false;
  message: any;

  constructor(
    private formBuilder: FormBuilder,
    public cloudFunction: AwsChimeService
  ) { }

  ngOnInit(): void {
    this.ContactForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      name: ['', [Validators.required, Validators.minLength(8)]]
    });
  }
  get f() { return this.ContactForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.ContactForm.invalid) {
      return;
    }
    this.cloudFunction.SendContactEmail(this.ContactForm.value.email, this.ContactForm.value.name).subscribe((response: any) => {
      if (response.status === 200) {
        this.message = "Form submitted successfully!";
        setTimeout(() => {
          this.message = "";
        }, 5000);
        this.ContactForm.reset();
        this.submitted = false;
      }
    })
  }

}
