<div class="background pb-5">
    <img src="../../../assets/PEAK-FOCUS.jpeg" class="center">
    <div class="container mt-5">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h3 class="text-uppercase">Contact</h3>
                <hr>
                <div class="contact-me border-gradient border-gradient-hidden-xs ">
                    <div class="row m0">
                        <div class="col-sm-12 text-center">
                            <div class="contact-form border-gradient-visible-xs">
                                <form class="form-styler" [formGroup]="ContactForm" (ngSubmit)="onSubmit()">
                                    <div class="form-group">
                                        <input type="text" formControlName="name" placeholder="Name" name="name"
                                            autocomplete="false">
                                        <div *ngIf="submitted && f.name.errors" class="alert-error">
                                            <div *ngIf="f.name.errors.required">Name is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input type="email" formControlName="email" placeholder="Email" name="email">
                                        <div *ngIf="submitted && f.email.errors" class="alert-error">
                                            <div *ngIf="f.email.errors.required">Email is required</div>
                                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                        </div>
                                    </div>
                                    <div class="btn submit-form form-sender">
                                        <input type="submit" value="send">
                                        <span class="error"></span>
                                    </div>
                                </form>
                                <div class="form-message">
                                    <p>{{message}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="map-canvas"></div>
                    <div class="closed"></div>
                </div>
            </div>
        </div>
    </div>

</div>