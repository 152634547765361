<div class="container">
  <div class="limiter">
    <div class="alert alert-danger alert-dismissible fade show scstcss" role="alert" *ngIf="errorEmail">
      <span [innerHTML]="errorEmail"></span>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closeForgotModel('UI')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="container bg-white sign-up shadow">
      <div class="px-5 my-5 py-5">
        <div class="row align-items-center">
          <div class="col-md-12">
            <form class="login100-form validate-form" [formGroup]="signupForm" (ngSubmit)="onSubmit()"
              autocomplete="off">
              <img class="logo" height="30" src="../../assets/template/img/Peak-Focus-Logo.png" />
              <span class="login100-form-title p-b-33 text-center">
                <img height="30" height="30" src="../../assets/template/img/waving-hand.png" />
                Let's Sign you in.
              </span>
              <div class="row">
                <div class="col-md-12 d-md-flex">
                  <a href="javascript:void(0)" class="btn btn-google btn-user btn-block"
                    (click)="authService.GoogleAuth('register')">
                    <img src="../../../assets/btn_google_signup_dark_normal_web@2x.png" />
                  </a>
                  <a href="javascript:void(0)" class="btn btn-facebook btn-user btn-block"
                    (click)="authService.FacebookAuth('register')">
                    <img src="../../../assets/facebook-icon.png" />
                  </a>
                </div>
              </div>
              <hr />

              <div class="alert alert-warning" role="alert">
                <p>Password must be at least 8 characters long.</p>
                <p>Password must contain at least one-one lowercase, uppercase, alphabetic, numeric, special symbols.
                </p>
              </div>
              <div class="row ">
                <div class="col-md-6">
                  <label>First Name</label>
                  <div class="wrap-input100 validate-input">
                    <input class="input100" type="text" name="firstname" formControlName="firstname"
                      [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }">
                    <span class="focus-input100-1"></span>
                    <span class="focus-input100-2"></span>
                  </div>
                  <div *ngIf="submitted && f.firstname.errors" class="text-danger validate-error">
                    <div *ngIf="f.firstname.errors.required">Firstname is required !</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label>Last Name</label>
                  <div class="wrap-input100 validate-input ">
                    <input class="input100" type="text" name="lastname" formControlName="lastname"
                      [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }">
                    <span class="focus-input100-1"></span>
                    <span class="focus-input100-2"></span>
                  </div>
                  <div *ngIf="submitted && f.lastname.errors" class="text-danger validate-error">
                    <div *ngIf="f.lastname.errors.required">Lastname is required !</div>
                  </div>
                </div>
              </div>

              <div class="row mb-4">
                <div class="col-md-12">
                  <label class="mt-2">Email</label>
                  <div class="wrap-input100 validate-input">
                    <input class="input100" type="text" name="email" formControlName="email" placeholder=""
                      [ngClass]="{ 'is-invalid': submitted && f.email.errors }" autocomplete="off">
                    <span class="focus-input100-1"></span>
                    <span class="focus-input100-2"></span>
                  </div>
                  <div *ngIf="submitted && f.email.errors" class="text-danger validate-error">
                    <div *ngIf="f.email.errors.required">Email is required !</div>
                    <div *ngIf="f.email.errors.email">Email must be a valid email address !</div>
                  </div>
                </div>
              </div>

              <h5>Choose a Secure Password</h5>
              <ul>
                <li>At least 8 characters—the more characters, the better.</li>
                <li>A mixture of both uppercase and lowercase letters.</li>
                <li>A mixture of letters and numbers.</li>
                <li>Inclusion of at least one special character, e.g., ! @ # ? ]</li>
              </ul>
              <div class="wrap-input100 validate-input">
                <input class="input100" type="password" formControlName="password" name="pass"
                  placeholder="8+ characters" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                  autocomplete="off">
                <span class="focus-input100-1"></span>
                <span class="focus-input100-2"></span>
              </div>
              <div *ngIf="submitted && f.password.errors" class="text-danger validate-error">
                <div *ngIf="f.password.errors.required">Password is required !</div>
                <div *ngIf="f.password.errors.minlength">Password must be at least 8 characters !</div>
              </div>
              <label class="mt-2">Confirm Password</label>
              <div class="wrap-input100 validate-input">
                <input class="input100" type="password" formControlName="confirmPassword" name="pass"
                  placeholder="8+ characters" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }">
                <span class="focus-input100-1"></span>
                <span class="focus-input100-2"></span>
              </div>
              <div *ngIf="submitted && f.confirmPassword.errors" class="text-danger validate-error">
                <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required !</div>
                <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match !</div>
              </div>
              <app-password-strength [passwordToCheck]="signupForm.value.password"
                (passwordStrength)="passwordValid($event)"></app-password-strength>

              <div class="container-login100-form-btn m-t-20">
                <button type="submit" class="btn theme-gradient-reverse btn-user btn-block"
                  [disabled]="!passwordIsValid">
                  Signup
                </button>
              </div>

              <div class="divforgotpassword text-center mt-3">
                <a href="javascript:void(0)" routerLink="/login">Already have an Account</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>