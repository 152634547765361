<div class="container">
  <div class="row">
    <div class="col-md-6 m-auto">
      <div class="card verfiy-email mt-5">
        <div class="card-body">
          <div class="displayTable">
            <div class="displayTableCell">
              <div class="authBlock">
                <img src="../../assets/template/img/verify-email.png">
                <div class="head text-center">
                  <h4 class="text-center mb-0">Thanks!</h4>
                  <h6>Verify your email address</h6>
                </div>
                <div class="formGroup" *ngIf="email">
                  <p class="text-center mb-0">We have sent a confirmation email to <strong>{{email}}</strong>.</p>
                  <p class="text-center">Please check your email and click on the link to verfiy your email address.</p>
                </div>
                <div class="formGroup text-center mb-3">
                  <button type="button" class="btn theme-gradient px-5"
                    (click)="authService.SendVerificationMail(email)">
                    <i class="fas fa-redo-alt"></i>
                    Resend Verification Email
                  </button>
                </div>
              </div>
              <div class="redirectToLogin text-center">
                <span>Go back to?<span class="redirect" routerLink="/login"> Sign in</span></span>
              </div>
              <img class="logo" height="30" src="../../assets/template/img/Peak-Focus-Logo.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>