import { Component, OnChanges, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AuthService } from '../../src/app/services/auth.service';
import { User } from './services/user';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnChanges {
  title = 'Jen';
  currentUser: User;
  toggle: boolean = false;
  currentUrl: boolean = true;
  addDynamicClass: boolean = false;
  music: boolean = false;
  questionList: any = [];
  color: any = "#FF5733";
  width: any = "100%"
  question: any;
  isShown: boolean = false;

  first_AudioTrack: any = [
    {
      url: "../assets/DeepWork.mp3",
      title: "Deep Work",
      cover: "https://i1.sndcdn.com/artworks-000249294066-uow7s0-t500x500.jpg"
    }
  ];
  Second_AudioTrack: any = [
    {
      url: "../assets/Creative.mp3",
      title: "Creative",
      cover: "https://i1.sndcdn.com/artworks-000249294066-uow7s0-t500x500.jpg"
    }
  ];
  third_AudioTrack: any = [
    {
      url: "../assets/Binaura.mp3",
      title: "Study & Read",
      cover: "https://i1.sndcdn.com/artworks-000249294066-uow7s0-t500x500.jpg"
    }
  ];
  four_AudioTrack: any = [
    {
      url: "../assets/Summer-Vibes-Lofi-Hiphop-Mix.mp3",
      title: "Light Work",
      cover: "https://i1.sndcdn.com/artworks-000249294066-uow7s0-t500x500.jpg"
    }
  ];
  audioList = [];

  constructor(public authService: AuthService, private router: Router, public afs: AngularFirestore) {
    this.authService.currentUser.subscribe(x => this.currentUser = x);
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = true;
        if (this.currentUser) {
          if (event.url == "/home" || event.url == "/" || event.url == "/privacypolicy") {
            this.music = false;
            this.currentUrl = false;
            return;
          }
          this.afs.collection("onboarding-answer").doc(this.currentUser.uid).get().subscribe(async (document: any) => {
            if (document.exists) {
              if (!document.data().hasOwnProperty("first") || !document.data().hasOwnProperty("second") || !document.data().hasOwnProperty("third")) {
                this.router.navigate(["/questions"]);
              }
              else {
                this.music = true;
                this.audioList = (event.url == "/login" || event.url == "/sign-up" || event.url == "/home" || event.url == "/" || event.url == "/privacypolicy") ? this.audioList = [] : this.first_AudioTrack;
                if (event.url == "/session" || event.url == "/test-session" || event.url == "/home" || event.url == "/" || event.url == "/privacypolicy") { this.currentUrl = false; }
                if (event.url == "/groups" || event.url == "/feeds") { this.addDynamicClass = true; } else { this.addDynamicClass = false; }

                $(document).ready(function () {
                  $("#music-player").click(function () {
                    $("#music-player-list").toggle();
                  })
                });
              }
            }
            else {
              this.router.navigate(["/questions"]);
            }
          });
        }
      }
    });
  }

  ngOnInit() {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })

    $(document).ready(function () {
      $("#music-player").click(function () {
        $("#music-player-list").toggle();
      })
      $('#bodyclasstoggle').on("click", function () {
        $(document).find('#mainbody').toggleClass('rp-open');
      })
    });
  }

  toggleShow() {
    this.isShown = !this.isShown;
  }

  ngOnChanges() { }

  ChangeTrck(obj, event) {
    $(".item").removeClass("active-track");
    this.audioList = [];
    switch (obj) {
      case "first":
        this.audioList = this.first_AudioTrack;
        break;
      case "second":
        this.audioList = this.Second_AudioTrack;
        break;
      case "third":
        this.audioList = this.third_AudioTrack;
        break;
      case "fourth":
        this.audioList = this.four_AudioTrack;
        break;
    }
    $("#" + obj).addClass("active-track");
  }

  profile() { this.router.navigate(['profile', this.currentUser.uid]); }

  logout() {
    this.authService.SignOut();
  }

  addbugerclass() {
    this.toggle = !this.toggle;
  }


}
