<div class="loading" *ngIf="loader">Loading&#8230;</div>
<nav class="navbar navbar-expand-lg navbar-dark top-nav session-head">
	<div class="btn-group" *ngIf="showPartnerDetails">
		<div class="partner-info">
			<img src="{{partnerProfileImage}}" />
			<div class="detail">
				<span class="name">{{partnerDisplayName}}</span>
				<span class="sessions">{{partnercompletedsessioncount}}</span>
			</div>
		</div>
	</div>
	<div class="test-header-left">
		<h6>👋 Hey! It’s focus time 😀 Tell your partner:</h6>
		<ol> 
			<li>1. What you plan to complete</li>
			<li>2. Which “No Gos” you will avoid</li>
		</ol>
	</div>
	<a class="navbar-brand" href="javascript:void(0)">
		<img class="brand-img" src="../assets/template/img/Peak-Focus-Logo.png" /><!-- <span>How Doers, Get More Done.</span> -->
	</a>
	<div class="btn-group btn-primary">
		<button class="btn end-count" type="button" style="font-weight: bold;">Ends in<span
				id="countdown"></span></button>
		<button class="btn" type="button" (click)="LeaveMeeting()" style="font-weight: bold;">Leave <i
				class="fas fa-sign-out-alt"></i></button>
	</div>
</nav>
<div class="session-video">
	<div class="waiting">
		<span class="info">You’re here!</span>
		<span class="info">Waiting for other person to join…</span>
		<video src="" id="attendee-video" class="preview_local_view none-element" autoplay="true" playsinline="true"
			muted="true" style="object-fit: cover;"></video>
	</div>
	<div class="attendee-status">
		<i class="fa fa-video attendee-icon text-success" id="attendee-camera-icon"></i>
		<i class="fa fa-video-slash attendee-icon off-element" id="attendee-camera-off-icon"></i>
		<i class="fa fa-microphone attendee-icon pl-3  text-success" id="attendee-mic-icon"></i>
		<i class="fa fa-microphone-slash attendee-icon pl-3 off-element" id="attendee-mic-off-icon"></i>

	</div>
	<div class="my-cam">
		<video src="" id="self-video" class="preview_local_view" autoplay="true" playsinline="true" muted="true"
			style="object-fit: cover;"></video>
		<span class="self-description">
			<i class="fas fa-video" id="self-description-camera-on-icon"></i>
			<i class="fas fa-video-slash" id="self-description-camera-off-icon"></i>

			<span>{{cameralabel}}</span>
		</span>
	</div>
	<div class="session-right">
		<ul class="nav nav-pills mb-2" id="pills-tab" role="tablist">
			<li class="nav-item mr-1" role="presentation">
				<a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#btn-task" role="tab"
					aria-controls="pills-home" aria-selected="true">Tasks</a>
			</li>
			<li class="nav-item ml-1" role="presentation">
				<a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#btn-chat" role="tab"
					aria-controls="pills-profile" aria-selected="false">Chat</a>
			</li>
		</ul>
		<div class="tab-content" id="pills-tabContent">
			<div class="tab-pane fade show active" id="btn-task" role="tabpanel" aria-labelledby="task-tab">
				<h2 class="title text-center">
					<button type="button" class="btn btn-flat add-task" data-toggle="modal" data-target="#createTask"
						#openModal (click)="CreateTask()"><i class="fa fa-plus"></i> Add Task</button>
				</h2>
				<div class="task-list">
					<div class="task-row head">
						<div class="task-column asign"></div>
						<div class="task-column time">Est Time</div>
						<div class="task-column date">Due Date</div>
					</div>
					<h5>
						<span>Top 3</span>
						<button id="copybuttontext"> <i class="fa fa-clone" aria-hidden="true"></i> </button>
						<button class="open" [ngClass]="{'open': toggle, '': !toggle}" (click)="btn_tgl_dwn()">
							<i class="fa fa-angle-down" id="toggledown"></i>
							<i class="fa fa-angle-up"></i>
						</button>
					</h5>
					<div class="task-container open" [ngClass]="{'open': toggle, '': !toggle}" *ngIf="totaltaskList">
						<div class="task-row" *ngFor="let item of totaltaskList | slice:0:3;let i=index">
							<div class="task-custom-placeholder task-column asign">{{i+1}}.
								<span class="copytext"> {{item.title}}</span>
							</div>
							<!-- <div class="task-column asign">01 <i class="fa fa-check" (click)="UpdateDoneTask(item.id)"></i><span>Task Title</span></div> -->
							<div class="task-column time">{{item.taskDuration}}:{{item.taskDurationmin}}</div>
							<div class="task-column date">{{item.start | date:'dd-MM-yyyy'}}
								<button (click)="remainderDetails('remainderTasks',item.id)" class="delete"><i
										class="fa fa-edit"></i>
								</button>
								<button (click)="deleteTaskClick(item.id)" class="delete"><i class="fas fa-times"></i>
								</button>
							</div>
						</div>
					</div>
					<h5 class="mt-4">
						<span>Tasks</span>
						<button class="open" [ngClass]="{'open': togglemaster, '': !togglemaster}"
							(click)="btn_tgl_dwn_master()">
							<i class="fa fa-angle-down"></i>
							<i class="fa fa-angle-up"></i>
						</button>

					</h5>
					<div class="task-container open" [ngClass]="{'open': togglemaster, '': !togglemaster}">
						<div class="task-row dragdrop" *ngFor="let item of totaltaskList | slice:3;let i=index">
							<div class="task-custom-placeholder"></div>
							<div class="task-column asign">{{i+1}}. {{item.title}}</div>
							<div class="task-column time">{{item.taskDuration}}:{{item.taskDurationmin}}</div>
							<div class="task-column date">{{item.start | date:'dd-MM-yyyy'}}
								<button class="delete"><i class="fa fa-edit"></i>
								</button>
								<button (click)="deleteTaskClick(item.id)" class="delete"><i class="fas fa-times"></i>
								</button>
							</div>
						</div>
					</div>

				</div>
			</div>
			<div id="output"></div>
			<div class="tab-pane fade" id="btn-chat" role="tabpanel" aria-labelledby="chat-tab">
				<div class="chat-scroll">
					<main class="msger-chat" id="chat-dashboard"></main>
				</div>

				<form class="msger-inputarea  mb-0">
					<input type="text" class="msger-input" id="chat-input" autocomplete="off"
						placeholder="Enter your message...">
					<button type="submit" class="msger-send-btn btn-primary"
						(click)="chatMessageSend()">Send</button>
				</form>
			</div>
		</div>
	</div>
</div>
<div class="session-footer">
	<audio id="meeting-audio" class="hide"></audio>
	<div class="btn-group">
		<button class="btn" type="button" (click)="HandleCamera()">
			<i class="fa fa-video text-success" id="self-camera-icon"></i>
			<i class="fa fa-video-slash off-element" id="self-camera-off-icon"></i>
			<span>{{cameralabel}}</span></button>
		<button class="btn" type="button" (click)="HandleMic()">
			<i class="fa fa-microphone text-success" id="self-mic-icon"></i>
			<i class="fa fa-microphone-slash off-element" id="self-mic-off-icon"></i>

			<span>Mic</span></button>
		<button class="btn" type="button" id="btn-chat" (click)="HandleChat()"><i class="fa fa-comment"></i><span
				class="count" *ngIf="notificationCount!=0">{{notificationCount}}</span><span>Chat</span></button>
	</div>
	<!--
	<div class="btn-group ml-auto">
-->
	<audio id="audio-notification">
		<source src="../../../assets/CartoonBell_DIGIFX5-89-01.mp3" type="audio/ogg">
	</audio>
	<button class="btn" type="button" id="btn-more" (click)="HandleTask()">
		<i class="fa fa-th-list"></i>
		<span>Task List</span>
	</button>
	<button class="blockuser" *ngIf="showPartnerDetails" (click)="OpenPopUpModal()" data-toggle="modal"
		data-target="#openBlockUserModal">Block </button>

</div>
<div class="modal fade" id="openBlockUserModal" tabindex="-1" role="dialog" aria-labelledby="openBlockUserModal"
	#openBlockUserModal aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLongTitle">Block {{partnerDisplayName}}?</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close" #CloseBlockUserModal>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>Sorry that {{partnerDisplayName}} isn't a good partner for you.</p>
				<p>Blocking will prevent {{partnerDisplayName}} from:</p>
				<ul class="viewD">
					<li class="viewb">Booking sessions with you</li>
					<li class="viewb">Seeing your sessions on the calendar</li>
					<li class="viewb">Messaging you</li>
					<li class="viewb">Seeing your name or profile anywhere on Focusmate</li>
				</ul>
				<p>Your upcoming sessions if any with {{partnerDisplayName}} will be deleted. And if you are
					currently
					in a
					session together, it will end automatically as you block {{partnerDisplayName}}.</p>
				<p>{{partnerDisplayName}} will not be notified.</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
				<button type="button" class="btn btn-primary" (click)="BlockUser()">Block</button>
			</div>
		</div>
	</div>
</div>

<button type="button" class="btn btn-primary" style="display: none;" data-toggle="modal" data-target="#deleteTask"
	#deleteopenModal></button>

<div class="modal fade" id="deleteTask" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered custom-alert" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLongTitle">Delete Task</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close" #deleteCloseModal>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<h5 class="text-center">You sure you about this?! ☹️</h5>
				<p class="text-center">Deleting task {{taskName}}. This action is irreversible.</p>

				<div class="row text-center">
					<div class="col-md-12">
						<p class="confirm-button">
							<a class="mr-2"><button class="btn btn-primary" data-dismiss="modal" aria-label="Close"
									#deleteCloseModal>Cancel</button>
							</a>
							<a>
								<button class="btn btn-danger" (click)="sure_Delete_Task(deleteTask)">delete it</button>
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<div class="modal fade" id="createTask" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<form class="login100-form validate-form" [formGroup]="taskForm" (ngSubmit)="remainderSubmit()">
				<div class="modal-header justify-content-center">
					<h5 class="modal-title" id="exampleModalLongTitle">{{TaskModalTitle}}</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeModal>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="row mb-3 mt-3 justify-content-center">
						<div class="col-10">
							<div class="form-group">
								<label>Task Description</label>
								<input type="text" class="form-control" formControlName="taskName" />
							</div>
							<div *ngIf="submitted && f.taskName.errors" class="text-danger validate-error">
								<div *ngIf="f.taskName.errors.required">Task name is required !</div>
							</div>
						</div>
					</div>
					<div class="row justify-content-center mb-3">
						<div class="col-md-7">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<label class="mr-4">Estimated Time</label>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group d-flex">
										<select class="mr-1" class="form-control" formControlName="duration"
											[ngClass]="{ 'is-invalid': submitted && f.duration.errors }">
											<option selected>0</option>
											<option>1</option>
											<option>2</option>
											<option>3</option>
											<option>4</option>
											<option>5</option>
											<option>6</option>
											<option>7</option>
											<option>8</option>
											<option>9</option>
											<option>10</option>
											<option>11</option>
											<option>12</option>
										</select>
										<span class="mr-2 ml-2">Hrs</span>
									</div>
									<div *ngIf="submitted && f.duration.errors"
										class="text-danger validate-error select-error">
										<div *ngIf="f.duration.errors.required">Estimated Time is required!</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group d-flex">
										<select class="form-control" formControlName="durationmin"
											[ngClass]="{ 'is-invalid': submitted && f.durationmin.errors }">
											<option *ngFor="let item of items; let i = index">
												{{item}}
											</option>
										</select>
										<span class="ml-2">Mins</span>
									</div>
									<div *ngIf="submitted && f.durationmin.errors"
										class="text-danger validate-error select-error">
										<div *ngIf="f.durationmin.errors.required">
											Duration is required !
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row justify-content-center mb-3">
						<div class="col-md-6">
							<div class="form-group">
								<label>Due Date</label>
								<input type='text' class="form-control" id='datetimepicker1' autocomplete="off" />
							</div>
						</div>
					</div>
				</div>
				<div class="row mb-5">
					<div class="col-md-12 text-center">
						<button type="submit" class="btn px-5 btn-primary">Submit</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>

<button type="button" class="btn btn-primary" style="display: none;" data-toggle="modal" data-target="#createTask"
	#taskeventdetailopenModal></button>