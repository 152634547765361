import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import { CalendarDashboardComponent } from './component/calendar-dashboard/calendar-dashboard.component';
import { AwsChimeComponent } from './component/awsChime/awsChime.component';
import { HomeComponent } from './component/home/home.component';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { environment } from '../environments/environment';
import { LoginComponent } from './component/account/login/login.component';
import { RegisterComponent } from './component/account/register/register.component';
import { AuthService } from "./services/auth.service";
import { AuthGuard } from "./helper/auth.guard";
import { SecureInnerPagesGuard } from "./helper/secure-inner-pages.guard";
import { ProfileComponent } from './component/profile/profile.component';
import { VerifyEmailComponent } from './component/account/verify-email/verify-email.component';
import { OrderByPipe } from './services/order-by-pipe.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PostComponent } from './component/post/post.component';
import { UserComponent } from './user/user.component';
import { UpcommingComponent } from './component/upcomming/upcomming.component';
import { TestSessionComponent } from './component/test-session/test-session.component';
import { PasswordStrengthComponent } from './component/account/password-strength/password-strength.component';
import { GroupsComponent } from './component/groups/groups.component';
import { FeedsComponent } from './component/feeds/feeds.component';
import { AngMusicPlayerComponent } from './component/ang-music-player/ang-music-player.component';
import { TimeConversionPipe } from './pipe/time-conversion.pipe';
import { QuestionsComponent } from './component/questions/questions.component';
import { PrivacyPolicyComponent } from './component/privacy-policy/privacy-policy.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { TemplateHomeComponent } from './component/template-home/template-home.component';
import { HomeImgComponent } from './component/home-img/home-img.component';
import { NewHomeTemplateComponent } from './component/new-home-template/new-home-template.component';
import { ConfirmVerificationComponent } from './component/account/confirm-verification/confirm-verification.component';

export function playerFactory() {
  return player;
}

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
  googleCalendarPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    CalendarDashboardComponent,
    HomeComponent,
    AwsChimeComponent,
    LoginComponent,
    RegisterComponent,
    ProfileComponent,
    VerifyEmailComponent,
    OrderByPipe,
    PostComponent,
    UserComponent,
    UpcommingComponent,
    TestSessionComponent,
    PasswordStrengthComponent,
    GroupsComponent,
    FeedsComponent,
    AngMusicPlayerComponent,
    TimeConversionPipe,
    QuestionsComponent,
    PrivacyPolicyComponent,
    TemplateHomeComponent,
    HomeImgComponent,
    NewHomeTemplateComponent,
    ConfirmVerificationComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FullCalendarModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    DragDropModule,
    FormsModule,
    HttpClientModule,
    AngularFireStorageModule,            // For Storage        <<<<<<
    LottieModule.forRoot({ player: playerFactory })
  ],
  providers: [AuthService, AuthGuard, SecureInnerPagesGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
