<section class="home_banner_area">
  <div class="container">
    <div class="alert alert-danger alert-dismissible fade show scstcss" role="alert" *ngIf="errorEmail">
      <span [innerHTML]="errorEmail"></span>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="row">
      <div class="col-md-6 m-auto">
        <div class="container box_1620">
          <div class="banner_inner d-flex align-items-center">
            <div class="banner_content">
              <!-- <div class="media">
                <div class="main main-raised"> -->
              <div class="profile-content">
                <div class="container">
                  <div class="row align-items-center">
                    <div class="col-md-4">
                      <div class="profile">
                        <div class="avatar" style="background-image: url({{imageSrc}});">
                          <!-- <img src="{{imageSrc}}" alt="Circle Image" class="img-raised img-fluid"> -->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="name" *ngIf="!editprofile">
                        <h3 class="title mb-0 text-capitalize">{{userDetials.displayName}}</h3>
                        <h6 class="mb-0">{{userDetials.email}}</h6>
                        <!-- <p class="mb-1 mt-1">Member since: {{userDetials.creationDate}} </p> -->
                        <p><i class="fa fa-map-marker" aria-hidden="true"></i>
                          {{userDetials.regionwithtimezone}}</p>

                        <a class="editIcon" href="javascript:void(0)"> <i class="fas fa-edit " aria-hidden="true"
                            (click)="editprofiledetails()"></i></a>

                        <a class="delete" href="javascript:void(0)" data-toggle="modal" data-target="#deleteAccount"
                          #openModal><i class="fas fa-trash-alt"></i></a>
                      </div>
                      <form class="login100-form validate-form" [formGroup]="profileForm" (ngSubmit)="onSubmit()"
                        *ngIf="editprofile">
                        <div class="form-group mt-5">
                          <label class="btn btn-primary btn-block">Upload Photo
                            <!-- <input type="file" class="uploadFile img" value="Upload Photo"
                                  (change)="handleFileInput($event)"
                                  style="width: 0px;height: 0px;overflow: hidden;"> -->
                            <input type="file" name="file" (input)="handleFileInput($event)" class="uploadFile img"
                              style="width: 0px;height: 0px;overflow: hidden;" accept=".jpeg,.jpg,.png,.svg" />

                          </label>
                        </div>

                        <div class="wrap-input100 validate-input">
                          <input class="input100" type="text" name="firstname" formControlName="firstname"
                            placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }">
                          <span class="focus-input100-1"></span>
                          <span class="focus-input100-2"></span>
                        </div>
                        <div *ngIf="submitted && f.firstname.errors" class="text-danger validate-error">
                          <div *ngIf="f.firstname.errors.required">Firstname is required !</div>
                        </div>

                        <div class="wrap-input100 validate-input  mt-2">
                          <input class="input100" type="text" name="lastname" formControlName="lastname"
                            placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }">
                          <span class="focus-input100-1"></span>
                          <span class="focus-input100-2"></span>
                        </div>
                        <div *ngIf="submitted && f.lastname.errors" class="text-danger validate-error">
                          <div *ngIf="f.lastname.errors.required">Lastname is required !</div>
                        </div>

                        <div class="wrap-input100 validate-input  mt-2">
                          <input class="input100 emailfield" type="text" name="email" disabled formControlName="email"
                            placeholder="Email-Id" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                          <span class="focus-input100-1"></span>
                          <span class="focus-input100-2"></span>
                        </div>
                        <div *ngIf="submitted && f.email.errors" class="text-danger validate-error">
                          <div *ngIf="f.email.errors.required">Email is required !</div>
                          <div *ngIf="f.email.errors.email">Email must be a valid email address !</div>
                        </div>

                        <div class="wrap-input100 validate-input  mt-2">
                          <input class="input100 emailfield" type="text" name="regionwithtimezone" disabled
                            formControlName="regionwithtimezone" placeholder="Region With Timezone">
                          <span class="focus-input100-1"></span>
                          <span class="focus-input100-2"></span>
                        </div>

                        <!-- <div class="wrap-input100 validate-input  mt-2">
                              <select class="select_box" formControlName="region" (change)="selectRegion($event)">
                                <option [selected]="9" *ngFor="let item of region" [value]="item.title">{{item.title}}
                                </option>
                              </select>
                            </div>

                            <div class="wrap-input100 validate-input  mt-2">
                              <select class="select_box" formControlName="timezone" (change)="selectTimezone($event)">
                                <option *ngFor="let subcategory of timezonelist" [value]="subcategory.title">
                                  {{subcategory.title}}</option>
                              </select>
                            </div> -->

                        <div class="container-login100-form-btn m-t-20">
                          <button type="submit" class="btn btn-primary btn-user">
                            submit
                          </button>
                          <button type="button" class="btn btn-danger btn-user ml-3" (click)="cancelEdit()">
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <!-- </div>
              </div> -->
            </div>

          </div>

          <hr>
          <div class="score">
            <div class="completed-session">
              <h5>{{completedsessioncount}}</h5>
              <p>Completed Sessions</p>
            </div>
            <div class="timeliness-score">
              <h5>{{timelinessScore}}%</h5>
              <p>Timeliness Score 
                <!-- <button type="button" class="btn btn-secondary btn-info" data-toggle="tooltip" data-placement="top" title="{{tooltipmsg}}">
                  <i class="fas fa-question-circle"></i> 
                </button> -->
                <button (mouseenter) ="mouseEnter()" (mouseleave) ="mouseLeave()" type="button" class="btn btn-secondary btn-info">
                  <i class="fas fa-question-circle"></i> 
                  <div class="tooltip fade show bs-tooltip-top" role="tooltip" id="tooltip14682" style="position: absolute; inset: auto auto 0px 0px; margin: 0px; bottom: 100%; display: none;" data-popper-placement="top"><div class="tooltip-arrow" style="position: absolute; left: 0px; transform: translate(100px);"></div><div class="tooltip-inner">The percentage of your last 20 sessions that you joined less than 5 min late. Only you can see this score</div></div>
                </button>
              </p>
            </div>
          </div>
        </div>
        
        <div class="container box_1620 mt-3 mb-5">
          <div class="banner_inner align-items-center">
            <a class="que_editIcon" href="javascript:void(0)" (click)="EditAnswers()"> <i class="fas fa-edit"
                aria-hidden="true" *ngIf="!editonboarding"></i></a>
            <a class="que_editIcon text-danger" href="javascript:void(0)" (click)="EditAnswers()"> <i
                class="fas fa-times" aria-hidden="true" *ngIf="editonboarding"></i></a>

            <div class="banner_content">
              <p>Question 1</p>
              <h6>{{que_first}}</h6>
              <p *ngIf="!editonboarding">{{ans_first}}</p>
              <div *ngIf="editonboarding">
                <div class="form-check">
                  <input type="radio" class="form-check-input" id="option1" name="option" value="40+ hours a week"
                    (change)="selectValue($event)" [checked]="ans_first == '40+ hours a week' ? true : false">

                  <label class="form-check-label" for="option1">
                    40+ hours a week
                  </label>
                </div>
                <div class="form-check">
                  <input type="radio" class="form-check-input" id="option2" name="option" value="10 - 40 hours a week"
                    (change)="selectValue($event)" [checked]="ans_first == '10 - 40 hours a week' ? true : false">
                  <label class="form-check-label" for="option2">
                    10 - 40 hours a week
                  </label>
                </div>
                <div class="form-check">
                  <input type="radio" class="form-check-input" id="option3" name="option" value="Less than 10"
                    (change)="selectValue($event)" [checked]="ans_first == 'Less than 10' ? true : false">
                  <label class="form-check-label" for="option3">
                    Less than 10
                  </label>
                </div>
              </div>
            </div>
            <hr>
            <div class="banner_content">
              <p>Question 2</p>
              <h6>{{que_second}}</h6>
              <p *ngIf="!editonboarding">{{ans_second}}</p>
              <input type="text" class="form-control" id="secondAns" *ngIf="editonboarding" (keyup)="validate()"
                value="{{ans_second}}">
            </div>
            <hr>
            <div class="banner_content">
              <p>Question 3</p>
              <h6>{{que_third}}</h6>
              <div *ngIf="!editonboarding">
                <p *ngFor="let item of ans_third;let i=index"><span
                    style="font-weight: 600;color: #000;">{{i+1}}.</span>
                  {{item.taskName}}</p>
              </div>
              <div *ngIf="editonboarding">
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col" class="text-center">Task</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" style="line-height: 2;">1</th>
                      <th scope="row">
                        <input type="text" class="form-control" id="task0" value="{{firsttask}}" />
                        <div class="text-danger validate-error err" id="error0">
                          <div>Task name is required !</div>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th scope="row" style="line-height: 2;">2</th>
                      <th scope="row">
                        <input type="text" class="form-control" id="task1" value="{{secondTask}}" />
                        <div class="text-danger validate-error err" id="error1">
                          <div>Task name is required !</div>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th scope="row" style="line-height: 2;">3</th>
                      <th scope="row"><input type="text" class="form-control" id="task2" value="{{thirdTask}}" />
                        <div class="text-danger validate-error err" id="error2">
                          <div>Task name is required !</div>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                </table>
                <div class="btn-cover text-center">
                  <button type="button" class="btn btn-success" (click)="SubmitAll()">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<div class="modal fade" id="deleteAccount" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog custom-alert" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Delete My Account</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h5 class="text-center">You sure you about this?! ☹️</h5>
        <p class="text-center">Deleting your account will irreversibly erase your entire account history. This action is
          irreversible.</p>

        <div class="row">
          <div class="col-md-12">
            <p class="confirm-button">
              <a class="mr-2"><button class="btn btn-primary" data-dismiss="modal" aria-label="Close" #closeModal>I'll
                  stay for now</button>
              </a>
              <a><button class="btn btn-danger" (click)="delete_User()">I'm sure, delete it</button>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


