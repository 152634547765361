import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  Toaster(type, message) {
    let toastClass = type == "Success" ? "toast-header-success" : type == "Error" ? "toast-header-error" : "toast-header-warning";
    $("#toast-body-custom").html(message);
    $('.toast').toast('show');
    setTimeout(() => {
      $("#toast-header-custom").removeClass(toastClass);
    }, 5500);
  }


}
