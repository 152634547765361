import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../../environments/environment.prod"

@Injectable({
  providedIn: 'root'
})
export class TribeService {

  baseURL: string = "https://us-central1-superbrain-c2070.cloudfunctions.net/tribe";
  //  baseURL: string = "https://4c8eef7cdef8.ngrok.io/tribe"
  adminToken: any;

  constructor(private http: HttpClient) {
    this.adminToken = localStorage.getItem("adminToken");
  }

  GenerateAdminToken() {
    return this.http.post(this.baseURL + "/generateAdminTribeToken", null);
  }

  CreateUser(userdata) {
    return this.http.post(this.baseURL + "/createuser", userdata);
  }

  GetTribeUsersList() {
    var reqdata = { authorization: this.adminToken }
    return this.http.post(this.baseURL + "/getUserList", reqdata);
  }

  GetTribeUsersListWithToken(adminToken) {
    var reqdata = { authorization: adminToken }
    return this.http.post(this.baseURL + "/getUserList", reqdata);
  }

  GetAllGroupList() {
    return this.http.get(this.baseURL + "/getallgroups");
  }

  GenerateJWTSSOToken(genarateSSOTOkenData) {
    return this.http.post(this.baseURL + "/generatejwtssotoken", genarateSSOTOkenData);
  }

  deleteUser(data) {
    return this.http.post(this.baseURL + "/deleteuser", data);
  }

  generateUserTribeToken(email) {
    let data = { email: email };
    return this.http.post(this.baseURL + "/generateUserTribeToken", data);
  }

  GetUsersGroups(token, userId) {
    let data = { authorization: token, userid: userId };
    return this.http.post(this.baseURL + "/getUserGroups", data);
  }

  GetGroupMembers(groupid, token) {
    let data = { authorization: token, groupid: groupid };
    return this.http.post(this.baseURL + "/getGroupMembers", data);
  }

  getUserBasedOnEmail(token, email) {
    let data = { authorization: token, email: email };
    return this.http.post(this.baseURL + "/getUserByEmailId", data);
  }

}
