import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CalendarOptions, EventApi, CalendarApi, ViewApi } from '@fullcalendar/angular';
import { TasksService } from '../../services/tasks/tasks.service';
import { AuthService } from '../../services/auth.service'
import { Router } from '@angular/router';
import { AwsChimeService } from '../../services/aws-chime/aws-chime.service'

declare var gapi: any;
declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-upcomming',
  templateUrl: './upcomming.component.html',
  styleUrls: ['./upcomming.component.css']
})
export class UpcommingComponent implements OnInit {

  @ViewChild('detailTaskModel') detailTaskModel: ElementRef;
  @ViewChild('CloseUpcommigModal') CloseUpcommigModal: ElementRef;
  @ViewChild('deleteopenModal') deleteopenModal: ElementRef;
  @ViewChild('deleteCloseModal') deleteCloseModal: ElementRef;
  @ViewChild('closedetailsModal') closedetailsModal: ElementRef;
  @ViewChild('closedetailsselfModal') closedetailsselfModal: ElementRef;
  @ViewChild('CloseuserProfileDetailsModal') CloseuserProfileDetailsModal: ElementRef;
  @ViewChild('openBlockUserModal') openBlockUserModal: ElementRef;
  @ViewChild('CloseBlockUserModal') CloseBlockUserModal: ElementRef;

  taskForm: FormGroup;
  upcommingtaskList: any[] = [];
  pasttaskList: any[] = [];
  taskForDetails: any = [{ taskName: '', taskTime: '', taskDate: '', days: '', taskDuration: '', durationmin: '' }];
  daySelection: boolean = true
  submitted: boolean = false;
  dayArray: any = [];
  publicID: any = '';
  bookedManual: 'False';
  currentUser: any;
  timeSelection: any;
  joinuserflag: 'false';
  joinusertaskid: '';
  joinusertaskuserid: '';
  joinflag: any = '';
  taskList: any[] = [];
  calenderEventsSelection: any[] = [];
  calendarEventSelect: any[] = [];
  topthreeremainder: any[] = [];
  totalremainderList: any[] = [];
  doneTaskList: any[] = [];
  calendarOptions: CalendarOptions;
  currentEvents: EventApi[] = [];
  JoinUser: any = '';
  incrementvalue: any[] = [];
  eventList: any[] = [];
  loader: boolean = true;
  upCommingEvents: any[] = [];
  meetingbutton: any = "";
  TaskModalTitle: string;
  deleteTask: any;
  viewAll: string = 'View All';
  viewAllValue: boolean = false;
  toggleLabel: any = "Public";
  toggletask: any = "upcomming";
  filteredtasklist: any = []
  filteredupcomingtask: any = []
  remainder: any = [];
  bookmeeting: any;
  initialized = false;
  bookingselected = false;
  bookingselctioncount = 0;
  itemsarr = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  calendarApii = new CalendarApi();
  viewapi: ViewApi;
  userDisplayName: any;
  userEmail: any;
  userCreationDate: any;
  userRegionWithTimeZone: any;
  userimageSrc: string = "../../../assets/avtar.png";
  UserUID: any;
  taskIdforedit: any;
  SigninedInStatus: boolean = false;
  toggle = {};
  togglemaster = {};
  toggledone: boolean = false;
  toggledone1: boolean = false;
  clicked: boolean = false;
  functionRunCount: number = 0;
  selecteddaycount = 1;
  constructor(
    private formBuilder: FormBuilder,
    private taskservices: TasksService,
    public afs: AngularFirestore,
    public authService: AuthService,
    private router: Router,
    private aswservice: AwsChimeService
  ) {
    this.loader = true;
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.getAllUpcommingData();
  }

  /*get all Upcomming data for a user*/
  getAllUpcommingData() {
    //console.log(this.currentUser)
    let object = this;
    let currentUserId: any;
    object.upcommingtaskList = [];
    object.pasttaskList = [];
    this.authService.currentUser.subscribe(x => currentUserId = x !== null ? x.uid : 0);
    //console.log("currentUserId", currentUserId);
    object.afs.collection('tasks', ref => ref.orderBy('taskDate', 'asc')).get().subscribe((querySnapshot) => {
      querySnapshot.forEach((doc: any) => {
        if (doc.data().userId == currentUserId) {
          let userId: any = '';
          if (doc.data().joinuserflaguserid != '') {
            userId = doc.data().joinuserflaguserid;
          } else {
            userId = doc.data().userId;
          }
          var ToDate = new Date();
          var dt2 = new Date(doc.data().taskStartTime);
          var taskstartDate = moment(dt2).format();
          //console.log('startDetail', taskstartDetail);
          var enddTime = new Date(doc.data().taskEndTime);
          enddTime = moment(enddTime).format();
          var taskstartDetail = object.timeTo12HrFormat(taskstartDate);
          var convertedtaskDatedetails = moment(dt2).format("YYYY-MM-DD");

          if (new Date(doc.data().taskEndTime).getTime() <= ToDate.getTime()) {
            object.afs.collection('users', ref => ref.where('uid', '==', userId)).get().subscribe((Snapshot) => {
              Snapshot.forEach((userdoc: any) => {
                var tasknametit = 'Not matched';
                if (doc.data().joinuserflaguserid != '') {
                  tasknametit = userdoc.data().displayName.split(' ')[0];
                }
                object.pasttaskList.push({
                  taskId: doc.data().taskId,
                  userId: doc.data().userId,
                  tasktittle: doc.data().taskName,
                  taskName: tasknametit,
                  taskTime: taskstartDetail,
                  taskDuration: doc.data().taskDuration,
                  taskDate: convertedtaskDatedetails,
                  bookedManual: doc.data().bookedManual,
                  days: doc.data().days,
                  dateTime: doc.data().dateTime,
                  joinuserflaguserid: doc.data().joinuserflaguserid,
                  joinuserflagtaskid: doc.data().joinuserflagtaskid,
                });
              });
            });
          } else {
            object.afs.collection('users', ref => ref.where('uid', '==', userId)).get().subscribe((Snapshot) => {
              Snapshot.forEach((userdoc: any) => {
                var tasknametit = 'Matching ...';
                if (doc.data().joinuserflaguserid != '') {
                  tasknametit = userdoc.data().displayName.split(' ')[0];
                }
                object.upcommingtaskList.push({
                  taskId: doc.data().taskId,
                  userId: doc.data().userId,
                  tasktittle: doc.data().taskName,
                  taskName: tasknametit,
                  taskTime: taskstartDetail,
                  taskDuration: doc.data().taskDuration,
                  taskDate: convertedtaskDatedetails,
                  bookedManual: doc.data().bookedManual,
                  days: doc.data().days,
                  dateTime: doc.data().dateTime,
                  joinuserflaguserid: doc.data().joinuserflaguserid == "" ? "" : userdoc.data().uid,
                  joinuserflagtaskid: doc.data().joinuserflagtaskid,
                });
              });
            });
          }
        }
      });
    });
    this.loader = false;

  }

  /*Convert time to 12 hour format*/
  timeTo12HrFormat(time) {   // Take a time in 24 hour format and format it in 12 hour format
    var timeformat = time.split("T");
    var time_part_array = timeformat[1].split(":");
    var ampm = '';

    if (time_part_array[0] >= 12) {
      ampm = 'PM';
    } else {
      ampm = 'AM';
    }

    if (time_part_array[0] > 12) {
      time_part_array[0] = time_part_array[0] - 12;
    }

    if (time_part_array[0].length > 1) {
      if (time_part_array[0].charAt(0) == '0') {
        time_part_array[0] = time_part_array[0].substring(1);
      }
    }
    time_part_array[0] = time_part_array[0] == '0' ? '12' : time_part_array[0];
    var formatted_time = time_part_array[0] + ':' + time_part_array[1] + ampm;
    return formatted_time;
  }

  /*Get profile for user*/
  profile(uId: string) {
    if (uId !== "") {
      this.closedetailsModal.nativeElement.click();
      var object = this;
      this.afs.collection('users', ref => ref.where('uid', '==', uId)).get().subscribe((querySnapshot) => {
        querySnapshot.forEach((doc: any) => {
          object.userDisplayName = doc.data().displayName;
          object.userEmail = doc.data().email;
          object.userCreationDate = doc.data().creationDate;
          object.userRegionWithTimeZone = doc.data().regionwithtimezone;
          object.userimageSrc = doc.data().photoURL;
          object.UserUID = uId;
        });
      });
    }
  }

  changeTab(obj) {
    this.toggletask = obj;
  }

  /*get task details for event*/
  taskDetails(obj, eventId) {
    this.JoinUser = '';
    this.joinflag = '';
    this.toggledone = false;
    this.toggledone1 = false;
    this.taskForDetails = [{
      taskName: '',
      taskTime: '',
      taskDate: '',
      days: '',
      taskDuration: ''
    }];
    this.toggletask = obj;
    //console.log(obj, eventId);
    if (obj == 'Past') {
      this.taskForDetails = this.pasttaskList.filter(x => x.taskId == eventId);
    } else {
      this.taskForDetails = this.upcommingtaskList.filter(x => x.taskId == eventId);
    }
    this.detailTaskModel.nativeElement.click();
    if (this.taskForDetails[0].joinuserflaguserid != '') {
      this.afs.collection('users', ref => ref.where('uid', '==', this.taskForDetails[0].joinuserflaguserid)).get().subscribe((Snapshot) => {
        Snapshot.forEach((userdoc: any) => {
          this.JoinUser = userdoc.data().displayName
        });
      });
      this.joinflag = 'no';
    } else {
      this.JoinUser = 'No Partner Matched';
      this.joinflag = '';
    }
  }

  /*clear modal for profile*/
  closePopUpModal() {
    $(".modal-backdrop").remove();
    // this.userDisplayName = "";
    this.userEmail = "";
    this.userCreationDate = "";
    this.userRegionWithTimeZone = "";
    this.userimageSrc = "../../../assets/avtar.png";
  }

  /*delete task modal open*/
  deleteTaskClick(eventId) {
    this.deleteTask = eventId;
    this.deleteopenModal.nativeElement.click();
  }

  /*Delete matching and open events*/
  async sure_Delete_Task(deleteTask) {
    console.log(deleteTask, this.toggletask)

    this.currentUser = JSON.parse(localStorage.getItem('user'));
    if (this.toggletask == 'Past') {
      this.taskForDetails = this.pasttaskList.filter(x => x.taskId == deleteTask);
    } else {
      this.taskForDetails = this.upcommingtaskList.filter(x => x.taskId == deleteTask);
    }
    console.log(this.taskForDetails);
    if (this.taskForDetails[0].joinuserflagtaskid != '') {
      await this.afs.doc('tasks/' + this.taskForDetails[0].joinuserflagtaskid).delete();
      const taskData = {
        taskId: this.taskForDetails[0].taskId,
        bookedManual: 'false',
        joinuserflag: 'false',
        joinuserflagtaskid: '',
        joinuserflaguserid: ''
      }
      await this.updateTask(taskData, 'tasks');
    } else {
      await this.afs.doc('tasks/' + deleteTask).delete();
    }


    // this.toggleLabel = "Public";
    this.deleteTask = '';
    this.taskForDetails = [{
      taskName: '',
      taskTime: '',
      taskDate: '',
      days: '',
      taskDuration: ''
    }];
    this.deleteCloseModal.nativeElement.click();
    setTimeout(() => {
      this.getAllUpcommingData();
      // location.reload();
    }, 1000);
  }


  /*close modal*/
  close(modaltype) {
    this.closedetailsModal.nativeElement.click();
  }

  /*UPdate event name for upcomming events*/
  async updateeventname(event, taskID) {
    var name = event.srcElement.value;
    if (name) {
      const taskData = {
        taskId: taskID,
        taskName: name,
        title: name,
      }
      await this.updateTask(taskData, 'tasks');

      alert('Title Updated');
      this.closedetailsModal.nativeElement.click();
      // this.closedetailsselfModal.nativeElement.click();
      this.getAllUpcommingData();
    }
  }


  /*Update data*/
  updateTask(data, tablename) {
    return new Promise<void>((resolve, reject) => {
      this.afs.doc(tablename + '/' + data.taskId).update(data);
      resolve()
    })
  }


  getalltaskBasedontaskID(taskId) {
    return new Promise((resolve, reject) => {
      this.afs.doc('tasks/' + taskId).get().subscribe((querySnapshot) => { const data = querySnapshot.data(); resolve(data); });
    });
  }

  /*get selected count*/
  countselect($event) {
    this.selecteddaycount = 0;
    let text = $event.target.options[$event.target.options.selectedIndex].value;
    if (text != '') {
      this.selecteddaycount = text;
    }
  }


  /*New work for repeating calendar events*/
  async repeat_event(period, taskId) {
    //console.log(period, taskId)
    var taskevent = [];
    var data: any = '';
    var mm: any = '';
    var dd: any = '';
    data = await this.getalltaskBasedontaskID(taskId)
    //console.log('data', data);
    if (period == 'weekly') {
      //console.log('week', this.selecteddaycount);
      await this.saveRecurringMeetinginDb(period, this.selecteddaycount, data, 7);
      const taskData = {
        taskId: this.afs.createId(),
        userId: this.currentUser.uid,
        taskName: 'FocusBlocks',
        taskStartTime: data.taskStartTime,
        taskEndTime: data.taskEndTime,
        taskDate: data.taskDate,
        bookedManual: 'false',
        days: '',
        dateTime: data.taskEndTime,
        joinuserflag: 'false',
        joinuserflagtaskid: '',
        joinuserflaguserid: '',
      };
      this.addrecuringMeetingtogmail(taskData, 'DAILY', this.selecteddaycount);

      // this.addrecuringMeetingtogmail(taskData, 'DAILY', countTime);
      this.getAllUpcommingData();
      alert('Meeting Scheduled');
    } else if (period == 'daily') {
      //console.log('daily', this.selecteddaycount);
      await this.saveRecurringMeetinginDb(period, this.selecteddaycount, data, 1);
      const taskData = {
        taskId: this.afs.createId(),
        userId: this.currentUser.uid,
        taskName: 'FocusBlocks',
        taskStartTime: data.taskStartTime,
        taskEndTime: data.taskEndTime,
        taskDate: data.taskDate,
        bookedManual: 'false',
        days: '',
        dateTime: data.taskEndTime,
        joinuserflag: 'false',
        joinuserflagtaskid: '',
        joinuserflaguserid: '',
      };
      this.addrecuringMeetingtogmail(taskData, 'WEEKLY', this.selecteddaycount);

      // this.addrecuringMeetingtogmail(taskData, 'DAILY', countTime);
      this.getAllUpcommingData();
      alert('Meeting Scheduled');
    }



    this.closedetailsModal.nativeElement.click();

  }

  /*Save reccuring mettings in Database*/
  async saveRecurringMeetinginDb(period, selecteddaycount, data, recday) {
    var taskevent = [];
    var mm: any = '';
    var dd: any = '';
    if (selecteddaycount > 0) {
      for (var i = 1; i <= selecteddaycount; i++) {

        var res: any = [];

        var countTime = recday * i;
        const date = new Date(data.taskDate);
        date.setDate(date.getDate() + countTime);
        mm = date.getMonth() + 1;
        dd = date.getDate();
        if (mm < 10) { mm = '0' + mm; }
        if (dd < 10) { dd = '0' + dd; }
        var taskDate = date.getFullYear() + '-' + mm + '-' + dd;
        var start_time = taskDate + 'T' + data.taskStartTime.split('T')[1];
        //console.log('ads ' + i, start_time);

        res = await this.checkspecifictime(start_time, this.currentUser.uid);

        if (res.length == 0) {
          const taskData = {
            taskId: this.afs.createId(),
            userId: this.currentUser.uid,
            taskName: 'FocusBlocks',
            taskStartTime: taskDate + 'T' + data.taskStartTime.split('T')[1],
            taskEndTime: taskDate + 'T' + data.taskEndTime.split('T')[1],
            taskDate: taskDate,
            bookedManual: 'false',
            days: '',
            dateTime: taskDate + 'T' + data.taskStartTime.split('T')[1],
            joinuserflag: 'false',
            joinuserflagtaskid: '',
            joinuserflaguserid: '',
          };
          await this.AddNewRemainderTask(taskData, 'tasks');
        } else if (res.length == 1) {
          if (res[0].userId != this.currentUser.uid) {
            var taskIdNEW = this.afs.createId();
            const taskData = {
              taskId: res[0].taskId,
              joinuserflag: 'True',
              joinuserflagtaskid: taskIdNEW,
              joinuserflaguserid: this.currentUser.uid
            }
            await this.updateTask(taskData, 'tasks');

            const createTask = {
              taskId: taskIdNEW,
              userId: this.currentUser.uid,
              taskName: res[0].taskName,
              taskStartTime: res[0].taskStartTime,
              taskEndTime: res[0].taskEndTime,
              taskDate: res[0].taskDate,
              days: res[0].days,
              bookedManual: res[0].bookedManual,
              dateTime: res[0].taskStartTime,
              joinuserflag: 'True',
              joinuserflagtaskid: res[0].taskId,
              joinuserflaguserid: res[0].userId
            }
            await this.AddNewRemainderTask(createTask, 'tasks');
          }
        }

      }
    }
  }

  checkspecifictime(time, userId) {
    return new Promise((resolve, reject) => {
      let data = [];
      this.afs.collection('tasks', ref => ref.where('dateTime', '==', time)).get().subscribe((querySnapshot) => {
        querySnapshot.forEach((doc: any) => {
          //console.log('checkspeci', doc.data());
          if (doc.data().joinuserflaguserid == userId || doc.data().userId == userId || (doc.data().userId != userId && doc.data().joinuserflaguserid == '')) {
            data.push(doc.data());
          }
        })
        resolve(data);
      })
    });
  }


  AddNewRemainderTask(taskData, tablename) {
    //console.log(taskData, tablename);
    return new Promise<void>((resolve, reject) => {
      this.afs.collection(tablename).doc(taskData.taskId).set(taskData);
      resolve()
    })
  }

  addrecuringMeetingtogmail(taskData, frequency, repeatdays) {
    if (frequency == 'DAILY') {


      var rule = ['RRULE:FREQ=' + frequency + ';COUNT=' + repeatdays]


    } else {
      var rule = ['RRULE:FREQ=' + frequency + ';']
    }

    var event = {
      'summary': 'FocusBlocks Session',
      'location': 'Online web meeting scheduled for client',
      'description': 'Invitation for meeting scheduled',
      'start': {
        'dateTime': taskData.taskStartTime,
        'timeZone': 'America/Los_Angeles'
      },
      'end': {
        'dateTime': taskData.taskEndTime,
        'timeZone': 'America/Los_Angeles'
      },
      'recurrence': rule,
      'attendees': [
        // { 'email': data.EventBookedBy },
        { 'email': this.currentUser.email },
      ],
      'reminders': {
        'useDefault': false,
        'overrides': [
          { 'method': 'email', 'minutes': 24 * 60 },
          { 'method': 'popup', 'minutes': 10 }
        ]
      }
    };
    //console.log('Event', event);
    var request = gapi.client.calendar.events.insert({
      'calendarId': 'jenhoustan@gmail.com',
      'resource': event,
      'maxAttendees': 1,
      'sendNotifications': true
    });

    request.execute(function (event) {
      //console.log('Event created: ' + event.htmlLink);
    });
  }

  backtocalendar() {
    this.router.navigate(['calendar-dashboard']);
  }

  async selfBooked(typeofEdit, taskID) {
    await this.afs.doc('tasks/' + taskID).delete();
    setTimeout(() => {
      alert('Event was Deleted');
      this.getAllUpcommingData();
      this.closedetailsModal.nativeElement.click();
    }, 2000);

  }

  BlockUser(UserUID) {
    let obj = this;
    this.afs.collection("blocked-user").doc(this.currentUser.uid).get().subscribe((document: any) => {
      let storedblocked = [];
      if (document.exists) {
        storedblocked = document.data()["blockUsers"];
        if (!storedblocked.includes(UserUID)) {
          // add blockuser in array
          storedblocked.push(UserUID);
        }
      } else {
        // First user in block list
        storedblocked.push(UserUID);
      }
      let taskCount = 0;
      obj.afs.collection("blocked-user").doc(obj.currentUser.uid).set({ 'blockUsers': storedblocked }).then(() => {
        obj.afs.collection('tasks', ref => ref.where('userId', '==', obj.currentUser.uid)).get().subscribe((querySnapshot) => {
          if (querySnapshot.size > 0) {
            querySnapshot.forEach((doc: any) => {
              if (doc.data()["joinuserflaguserid"] === UserUID) {
                // this.afs.collection('tasks').doc(doc.id).update({ joinuserflaguserid: '', joinuserflagtaskid: '', joinuserflag: 'false', bookedManual: 'false' })
                obj.afs.collection('tasks', ref => ref.where('joinuserflaguserid', '==', obj.currentUser.uid)).get().subscribe((querySnapshot) => {
                  if (querySnapshot.size > 0) {
                    querySnapshot.forEach((doc1: any) => {
                      // console.log("Test");
                      obj.afs.collection('tasks').get().subscribe((querySnapshot) => {
                        console.log("querySnapshotdata.size: ", querySnapshot.size);
                        if (querySnapshot.size > 0) {
                          querySnapshot.forEach((doc3: any) => {
                            // console.log("<<<<<<<<------------>>>>>>>>>", doc3);
                            // console.log("------------", doc3.data());
                            // console.log("Booked session found.", doc3.data()["taskStartTime"], doc1.data()["taskStartTime"], (new Date(doc3.data()["taskStartTime"]).getTime() === new Date(doc1.data()["taskStartTime"]).getTime()))
                            if (taskCount === 0) {
                              if ((new Date(doc3.data()["taskStartTime"]).getTime() == new Date(doc1.data()["taskStartTime"]).getTime()) && doc3.data()["joinuserflag"] == 'false' && doc3.data()["userId"] != UserUID) {
                                console.log("Booked session found.");
                                obj.afs.collection('tasks').doc(doc1.id).update({ joinuserflaguserid: doc3.data()["userId"], joinuserflagtaskid: doc3.data()["taskId"], joinuserflag: 'True', bookedManual: 'True' });
                                obj.afs.collection('tasks').doc(doc3.id).update({ joinuserflaguserid: doc1.data()["userId"], joinuserflagtaskid: doc1.data()["taskId"], joinuserflag: 'True', bookedManual: 'True' });
                                taskCount++;
                              }
                            }
                          })
                        }
                      });
                      if (taskCount === 0) {
                        obj.afs.collection('tasks').doc(doc1.id).update({ joinuserflaguserid: '', joinuserflagtaskid: '', joinuserflag: 'false', bookedManual: 'false' })
                      }
                      setTimeout(() => {
                        obj.getAllUpcommingData();
                      }, 1000);


                    });
                  }
                });
                obj.afs.collection('tasks').doc(doc.id).delete();
              }
            });
          }
        });
        this.afs.collection("blocked-user").doc(UserUID).get().subscribe((document: any) => {
          let storedblockedOther = [];
          if (document.exists) {
            storedblockedOther = document.data()["blockUsers"];
            if (!storedblockedOther.includes(this.currentUser.uid)) {
              // add blockuser in array
              storedblockedOther.push(this.currentUser.uid);
            }
          } else {
            // First user in block list
            storedblockedOther.push(this.currentUser.uid);
          }
          this.afs.collection("blocked-user").doc(UserUID).set({ 'blockUsers': storedblockedOther })
        });
        this.CloseBlockUserModal.nativeElement.click();

      }, error => { console.log(error) });
    })
  }

  OpenPopUpModal() {
    this.CloseuserProfileDetailsModal.nativeElement.click();

    this.openBlockUserModal.nativeElement.click();
  }
}
