import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {
  iframeSrc: any = "";
  constructor(private sanitizer: DomSanitizer) {
    let groupRoute = localStorage.getItem("grpname");
    if (groupRoute == "list-grp-all") {
      this.iframeSrc = sanitizer.bypassSecurityTrustResourceUrl("https://asas.tribe.so/groups");
    } else {
      this.iframeSrc = sanitizer.bypassSecurityTrustResourceUrl("https://asas.tribe.so/group/" + groupRoute);
    }
  }

  ngOnInit(): void {

  }

}
